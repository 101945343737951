import * as THREE from "three";
import vertex from "./physicalMaterial.glslv";
import fragment from "./physicalMaterial.glslf";
import { Vector3, Color, ShaderChunk, ShaderLib, UniformsUtils } from "three";

const meshphys_frag_before_main = ShaderChunk["meshphysical_frag"].slice(
  0,
  ShaderChunk["meshphysical_frag"].indexOf(
    "outgoingLight = outgoingLight * ( 1.0 - material.clearcoat * Fcc ) + clearcoatSpecular * material.clearcoat",
  ),
);

function insertBetween(shader, insertQueries, inserts) {
  let rShader = shader;
  insertQueries.forEach((iq, index) => {
    let splits = rShader.split(iq);
    rShader = splits.join(iq + inserts[index]);
  });

  return rShader;
}

const vertexQueryBeforeMain = "#include <uv_pars_vertex>\n";
const vertexQueryMain = "#include <fog_vertex>\n";

const vertexBeforeMain = "varying vec3 wPosition;\n";
const vertexMain = "\twPosition = vec3(modelMatrix * vec4(position, 1.0));\n";
const vertexShader = insertBetween(
  ShaderChunk["meshphysical_vert"],
  [vertexQueryBeforeMain, vertexQueryMain],
  [vertexBeforeMain, vertexMain],
);

const fragmentQueryBeforeMain = "#include <packing>\n";
const fragmentQueryMain =
  "outgoingLight = outgoingLight * ( 1.0 - material.clearcoat * Fcc ) + clearcoatSpecular * material.clearcoat;\n\t#endif\n\t#include <output_fragment>\n\t";

const fragmentBeforeMain =
  "uniform float t;\nuniform vec3 materialImpulseCenter;\nuniform float circularT;\nuniform vec3 circularCenter;\nvarying vec3 wPosition;\n";
const fragmentMain = fragment + "\t";
const fragmentShader = insertBetween(
  ShaderChunk["meshphysical_frag"],
  [fragmentQueryBeforeMain, fragmentQueryMain],
  [fragmentBeforeMain, fragmentMain],
);

// console.log(vertexShader, fragmentShader);

const physicalMaterial = (options) => {
  const m = new THREE.ShaderMaterial({
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    uniforms: UniformsUtils.merge([
      ShaderLib.physical.uniforms,
      { t: { value: 1 } },
      { materialImpulseCenter: { value: new THREE.Vector3(0, 0, 0) } },
      { circularT: { value: 0 } },
      { circularCenter: { value: new THREE.Vector3(-1000, 0, 0) } },
    ]),
    defines: {
      STANDARD: "",
      PHYSICAL: "",
      USE_MAP: options && options.map ? true : false,
      USE_UV: options && options.map ? true : false,
      MAP_UV: options && options.map ? "uv" : false,
    },
    lights: true,
  });

  if (options && options.map) m.uniforms.map.value = options.map;
  if (options && options.color) m.uniforms.diffuse.value = options.color;
  //m.isMeshPhysicalMaterial = true;

  return m;
};

export default physicalMaterial;
