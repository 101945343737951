import * as THREE from "three";
import * as bufferUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import { DataUtils } from "three";
import { getAssets } from "./loader";

const objects = {
  "A_Factory_OilStorage_01-BASE__Blue": null,
  "A_Factory_OilStorage_02-BASE__Blue": null,
  "A_Factory_OilStorage_03-BASE__Blue": null,
  "A_Factory_OilStorage_04-BASE__Blue": null,
  "A_Factory_OilStorage_05-BASE__Blue": null,
  "A_Factory_OilStorage_06-BASE__Blue": null,
  "A_Factory_Storage_02-BASE__Blue": null,
  "A_Factory_Column_02-BASE__Blue": null,
  "A_Factory_Column_01-BASE__Blue": null,
  "A_Factory_Column_03-BASE__Blue": null,
  A_Factory_GasStorage_01: null,
  A_Factory_GasStorage_02: null,

  "A_Factory_02_GasSphere_01-BASE__Blue": null,
  "A_Factory_02_GasSphere_02-BASE__Blue": null,
  "A_Factory_02_GasSphere_03-BASE__Blue": null,
  "A_Factory_02_GasSphere_04-BASE__Blue": null,
  "A_Factory_02_Column_01-BASE__Blue": null,
  "A_Factory_02_Column_02-BASE__Blue": null,
  "A_Factory_02_Column_03-BASE__Blue": null,
  "A_Factory_02_Column_04-BASE__Blue": null,

  "A_Septic_Pool_01-BASE__White": null,
  "A_Septic_Pool_02-BASE__White": null,
  "A_Septic_Pool_03-BASE__White": null,

  "A_Septic_Tank_02-BASE__Blue": null,

  C_Transport_01: null,
  C_Transport_02: null,
  Bricks: null,
  Bricks2: null,
  B_Region_FB_02: null,
};

const fullyDown = [
  "B_Region_08_House_01-BASE__Blue",
  "B_Region_08_House_01-BASE__White",
  "B_Region_08_House_02",
  "B_Region_09_House_03-BASE__Blue",
  "B_Region_09_House_03-BASE__White",
  "B_Region_07_House_01",
  "B_Region_04_House_05-BASE__Blue",
  "B_Region_04_House_05-BASE__White",
  "B_Region_04_House_02",
  "B_Region_Out_House_06-BASE__Blue",
  "B_Region_Out_House_06-BASE__White",
  "B_Region_Out_House_05-BASE__Blue",
  "B_Region_Out_House_05-BASE__White",
  "B_Region_Out_House_13-BASE__Blue",
  "B_Region_Out_House_13-BASE__White",
  "B_Region_Out_House_14-BASE__Blue",
  "B_Region_Out_House_14-BASE__White",
  "B_Region_Out_House_09-BASE__Blue",
  "B_Region_Out_House_09-BASE__White",
];

const transparent = [
  "B_Region_02_House_01-BASE__White",
  "B_Region_02_House_01-BASE__Blue",
  "B_Region_FB_04-BASE__White",
  "B_Region_FB_04-BASE__Blue",
  "B_Region_FB_03-BASE__Blue",
  "B_Region_FB_03-BASE__White",
  "A_Factory_Column_01-BASE__Blue",
  "A_Factory_Column_01-BASE__White",
  "A_Factory_Column_02-BASE__Blue",
  "A_Factory_Column_02-BASE__White",
  "A_Recycle_Factory-BASE__Blue",
  "A_Recycle_Factory-BASE__White",
  // "B_Region_08_House_02",
  // "B_Region_08_House_01-BASE__Blue",
  // "B_Region_08_House_01-BASE__White",
  // "B_Region_09_House_03-BASE__Blue",
  // "B_Region_09_House_03-BASE__White",
  "Tonnel_01",
  "A_Factory_Column_03-BASE__Blue",
  "A_Factory_Column_03-BASE__White",
  "TowerCrane",
  "TowerWeight",
  "B_Region_Out_Land_01-BASE__Blue",
  "B_Region_Out_Land_01-BASE__White",
  "A_Factory_Tube_01",
];

const animatedMeshes = [];
function getTransparent() {
  return transparent;
}

function reduceIndexBytes(geometry) {
  const indexArray = geometry.index.array;
  const u16IndexArray = new Uint16Array(indexArray);
  geometry.setIndex(new THREE.Uint16BufferAttribute(u16IndexArray, 1));
}
function quantizeGeometryAttribute(geometry, attribute) {
  const preArray = geometry.attributes[attribute].array;
  // const uint32View = new Uint32Array(preArray.buffer);
  const itemSize = geometry.attributes[attribute].itemSize;

  const buffer = new ArrayBuffer(2 * preArray.length);
  const uint16Buffer = new Uint16Array(buffer);

  for (let i = 0; i < preArray.length; i++) {
    //const uint32Element = uint32View[i];
    const float32Element = preArray[i];
    //uint16Buffer[i] = DataUtils.toHalfFloat(uint32Element);
    const uint16Element = DataUtils.toHalfFloat(float32Element);
    uint16Buffer[i] = uint16Element;
  }
  geometry.setAttribute(
    attribute,
    new THREE.Float16BufferAttribute(buffer, itemSize, false),
  );
}

function populateArrays(
  node,
  waterMeshes,
  roadMeshes,
  accentMeshes,
  baseMeshes,
  planeMeshes,
  treeMeshes,
  transparentBaseMeshes,
  transparentAccentMeshes,
) {
  let trees;
  node.traverse((m) => {
    if (m.isMesh) {
      const geometry = m.geometry;
      const count = geometry.attributes.position.count;
      const meshPositions = new Float32Array(count * 4);
      const position = new THREE.Vector3();
      m.getWorldPosition(position);
      const r = fullyDown.indexOf(m.name) === -1 ? 1 : -1;
      for (let i = 0; i < 4 * count; i += 4) {
        meshPositions[i] = position.x;
        meshPositions[i + 1] = position.y;
        meshPositions[i + 2] = position.z;
        meshPositions[i + 3] = r; //-1;
      }

      geometry.setAttribute(
        "meshPosition",
        new THREE.BufferAttribute(meshPositions, 4),
      );
    }

    if (m.name === "Trees") trees = m;

    if (Object.keys(objects).indexOf(m.name) != -1) {
      // if (!m.isAnimated) {
      //   m.updateMatrixWorld(true);
      //   const between = new THREE.Object3D();
      //   between.applyMatrix4(m.parent.matrixWorld);
      //   //between.add(m);
      //   //m.parent.remove(m);
      //   // animatedMeshes.push({ between, m });
      // }
      // if (m.children.length > 0)
      //   m.traverse((o) => {
      //     o.isAnimated = true;
      //   });
      return;
    }

    if (m.isMesh && !m.isAnimated) {
      m.geometry.computeBoundingBox();
      let sizes = new THREE.Vector3();
      m.geometry.boundingBox.getSize(sizes);
      const name = m.material.name.slice(0, 12);

      if (
        (m.name.slice(0, 7) === "C_Water" || m.name.slice(0, 5) === "Water") &&
        name === "BASE__Blue"
      ) {
        m.position.y += 0.1;
        waterMeshes.push(m);
      } else if (m.name === "C_Communications_Roads-BASE__White_1") {
        roadMeshes.push(m);
      } else if (m.name === "Ground_Cube") {
        planeMeshes.push(m);
      } else if (m.parent.name === "Trees") {
        treeMeshes.push(m);
      } else if (name === "BASE__Blue" && transparent.indexOf(m.name) == -1) {
        accentMeshes.push(m);
      } else if (name === "BASE__Blue" && transparent.indexOf(m.name) != -1) {
        transparentAccentMeshes.push(m);
      } else if (name === "BASE__White" && transparent.indexOf(m.name) == -1) {
        baseMeshes.push(m);
      } else if (name === "BASE__White" && transparent.indexOf(m.name) != -1) {
        transparentBaseMeshes.push(m);
      } else if (transparent.indexOf(m.name) != -1) {
        transparentBaseMeshes.push(m);
      } else {
        baseMeshes.push(m);
      }
    }
  });
}

function processArrayForGeometry(array) {
  const geometries = [];
  for (let i = 0; i < array.length; i++) {
    const mesh = array[i];
    const geometry = mesh.geometry.clone();

    if (Object.keys(geometry.attributes).length < 3) {
      geometry.computeVertexNormals();
      //quantizeGeometryAttribute(geometry, "normal");
    }

    mesh.updateMatrixWorld(true);
    geometry.applyMatrix4(mesh.matrixWorld);

    const count = geometry.attributes.position.count;
    // const meshPositions = new Float32Array(count * 3);
    //
    // const r = fullyDown.indexOf(mesh.name) === -1 ? 1 : -1;

    // for (let i = 0; i < 3 * count; i += 3) {
    //   meshPositions[i] = mesh.position.x;
    //
    //   meshPositions[i + 1] = mesh.position.z;
    //   meshPositions[i + 2] = r;
    //   // meshPositions[i + 1] = mesh.position.y;
    //   // meshPositions[i + 2] = mesh.position.z;
    // }
    //
    // geometry.setAttribute(
    //   "meshPosition",
    //   new THREE.BufferAttribute(meshPositions, 3),
    // );
    geometries.push(geometry);
  }

  let finalGeometry;
  const chunkSize = 5;
  for (let i = 0; i < geometries.length; i += chunkSize) {
    const chunk = geometries.slice(i, i + chunkSize);
    // do whatever
    if (finalGeometry) chunk.push(finalGeometry);
    finalGeometry = bufferUtils.mergeGeometries(chunk);
  }

  quantizeGeometryAttribute(finalGeometry, "position");
  quantizeGeometryAttribute(finalGeometry, "normal");
  quantizeGeometryAttribute(finalGeometry, "meshPosition");

  return finalGeometry;
}

function transformGeometry(node) {
  // const assets = getAssets();
  // Object.keys(assets.animatedMeshes).forEach((name) => {
  //   objects[name] = null;
  // });

  const waterMeshes = [];
  const roadMeshes = [];
  const accentMeshes = [];
  const baseMeshes = [];
  const planeMeshes = [];
  const treeMeshes = [];
  const transparentBaseMeshes = [];
  const transparentAccentMeshes = [];
  node.updateMatrixWorld(true);
  populateArrays(
    node,
    waterMeshes,
    roadMeshes,
    accentMeshes,
    baseMeshes,
    planeMeshes,
    treeMeshes,
    transparentBaseMeshes,
    transparentAccentMeshes,
  );
  const water = processArrayForGeometry(waterMeshes);
  const road = processArrayForGeometry(roadMeshes);
  const accent = processArrayForGeometry(accentMeshes);
  const base = processArrayForGeometry(baseMeshes);
  const plane = processArrayForGeometry(planeMeshes);
  const tree = processArrayForGeometry(treeMeshes);
  const transparentAccent = processArrayForGeometry(transparentAccentMeshes);
  const transparentBase = processArrayForGeometry(transparentBaseMeshes);

  return {
    water,
    road,
    accent,
    base,
    plane,
    tree,
    transparentAccent,
    transparentBase,
  };
}

export { transformGeometry, getTransparent };
