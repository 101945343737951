import * as THREE from "three";
import { getAssets } from "../loader";
import getState from "../state";
import matcapMaterial from "../materials/matcapMaterial";
import { addDynamicMaterial } from "./dynamicMaterialsManager";
const state = getState();
let bricksTaken = false;
let bricks2Taken = false;

let brickMaterial, brickMaterial2;

const objects = {
  "A_Factory_OilStorage_01-BASE__Blue": null,
  "A_Factory_OilStorage_02-BASE__Blue": null,
  "A_Factory_OilStorage_03-BASE__Blue": null,
  "A_Factory_OilStorage_04-BASE__Blue": null,
  "A_Factory_OilStorage_05-BASE__Blue": null,
  "A_Factory_OilStorage_06-BASE__Blue": null,
  "A_Factory_Storage_02-BASE__Blue": null,
  "A_Factory_Column_02-BASE__Blue": null,
  "A_Factory_Column_01-BASE__Blue": null,
  "A_Factory_Column_03-BASE__Blue": null,
  A_Factory_GasStorage_01: null,
  A_Factory_GasStorage_02: null,

  "A_Factory_02_GasSphere_01-BASE__Blue": null,
  "A_Factory_02_GasSphere_02-BASE__Blue": null,
  "A_Factory_02_GasSphere_03-BASE__Blue": null,
  "A_Factory_02_GasSphere_04-BASE__Blue": null,
  "A_Factory_02_Column_01-BASE__Blue": null,
  "A_Factory_02_Column_02-BASE__Blue": null,
  "A_Factory_02_Column_03-BASE__Blue": null,
  "A_Factory_02_Column_04-BASE__Blue": null,

  "A_Septic_Pool_01-BASE__White": null,
  "A_Septic_Pool_02-BASE__White": null,
  "A_Septic_Pool_03-BASE__White": null,

  "A_Septic_Tank_02-BASE__Blue": null,

  C_Transport_01: null,
  C_Transport_02: null,
  Bricks: null,
  Bricks2: null,
};
function parseScene(scene) {
  let t = Date.now();
  const assets = getAssets();
  scene.traverse((o) => {
    if (Object.keys(objects).indexOf(o.name) != -1) {
      objects[o.name] = o;
    }
  });

  const trainMaterial = matcapMaterial(
    assets[state.trainMatCap],
    null,
    state.ambientColor,
    state.ambientIntensity,
    false,
    state.backgroundColor,
  );
  trainMaterial.defines["TRAIN"] = true;
	trainMaterial.transparent = true;
  //trainMaterial.blending = THREE.AdditiveBlending;
  addDynamicMaterial(trainMaterial);

  objects["C_Transport_01"].traverse((m) => {
    if (m.isMesh) m.material = trainMaterial;
  });
  objects["C_Transport_02"].traverse((m) => {
    if (m.isMesh) m.material = trainMaterial;
  });

  objects.Bricks = assets.bricks.clone();
  objects.Bricks2 = assets.bricks.clone();

  //scene.add(objects.Bricks);
  //scene.add(objects.Bricks2);

  //objects.Bricks.visible = false;
  //objects.Bricks2.visible = false;

  brickMaterial = matcapMaterial(
    assets[state.bricksMatCap],
    null,
    state.ambientColor,
    state.ambientIntensity,
    false,
    state.backgroundColor,
  );
  brickMaterial.side = THREE.DoubleSide;
  brickMaterial.defines["BRICKS"] = true;
  brickMaterial2 = matcapMaterial(
    assets[state.bricksMatCap],
    null,
    state.ambientColor,
    state.ambientIntensity,
    false,
    state.backgroundColor,
  );
  brickMaterial2.side = THREE.DoubleSide;
  brickMaterial2.defines["BRICKS"] = true;
  addDynamicMaterial(brickMaterial);
  addDynamicMaterial(brickMaterial2);

  objects.Bricks.traverse((m) => {
    if (m.isMesh) m.material = brickMaterial;
  });
  objects.Bricks.scale.set(0.4, 0.4, 0.4);

  objects.Bricks2.traverse((m) => {
    if (m.isMesh) m.material = brickMaterial2;
  });
  objects.Bricks2.scale.set(0.4, 0.4, 0.4);
}

function getObject(name) {
  return objects[name];
}

function getObjectWorldPosition(name, target) {
  const targetVector = target ? target : new THREE.Vector3();
  const object = objects[name];
  object.getWorldPosition(targetVector);

  return targetVector;
}

function getObjectPosition(name, target) {
  const targetVector = target ? target : new THREE.Vector3();
  const object = objects[name];
  targetVector.copy(object.position);

  return targetVector;
}

class trainWaitInteraction {
  constructor(vehicle) {
    this.vehicle = vehicle;

    this.running = false;
    this.started = false;
    this.finished = false;

    this.train1Position = new THREE.Vector3();
    this.train2Position = new THREE.Vector3();
  }

  start() {
    this.running = true;
    this.started = true;
  }

  checkIfFinished() {
    if (!this.started) return false;
    const vehicle = this.vehicle;

    getObjectWorldPosition("C_Transport_01", this.train1Position);
    getObjectWorldPosition("C_Transport_02", this.train2Position);

    const distanceToTrain1 = Math.abs(
      vehicle.body.position.x - this.train1Position.x,
    );
    const distanceToTrain2 = Math.abs(
      vehicle.body.position.x - this.train2Position.x,
    );
    if (distanceToTrain1 > 30 && distanceToTrain2 > 30) {
      this.running = false;
      this.finished = true;
    }

    return this.finished;
  }
  checkIfStarted() {
    return this.started;
  }

  checkIfRunning() {
    if (!this.started) return false;

    let t = this.getT();
    if (t < 1) return true;
    else return false;
  }
  toStartNextInteraction() {
    return this.nextInteractionStart == "now";
  }

  setNextInteractionStart(nextInteractionStart) {
    this.nextInteractionStart = nextInteractionStart;
  }
}

class bricksInteraction {
  constructor(vehicle) {
    this.running = false;
    this.started = false;
    this.finished = false;

    this.loader = vehicle.loader;
    this.vehicle = vehicle;
    this.bricks = vehicle.num < 5 ? objects.Bricks : objects.Bricks2;
    this.material = vehicle.num < 5 ? brickMaterial : brickMaterial2;

    if (vehicle.num < 5) {
      if (bricksTaken) {
        this.reverse = true;
        bricksTaken = false;
      } else {
        this.reverse = false;
        bricksTaken = true;
      }
    } else {
      if (bricks2Taken) {
        this.reverse = true;
        bricks2Taken = false;
      } else {
        this.reverse = false;
        bricks2Taken = true;
      }
    }

    this.t = 0;

    this.getT = () => {};
  }

  start() {
    this.running = true;
    this.started = true;

    this.loader.add(this.bricks);
    this.bricks.position.set(0.24, -0.02, this.reverse ? 0.1 : 5);
    this.bricks.visible = true;
    this.bricks.matrixWorldNeedsUpdate = true;
    this.material.uniforms.opacity.value = 1;
  }

  animateBricks() {
    if (!this.reverse)
      this.bricks.position.z = 5.0 * (1 - this.t) + this.t * 0.1;
    else {
      this.loader.rotation.y =
        this.t < 0.5
          ? -Math.PI * 0.25 * (this.t / 0.5)
          : -Math.PI * 0.25 * ((1.0 - this.t) / 0.5);
      //this.bricks.position.z = 5.0 * this.t + (1.0 - this.t) * 0.1;
      this.bricks.position.x = -1.0 * this.t + (1.0 - this.t) * 0.24;
      this.material.uniforms.opacity.value = Math.max(0.0, 1.0 - this.t / 0.5);
    }
  }

  checkIfFinished() {
    if (!this.started) return false;

    this.t += 0.005;
    this.animateBricks();

    let t = this.t;

    if (t >= 1) {
      this.running = false;
      this.finished = true;
      if (this.revrse) {
        this.bricks.position.z = 5;
      }
    }

    return this.finished;
  }
  checkIfStarted() {
    return this.started;
  }

  checkIfRunning() {
    if (!this.started) return false;

    let t = this.getT();
    if (t < 1) return true;
    else return false;
  }
  toStartNextInteraction() {
    return this.nextInteractionStart == "now";
  }

  setNextInteractionStart(nextInteractionStart) {
    this.nextInteractionStart = nextInteractionStart;
  }
}

function cleanImportantObjects() {

 bricksTaken = false;
bricks2Taken = false;
  Object.keys(objects).forEach((key) => {
    objects[key] = null;
  });
}

export {
  cleanImportantObjects,
  getObject,
  getObjectWorldPosition,
  getObjectPosition,
  parseScene,
  trainWaitInteraction,
  bricksInteraction,
};
