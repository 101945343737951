import * as THREE from "three";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { getComplete } from "./loader";
import curveMaterial from "./materials/curveMaterial";
import getState from "./state";

const state = getState();
const scene = new THREE.Scene();
let camera;
let controls;
let cylinderMesh, cylinderObject;
let halfWidth;
let halfHeight;

let targetSize = 0;
let size = 0;

let center = 0;
let radius = 0;

const sizes = { width: window.innerWidth, height: window.innerHeight };

function getSize() {
  return size;
}
function getLoadCamera() {
  return camera;
}

function onLoad(data) {
  const paths = data.paths;
  const group = new THREE.Group();

  for (let i = 0; i < paths.length; i++) {
    const path = paths[i];

    const material = new THREE.MeshBasicMaterial({
      color: path.color,
      side: THREE.DoubleSide,
      depthWrite: false,
      transparent: true,
      opacity: path.userData.style.fillOpacity,
    });

    if (
      material.color.r === 1 &&
      material.color.g === 1 &&
      material.color.b === 1
    )
      material.color = new THREE.Color(state.backgroundColor);
    else material.color.convertLinearToSRGB();

    const shapes = SVGLoader.createShapes(path);

    for (let j = 0; j < shapes.length; j++) {
      const shape = shapes[j];
      const geometry = new THREE.ShapeGeometry(shape);

      if (i == 0) {
        geometry.computeBoundingSphere();
        center = geometry.boundingSphere.center;
        radius = geometry.boundingSphere.radius;
      }

      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.sub(center);
      group.add(mesh);
    }
  }

  group.rotation.z = Math.PI;
  group.rotation.y = Math.PI;
  group.scale.set(0.04, 0.04, 0.04);
  group.frustumCulled = false;
  scene.add(group);

  camera.position.set(0, 0, sizes.width < 1280 ? 22.0 : 11.0);
  camera.lookAt(new THREE.Vector3(0, 0, 0));

  const cylGeometry = new THREE.CylinderGeometry(1, 1, 1, 12, 2);
  cylinderMesh = new THREE.Mesh(cylGeometry, curveMaterial(null, null));

  const color = new THREE.Color("#657cca");
  color.convertLinearToSRGB();
  cylinderMesh.material.uniforms.curveColor.value.setFromColor(color);
  //657cca

  const distance = camera.position.z;
  const fov = (Math.PI * camera.fov) / 180.0;

  halfHeight = Math.tan(fov / 2) * distance * 1.0;
  halfWidth = halfHeight * camera.aspect * 0.99;
  cylinderMesh.rotation.z = -Math.PI / 2;

  cylinderMesh.scale.y = 2 * halfWidth;
  cylinderMesh.scale.x = 0.09;
  cylinderMesh.scale.z = 0.0; //0.05;

  cylinderObject = new THREE.Object3D();
  cylinderObject.add(cylinderMesh);
  cylinderObject.position.x = -halfWidth; //(sizes.width * 5.0) / sizes.height;
  cylinderObject.position.y = camera.position.y - halfHeight;

  scene.add(cylinderObject);
}

function buildLoadScene(renderer, inSizes, url) {
  if (inSizes) {
    sizes.width = inSizes.width;
    sizes.height = inSizes.height;
  }

  camera = new THREE.PerspectiveCamera(
    60,
    sizes.width / sizes.height,
    0.1,
    1000,
  );
  const loader = new SVGLoader();
  scene.background = new THREE.Color("#1A34A7");
  scene.add(camera);

  loader.load(url ? url + "logo_EF_sign.svg" : "logo_EF_sign.svg", onLoad);
  // noLoad();
}

function renderLoadScene(renderer) {
  //controls.update();
  const complete = getComplete();

  targetSize = complete / 100;
  size += 0.05 * (targetSize - size);
  size = size > 0.995 ? 1.0 : size;
  if (cylinderMesh) {
    cylinderMesh.material.uniforms.upperTime.value = size;

    const distance = camera.position.z;
    const fov = (Math.PI * camera.fov) / 180.0;
    halfHeight = Math.tan(fov / 2) * distance * 1.0;
    halfWidth = halfHeight * camera.aspect * 0.99;
    cylinderObject.position.x = -halfWidth; //(sizes.width * 5.0) / sizes.height;
    cylinderObject.position.y = camera.position.y - halfHeight + 0.2;
  }
  renderer.render(scene, camera);
}

function cleanLoadScene() {
  camera = null;
  controls = null;
  cylinderMesh = null;
  cylinderObject = null;

  targetSize = 0;
  size = 0;

  center = 0;
  radius = 0;
}

export { cleanLoadScene,buildLoadScene, renderLoadScene, getSize, getLoadCamera };
