import * as THREE from "three";
import getState from "../state";
import { getVehicles } from "./vehicleManager";
import { addLayersToShader } from "../layers/layers";
import { getAssets } from "../loader";
import { easings } from "../easing";

const materials = [];
const state = getState();
let t = 1.0;
let periodicCircularT = 0.0;

let sceneImpulseRunning = false;
let sceneImpulseTime = 0.0;

const effectiveInfintyVector = new THREE.Vector3(-1000, 0, 0);

let dt = 0.01; //0.008;
let dct = 0.003;
let go = false;

let tankT = 0;
let tankGo = false;
let tankDt = 0.001;

let waterT = 0.0;

const materialAnimations = [];
const globalMaterialAnimations = [];

function startSceneImpulse(x, y, z) {
  sceneImpulseRunning = true;
  sceneImpulseTime = 0.0;

  const position = new THREE.Vector3(x, y, z);

  materials.forEach((material) => {
    material.uniforms["sceneImpulseCenter"].value.copy(position);
    material.uniforms["sceneImpulseTime"].value = sceneImpulseTime;
  });
}

function getMaterialByIndex(index) {
  return materials[index];
}

function setDynamicMaterialMatCapByIndex(index, matcap) {
  materials[index].uniforms.matCap.value = matcap;
}

function addDynamicMaterial(material) {
  materials.push(material);
}

function getActiveLayersFromState() {
  return state.layers.filter((layer) => layer.active);
}

function updateLayersData(materials) {
  const assets = getAssets();
  const activeLayers = getActiveLayersFromState();
  materials.forEach((material) => {
    material.cleanLayers();
    activeLayers.forEach((activeLayer) => {
      material.addLayer(activeLayer, assets.layerTextures);
    });
  });
}

function startTankAnimation(up) {
  tankT = up ? 0 : 1;
  tankGo = true;
  tankDt = up ? Math.abs(tankDt) : -Math.abs(tankDt);
}
function startAnimation(position) {
  t = 0;
  go = true;

  const globalAnimation = { position: position, time: 0, finished: false };
  globalMaterialAnimations.push(globalAnimation);

  if (globalMaterialAnimations.length > 4) {
    globalMaterialAnimations[0].finished = true;
    globalMaterialAnimations.shift();
  }

  globalMaterialAnimations.forEach((gma, index) => {
    materials.forEach((m) => {
      m.uniforms["materialImpulseCenter" + index].value.copy(gma.position);
    });
  });

  return globalAnimation;
}

function commitTankMaterialAnimation(data) {
  materialAnimations.push({
    ...data,
    time: 0,
    dead: false,
    dt: state.velocities.tankMaterialDt,
  });
}

function updateDynamicMaterials() {
  const vehicles = getVehicles();

  const times = [];
  for (let i = 0; i < globalMaterialAnimations.length; i++) {
    globalMaterialAnimations[i].time += state.velocities.globalMaterialsDt;
    globalMaterialAnimations[i].time = Math.min(
      1,
      globalMaterialAnimations[i].time,
    );
    times.push(globalMaterialAnimations[i].time);
  }

  periodicCircularT =
    (periodicCircularT + state.velocities.circularVehicleDt) % 1;

  if (tankGo) tankT += tankDt;
  waterT += 0.00025;

  waterT = waterT % 10000.0;

  //tank materials updates

  let toKill = true;
  let toKillN = -1;
  for (let i = 0; i < materialAnimations.length; i++) {
    const materialAnimation = materialAnimations[i];

    if (materialAnimation.dead && toKill) toKillN = i;
    else toKill = false;

    if (materialAnimation.dead) continue;

    materialAnimation.time += materialAnimation.dt;
    materialAnimation.time = Math.min(1, materialAnimation.time);
    materialAnimation.time = Math.max(0, materialAnimation.time);
    materialAnimation.material.uniforms.tankT.value = materialAnimation.up
      ? materialAnimation.time
      : 1 - materialAnimation.time;

    if (materialAnimation.time >= 1.0) materialAnimation.dead = true;
  }

  for (let i = 0; i < toKillN + 1; i++) materialAnimations.shift();
  //global material animations
  materials.forEach((m) => {
    for (let i = 0; i < 4; i++) {
      if (i < times.length) {
        m.uniforms["t" + i].value = times[i];
        const gma = globalMaterialAnimations[i];
        m.uniforms["materialImpulseCenter" + i].value.copy(gma.position);
      } else m.uniforms["t" + i].value = 0;
    }

    if (sceneImpulseRunning && sceneImpulseTime > 1)
      sceneImpulseRunning = false;
    if (sceneImpulseRunning) {
      if (state.startScreenCamera)
        sceneImpulseTime += state.velocities.sceneImpulseDt * 0.6;
      else if (state.cameraUp)
        sceneImpulseTime += state.velocities.sceneImpulseDt * 0.5;
      else sceneImpulseTime += state.velocities.sceneImpulseDt * 0.3;
    }

    const easedSceneImpulseTime = easings["quadraticInOut"](sceneImpulseTime);
    m.uniforms["sceneImpulseTime"].value = easedSceneImpulseTime;

    m.uniforms.cameraDown.value =
      !state.cameraUp && !state.startScreenCamera ? 1.0 : 0.0;
    m.uniforms.waterT.value = waterT;
    m.uniforms.circularT.value = periodicCircularT;
    m.uniforms.circularCenter.value.copy(
      state.focusVehicle == -1
        ? effectiveInfintyVector
        : vehicles[state.focusVehicle].body.position,
    );
  });
}

class dynamicMaterialInteraction {
  constructor(data, type) {
    this.data = data;
    this.type = type ? type : "default";
    this.running = false;
    this.started = false;
    this.materialAnimation = null;
    this.finished = false;

    this.getT = () => {
      switch (this.type) {
        case "tank":
          return this.materialAnimation ? this.materialAnimation.time : 0;
        case "default":
        default:
          return this.materialAnimation
            ? this.materialAnimation.finished
              ? 1.0
              : this.materialAnimation.time
            : 0.0;
      }
    };
  }

  start() {
    if (this.type == "default") {
      this.materialAnimation = startAnimation(this.data.position);
    } else if (this.type == "tank") {
      commitTankMaterialAnimation(this.data);
      this.materialAnimation =
        materialAnimations[materialAnimations.length - 1];
    }
    this.running = true;
    this.started = true;
  }

  checkIfFinished() {
    if (!this.started) return false;

    let t = this.getT();

    if (t >= 1) {
      this.running = false;
      this.finished = true;
    }

    return this.finished;
  }
  checkIfStarted() {
    return this.started;
  }

  checkIfRunning() {
    if (!this.started) return false;

    let t = this.getT();
    if (t < 1) return true;
    else return false;
  }
  toStartNextInteraction() {
    return this.nextInteractionStart == "now";
  }

  setNextInteractionStart(nextInteractionStart) {
    this.nextInteractionStart = nextInteractionStart;
  }
}

function updateDynamicMaterialsState() {
  updateLayersData(materials);
  materials.forEach((material) => {
    material.defines["NORMAL_TARGET"] =
      state.GTAO !== "off" && state.renderer === "composer";
    material.defines["FINAL_OUTPUT"] = state.renderer !== "composer";
    material.defines["TONEMAP"] =
      state.ToneMapping !== "NONE" && state.renderer !== "composer";
    material.defines["TONEMAP_SIMPLE"] = state.ToneMapping === "TONEMAP_SIMPLE";
    material.defines["TONEMAP_REINHARD"] =
      state.ToneMapping === "TONEMAP_REINHARD";
    material.defines["TONEMAP_REINHARD_LUMA"] =
      state.ToneMapping === "TONEMAP_REINHARD_LUMA";
    material.defines["TONEMAP_REINHARD_WHITE"] =
      state.ToneMapping === "TONEMAP_REINHARD_WHITE";
    material.defines["TONEMAP_FILMIC"] = state.ToneMapping === "TONEMAP_FILMIC";
    material.defines["TONEMAP_PHOTOGRAPHIC"] =
      state.ToneMapping === "TONEMAP_PHOTOGRAPHIC";
    material.defines["TONEMAP_UNCHARTED"] =
      state.ToneMapping === "TONEMAP_UNCHARTED";

    material.uniforms.toneMappingExposure.value = state.ToneMappingExposure;

    material.uniforms.bigPulseColor.value = new THREE.Color(
      state.BigPulseColor,
    );
    material.uniforms.bigPulseIntensity.value = state.BigPulseIntensity;

    material.uniforms.smallPulseColor.value = new THREE.Color(
      state.SmallPulseColor,
    );
    material.uniforms.smallPulseIntensity.value = state.SmallPulseIntensity;
    material.uniforms.ambientColor.value = new THREE.Color(state.ambientColor);
    material.uniforms.ambientIntensity.value = state.ambientIntensity;
    material.uniforms.backgroundColor.value = new THREE.Color(
      state.backgroundColor,
    );

    material.compile();
    material.needsUpdate = true;
  });
}

function cleanMaterialManager() {
  t = 1.0;
  periodicCircularT = 0.0;

  sceneImpulseRunning = false;
  sceneImpulseTime = 0.0;

  dt = 0.01; //0.008;
  dct = 0.003;
  go = false;

  tankT = 0;
  tankGo = false;
  tankDt = 0.001;

  waterT = 0.0;

  while (materials.length > 0) {
    materials.pop();
  }

  while (materialAnimations.length > 0) {
    materialAnimations.pop();
  }

  while (globalMaterialAnimations.length > 0) {
    globalMaterialAnimations.pop();
  }
}

export {
  cleanMaterialManager,
  startSceneImpulse,
  addDynamicMaterial,
  updateDynamicMaterials,
  startAnimation,
  dynamicMaterialInteraction,
  updateDynamicMaterialsState,
  getMaterialByIndex,
  setDynamicMaterialMatCapByIndex,
};
