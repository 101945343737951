import { typeInfo } from "../vehicleTypes";
import { getVehicles } from "./vehicleManager";
import { addInteractionSequence } from "./interactionManager";

let worker;
let onWorkerMessage = null;

function initializeWorker(inURL) {
  let url;

  if (!inURL) url = "";
  else url = inURL;

  worker = new Worker(url + "worker.js");
  worker.onmessage = (e) => {
    const type = e.data[0];
    const vehicles = getVehicles();
    switch (type) {
      case "addInteraction":
        const { interaction, num } = e.data[1];
        const vehicle = vehicles[num];
        addInteractionSequence(vehicle, interaction);
        break;
      case "vehiclesUpdate":
      default:
        const { positionArray, quaternionArray, innerTimeArray } = e.data[1];
        vehicles.forEach((v) => {
          v.updateVehicleSceneMeshes(
            positionArray,
            quaternionArray,
            innerTimeArray,
          );
        });
        break;
    }
  };
}

function initPhysicsArrays(vehicleDescriptions) {
  let positionArraySize = 0;
  let quaternionArraySize = 0;
  let numberOfVehicles = vehicleDescriptions.length;
  vehicleDescriptions.forEach((vehicleDescription) => {
    const info = typeInfo[vehicleDescription.type];
    positionArraySize += 3 + 3 * info.nWheels;
    quaternionArraySize += 4 + 4 * info.nWheels;
  });

  worker.postMessage([
    "initialize",
    { positionArraySize, quaternionArraySize, numberOfVehicles },
  ]);
}

function createPhysicalVehicle(vehicle, vehiclePathData) {
  const perVehicleData = {
    boundingSphereRadius: vehicle.boundingSphereRadius,
    wheelRadius: vehicle.wheelRadius,
    type: vehiclePathData.type,
    stopPointIndices: vehiclePathData.stopIndices,
    interactionIndices: vehiclePathData.interactionIndices,
    initialPositionIndex: vehiclePathData.initialPositionIndex,
    curveIndex: vehiclePathData.path,
  };
  worker.postMessage(["addVehicle", perVehicleData]);
}

function addPhysicalCurve(curveObject) {
  worker.postMessage(["addCurve", { positions: curveObject.positions }]);
}

function sendUpdateRequest(dt) {
  worker.postMessage(["updatePhysics", { data: dt }]);
}

function continueMovement(vehicle) {
  worker.postMessage(["continueMovement", { num: vehicle.num }]);
}

function killWorker() {
  worker.terminate();
}

export {
  killWorker,
  initializeWorker,
  addPhysicalCurve,
  createPhysicalVehicle,
  initPhysicsArrays,
  sendUpdateRequest,
  continueMovement,
};
