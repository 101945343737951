import * as THREE from "three";
import vertex from "./curveMaterial.glslv";
import fragment from "./curveMaterial.glslf";
import { LayeredMaterial } from "../layers/LayeredMaterial.mjs";
import { createStandardLayeredShader } from "../layers/layers";
import getState from "../state";

const state = getState();

const curveMaterial = (tex, matcap, width) => {
  return new LayeredMaterial(
    {
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: {
        curveTexture: { value: tex },
        matCap: { value: matcap },
        upperTime: { value: 1 },
        lowerTime: { value: 0 },
        insideTime: { value: 0 },
        width: { value: width },
        intensity: { value: 1.0 },
        curveColor: { value: new THREE.Vector3(1, 1, 1) },
        secondaryColor: { value: new THREE.Vector3(0, 0, 0) },
        secondaryIntensity: { value: 1.0 },
        toneMappingExposure: { value: 1.0 },
        resolution: {
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
      },
      side: THREE.DoubleSide,
      // depthWrite: true,
      //  depthTest: false,
      transparent: true,
      defines: {
        LAYERS: false,
        NO_TEXTURE: tex ? false : true,
        NO_CURVE: tex ? false : true,
        NORMAL_TARGET: false,
        PATH: false,
        FINAL_OUTPUT: true,
        TONEMAP: false,
        TONEMAP_SIMPLE: false,
        TONEMAP_REINHARD: false,
        TONEMAP_REINHARD_LUMA: false,
        TONEMAP_REINHARD_WHITE: false,
        TONEMAP_FILMIC: false,
        TONEMAP_FILMIC: false,
        TONEMAP_PHOTOGRAPHIC: false,
        TONEMAP_UNCHARTED: true,
      },
    },
    createStandardLayeredShader(fragment),
    state,
  );
};

export default curveMaterial;
