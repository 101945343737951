import overlayShader from "./glsl/overlay.glslf";
import darkenShader from "./glsl/darken.glslf";
import multiplyShader from "./glsl/multiply.glslf";
import colorBurnShader from "./glsl/colorBurn.glslf";
import linearBurnShader from "./glsl/linearBurn.glslf";
import darkerColorShader from "./glsl/darkerColor.glslf";
import lightenShader from "./glsl/lighten.glslf";
import screenShader from "./glsl/screen.glslf";
import colorDodgeShader from "./glsl/colorDodge.glslf";
import linearDodgeShader from "./glsl/linearDodge.glslf";
import lighterColorShader from "./glsl/lighterColor.glslf";
import softLightShader from "./glsl/softLight.glslf";
import hardLightShader from "./glsl/hardLight.glslf";
import vividLightShader from "./glsl/vividLight.glslf";
import linearLightShader from "./glsl/linearLight.glslf";
import pinLightShader from "./glsl/pinLight.glslf";
import hardMixShader from "./glsl/hardMix.glslf";
import differenceShader from "./glsl/difference.glslf";
import exclusionShader from "./glsl/exclusion.glslf";
import subtractShader from "./glsl/subtract.glslf";
import reverseSubtractShader from "./glsl/reverseSubtract.glslf";
import divideShader from "./glsl/divide.glslf";
import hueShader from "./glsl/hue.glslf";
import colorFunctionShader from "./glsl/colorFunction.glslf";
import saturationShader from "./glsl/saturation.glslf";
import luminosityShader from "./glsl/luminosity.glslf";

import { LayeredShader } from "./LayeredShader.mjs";

const layerTypeFunctionsCode = {
  base: "",
  overlay: overlayShader,
  darken: darkenShader,
  multiply: multiplyShader,
  colorBurn: colorBurnShader,
  linearBurn: linearBurnShader,
  darkerColor: darkerColorShader,
  lighten: lightenShader,
  screen: screenShader,
  colorDodge: colorDodgeShader,
  linearDodge: linearDodgeShader,
  lighterColor: lighterColorShader,
  softLight: softLightShader,
  hardLight: hardLightShader,
  vividLight: vividLightShader,
  linearLight: linearLightShader,
  pinLight: pinLightShader,
  hardMix: hardMixShader,
  difference: differenceShader,
  exclusion: exclusionShader,
  subtract: subtractShader,
  reverseSubtract: reverseSubtractShader,
  divide: divideShader,
  hue: hueShader,
  colorFunction: colorFunctionShader,
  saturation: saturationShader,
  luminosity: luminosityShader,
};

export const layerNames = {};
Object.keys(layerTypeFunctionsCode).forEach((key) => {
  layerNames[key] = key;
});

export function createStandardLayeredShader(defaultShader) {
  return new LayeredShader(defaultShader, layerTypeFunctionsCode);
}
