import * as THREE from "three";

function copyLayerFromLayerDescription(layerDescription) {
  const layer = { ...layerDescription };
  layer.input = {};
  layer.input.type = layerDescription.input.type;
  layer.input.value = null;
  return layer;
}

class LayeredMaterial extends THREE.ShaderMaterial {
  constructor(parameters, layeredFragmentShader, state) {
    super(parameters);
    this.layeredFragmentShader = layeredFragmentShader;
    this.state = state;
    this.layers = [];
  }

  compile() {
    this.layeredFragmentShader.compile(this.layers);
    this.fragmentShader = this.layeredFragmentShader.compiledShader;
    this.defines.LAYERS = this.layers.length > 0;
    this.updateUniforms();
  }
  addLayer(layerDescription, dataSource) {
    const layer = copyLayerFromLayerDescription(layerDescription);
    if (layerDescription.input.type === "texture")
      layer.input.value = dataSource[layerDescription.input.texture];
    else if (layerDescription.input.type === "color")
      layer.input.value = new THREE.Color(layerDescription.input.color);
    this.layers.push(layer);
  }

  updateUniforms() {
    const updatedUniforms = {};
    this.layers.forEach((layer, index) => {
      if (layer.input.type === "texture")
        updatedUniforms["layerTexture" + index] = { value: layer.input.value };
      else if (layer.input.type === "color")
        updatedUniforms["layerColor" + index] = { value: layer.input.value };
    });

    Object.keys(updatedUniforms).forEach((key) => {
      this.uniforms[key] = updatedUniforms[key];
    });

    const state = this.state;
    this.uniforms.resolution.value.set(
      state.width * state.pixelRatio,
      state.height * state.pixelRatio,
    );
  }

  cleanLayers() {
    while (this.layers.length > 0) this.layers.shift();
  }
  copy(source) {
    super.copy(source);
    this.layeredFragmentShader = source.layeredFragmentShader.clone();
    this.state = source.state;
    return this;
  }
}

export { LayeredMaterial };
