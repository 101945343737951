import { composerRender } from "../composer";
import { updateImpulsesMaterialsState } from "./impulseManager";
import { updateDynamicMaterialsState } from "./dynamicMaterialsManager";
import { updateVehiclesMaterialsState } from "./vehicleManager";
import { updatePostprocessesState } from "../composer";
import getState from "../state";
import { compileBackground } from "../background";

const scenes = [];
const renderers = [];
const cameras = [];
const state = getState();

function addRenderScene(scene) {
  scenes.push(scene);
}

function addRenderCamera(camera) {
  cameras.push(camera);
}

function addRenderer(renderer) {
  renderers.push(renderer);
}

function updateRenderingState() {
  updateVehiclesMaterialsState();
  updateDynamicMaterialsState();
  updateImpulsesMaterialsState();
  updatePostprocessesState();
  compileBackground();

  renderers[0].setPixelRatio(state.pixelRatio);
  //renderers[0].antialias = state.defaultFrameBufferAntialiasing;
}

function render() {
  if (state.renderer === "composer")
    composerRender(renderers[0], scenes[0], cameras[0]);
  else renderers[0].render(scenes[0], cameras[0]);
}

function cleanRenderManager() {
  while (scenes.length > 0) {
    scenes.pop();
  }
  while (renderers.length > 0) {
    renderers.pop();
  }
  while (cameras.length > 0) {
    cameras.pop();
  }
}

export {
	cleanRenderManager,
  addRenderScene,
  addRenderCamera,
  addRenderer,
  render,
  updateRenderingState,
};
