import { Curve } from "../curve";
import { getAssets } from "../loader";
import { getData } from "../scene";
import getState from "../state";
import * as THREE from "three";

const impulses = [];
const state = getState();
const assets = getAssets();

function updateImpulses() {
  let toKill = true;
  let toKillN = -1;

  for (let i = 0; i < impulses.length; i++) {
    const impulse = impulses[i];

    if (!impulse.alive && toKill) toKillN = i;
    else toKill = false;

    if (impulse.alive && impulse.time < 1) impulse.updateImpulse();
    else if (impulse.alive && impulse.toDie && impulse.time >= 1) impulse.die();
  }

  for (let i = 0; i < toKillN + 1; i++) impulses.shift();
}

function sendImpulse(position1, position2, scene, reverse) {
  const { _, __, assetsData } = getData();
  const middle = new THREE.Vector3(
    (position1.x + position2.x) / 2,
    (position1.y + position2.y) / 2 + 5, //10
    (position1.z + position2.z) / 2,
  );
  const positions = [position1, middle, position2];

  let impulse = new Curve(
    positions,
    scene,
    state.velocities.curveImpulseDt,
    true,
    300,
    reverse,
  );
  impulse.addCylinderMesh(
    scene,
    assetsData.orangeMatCap,
    null, //assetsData.blackMatCap,
    0.01,
  );
  impulse.updateSplineOutline();

  impulses.push(impulse);
}

class curveImpulseInteraction {
  constructor(position1, position2, scene, reverse) {
    this.data = { position1, position2, scene, reverse };
    this.running = false;
    this.started = false;
  }

  start() {
    const { position1, position2, scene, reverse } = this.data;
    sendImpulse(position1, position2, scene, reverse);
    this.impulse = impulses[impulses.length - 1];
    this.impulse.setUpperTime(0);
    this.impulse.setLowerTime(0);
    this.running = true;
    this.started = true;
  }

  checkIfFinished() {
    if (!this.started) return false;

    if (this.impulse.time >= 1 || !this.impulse.alive) {
      this.running = false;
      return true;
    } else return false;
  }

  checkIfStarted() {
    return this.started;
  }

  checkIfRunning() {
    if (!this.started) return false;

    if (this.impulse.time < 1 && this.impulse.alive) return true;
    else return false;
  }

  toStartNextInteraction() {
    return this.nextInteractionStart == "now";
  }

  setNextInteractionStart(nextInteractionStart) {
    this.nextInteractionStart = nextInteractionStart;
  }
}

function getActiveLayersFromState() {
  return state.layers.filter((layer) => layer.active);
}

function updateLayersData(material) {
  const activeLayers = getActiveLayersFromState();
  material.cleanLayers();
  activeLayers.forEach((activeLayer) => {
    material.addLayer(activeLayer, assets.layerTextures);
  });
}
function updateImpulsesMaterialsState() {
  impulses.forEach((impulse) => {
    const impulseMaterial1 = impulse.cylMesh.material;
    const impulseMaterial2 = impulse.cylMesh2
      ? impulse.cylMesh2.material
      : null;
    impulseMaterial1.defines["NO_TEXTURE"] = state.OilOutsideMatCap === "none";
    impulseMaterial1.defines["NORMAL_TARGET"] =
      state.GTAO !== "off" && state.renderer === "composer";
    impulseMaterial1.defines["FINAL_OUTPUT"] = state.renderer !== "composer";
    impulseMaterial1.defines["TONEMAP"] =
      state.ToneMapping !== "NONE" && state.renderer !== "composer";
    impulseMaterial1.defines["TONEMAP_SIMPLE"] =
      state.ToneMapping === "TONEMAP_SIMPLE";
    impulseMaterial1.defines["TONEMAP_REINHARD"] =
      state.ToneMapping === "TONEMAP_REINHARD";
    impulseMaterial1.defines["TONEMAP_REINHARD_LUMA"] =
      state.ToneMapping === "TONEMAP_REINHARD_LUMA";
    impulseMaterial1.defines["TONEMAP_REINHARD_WHITE"] =
      state.ToneMapping === "TONEMAP_REINHARD_WHITE";
    impulseMaterial1.defines["TONEMAP_FILMIC"] =
      state.ToneMapping === "TONEMAP_FILMIC";
    impulseMaterial1.defines["TONEMAP_PHOTOGRAPHIC"] =
      state.ToneMapping === "TONEMAP_PHOTOGRAPHIC";
    impulseMaterial1.defines["TONEMAP_UNCHARTED"] =
      state.ToneMapping === "TONEMAP_UNCHARTED";
    impulseMaterial1.uniforms.matCap.value =
      state.OilOutsideMatCap === "none" ? null : assets[state.OilOutsideMatCap];
    //impulseMaterial1.uniforms.width.value = state.pathWidth
    impulseMaterial1.uniforms.intensity.value = state.OilOutsideIntensity;
    impulseMaterial1.uniforms.toneMappingExposure.value =
      state.ToneMappingExposure;
    impulseMaterial1.uniforms.curveColor.value = new THREE.Color(
      state.OilOutsideColor,
    );
    updateLayersData(impulseMaterial1);
    impulseMaterial1.compile();

    if (impulseMaterial2) {
      impulseMaterial2.defines["NO_TEXTURE"] = state.OilInsideMatCap === "none";
      impulseMaterial2.defines["NORMAL_TARGET"] =
        state.GTAO !== "off" && state.renderer === "composer";
      impulseMaterial2.defines["FINAL_OUTPUT"] = state.renderer !== "composer";
      impulseMaterial2.defines["TONEMAP"] =
        state.ToneMapping !== "NONE" && state.renderer !== "composer";
      impulseMaterial2.defines["TONEMAP_SIMPLE"] =
        state.ToneMapping === "TONEMAP_SIMPLE";
      impulseMaterial2.defines["TONEMAP_REINHARD"] =
        state.ToneMapping === "TONEMAP_REINHARD";
      impulseMaterial2.defines["TONEMAP_REINHARD_LUMA"] =
        state.ToneMapping === "TONEMAP_REINHARD_LUMA";
      impulseMaterial2.defines["TONEMAP_REINHARD_WHITE"] =
        state.ToneMapping === "TONEMAP_REINHARD_WHITE";
      impulseMaterial2.defines["TONEMAP_FILMIC"] =
        state.ToneMapping === "TONEMAP_FILMIC";
      impulseMaterial2.defines["TONEMAP_PHOTOGRAPHIC"] =
        state.ToneMapping === "TONEMAP_PHOTOGRAPHIC";
      impulseMaterial2.defines["TONEMAP_UNCHARTED"] =
        state.ToneMapping === "TONEMAP_UNCHARTED";
      impulseMaterial2.uniforms.matCap.value =
        state.OilInsideMatCap === "none" ? null : assets[state.OilInsideMatCap];
      //impulseMaterial2.uniforms.width.value = state.pathWidth
      impulseMaterial2.uniforms.intensity.value = state.OilInsideIntensity;
      impulseMaterial2.uniforms.toneMappingExposure.value =
        state.ToneMappingExposure;
      impulseMaterial2.uniforms.curveColor.value = new THREE.Color(
        state.OilInsideColor,
      );
      updateLayersData(impulseMaterial2);
      impulseMaterial2.compile();
    }
    impulseMaterial1.needsUpdate = true;
    if (impulseMaterial2) impulseMaterial2.needsUpdate = true;
  });
}

function cleanImpulses() {
  while (impulses.length > 0) {
    impulses.pop();
  }
}

export {
	cleanImpulses,
  updateImpulses,
  sendImpulse,
  curveImpulseInteraction,
  updateImpulsesMaterialsState,
};
