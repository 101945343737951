import { continueMovement } from "./workerManager";
class vehicleContinueMovementInteraction {
  constructor(vehicle) {
    this.vehicle = vehicle;
    this.running = false;
    this.started = false;
  }

  start() {
    continueMovement(this.vehicle);
    this.running = true;
    this.started = true;
  }

  checkIfFinished() {
    if (!this.started) return false;
    else return true;
  }
  checkIfStarted() {
    return this.started;
  }

  checkIfRunning() {
    if (!this.started) return false;
    else return true;
  }
  toStartNextInteraction() {
    return true;
  }

  setNextInteractionStart(nextInteractionStart) {}
}

export { vehicleContinueMovementInteraction };
