import AnimatedText from "../text";
import getState from "../state";
const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
const texts = [];
const transitions = [];
const state = getState();

function addText(index) {
  const text = new AnimatedText(index);
  text.setAnimation(0);
  text.setTime(0);
  texts.push(text);
}

function updateTextsPositiioning() {
  texts.forEach((text) => {
    text.updatePositioning();
  });
}

function addTransition(description) {
  transitions.push({
    ...description,
    dt: state.velocities.textTransitionSpeed,
    time: 0,
    dead: false,
  });
  //else
  //transitions[previousIndexOfATransitioningText] = {
  // ...description,
  //  dt: 0.02,
  //  time: 0,
  //  dead: false,
  // };
}

function updateTextTransitions() {
  let toKill = true;
  let toKillN = -1;

  for (let i = 0; i < transitions.length; i++) {
    const transition = transitions[i];

    if (transition.dead && toKill) toKillN = i;
    else toKill = false;

    if (transition.dead) continue;

    transition.time += transition.dt;
    const text = texts[transition.textIndex];
    if (text)
      text.setTime(
        transition.reverse
          ? clamp(1.0 - transition.time, 0, 1)
          : clamp(transition.time, 0, 1)
      );

    if (transition.time >= 1.0) transition.dead = true;
  }

  for (let i = 0; i < toKillN + 1; i++) transitions.shift();
}

class textTransitionInteraction {
  constructor(data, type) {
    this.data = data;
    this.type = type ? type : "default";
    this.running = false;
    this.started = false;
    this.finished = false;
  }

  start() {
    this.running = true;
    this.started = true;

    addTransition(this.data);
    this.transition = transitions[transitions.length - 1];

    this.getT = () => {
      return this.transition.time;
    };
  }

  checkIfFinished() {
    if (!this.started) return false;

    let t = this.getT();

    if (t >= 1) {
      this.running = false;
      this.finished = true;
    }

    return this.finished;
  }
  checkIfStarted() {
    return this.started;
  }

  checkIfRunning() {
    if (!this.started) return false;

    let t = this.getT();
    if (t < 1) return true;
    else return false;
  }
  toStartNextInteraction() {
    return this.nextInteractionStart == "now";
  }

  setNextInteractionStart(nextInteractionStart) {
    this.nextInteractionStart = nextInteractionStart;
  }
}

export {
  addTransition,
  updateTextTransitions,
  addText,
  updateTextsPositiioning,
  textTransitionInteraction,
};
