import * as THREE from "three";
import vertex from "./hologram.glslv";
import fragment from "./hologram.glslf";
import { getAssets } from "../loader";

const hologramMaterial = () => {
  const assets = getAssets();
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    uniforms: {},
    defines: {
      FINAL_OUTPUT: true,
      TONEMAP: false,
      TONEMAP_SIMPLE: false,
      TONEMAP_REINHARD: false,
      TONEMAP_REINHARD_LUMA: false,
      TONEMAP_REINHARD_WHITE: false,
      TONEMAP_FILMIC: false,
      TONEMAP_FILMIC: false,
      TONEMAP_PHOTOGRAPHIC: false,
      TONEMAP_UNCHARTED: true,
    },
    transparent: false,
  });
};

export default hologramMaterial;
