import { Curve } from "../curve";
import { getAssets } from "../loader";
import getState from "../state";
import * as THREE from "three";

const state = getState();

const curves = {};

function createCurve(scene, positions) {
  const curveObject = new Curve(positions, scene);
  curveObject.addCylinderMesh(scene);
  //curves.push(curveObject);
  const N = Object.keys(curves).length;
  curves["curve" + N] = curveObject;
}

function createDefaultCurve(scene, N) {
  const defaultPosition = new THREE.Vector3(0.0, 0.2, 0.0);
  const positions = [];

  for (let i = 0; i < N; i++) {
    positions.push(defaultPosition.clone());
    positions[i].x = i;
  }

  const assets = getAssets();

  const curveObject = new Curve(positions, scene);
  curveObject.addCylinderMesh(scene, assets.bluishMatCap, assets.orangeMatCap);
  //curves.push(curveObject);
  const M = Object.keys(curves).length;
  curves["curve" + M] = curveObject;
}

function addCurve(curve) {
  //curves.push(curve);
  const N = Object.keys(curves).length;
  curves["curve" + N] = curve;
}

function getCurve(index) {
  return curves[index];
}

function getCurvesList() {
  return curves;
}

function updateStateCurves() {
  state.curves = [];

  for (let i = 0; i < Object.keys(curves).length; i++) state.curves.push([]);

  Object.keys(curves).forEach((curveName) => {
    const index = curveName.slice(5);
    const positions = curves[curveName].curve.points.map((p) => {
      return { x: p.x, y: p.y, z: p.z };
    });

    state.curves[index] = { positions, render: false };
  });
}

function cleanCurves() {
  Object.keys(curves).forEach((key) => delete curves[key]);
  state.curves = [];
}

export {
  cleanCurves,
  addCurve,
  getCurve,
  getCurvesList,
  createCurve,
  createDefaultCurve,
  updateStateCurves,
};
