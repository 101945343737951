import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import * as THREE from "three";
import shaderLoad from "./shaders";
import getState from "./state";
import { LayeredMaterial } from "./layers/LayeredMaterial.mjs";
import { createStandardLayeredShader } from "./layers/layers";
import { getAssets } from "./loader";

const shaders = shaderLoad();
const state = getState();

const pixelRatio = 1;
const renderWidth = pixelRatio * window.innerWidth;
const renderHeight = pixelRatio * window.innerHeight;

const GTAOScaleDown = 1;
const samples = 0;

const pipeline = [];

const depthNormalColorRenderTarget = new THREE.WebGLMultipleRenderTargets(
  renderWidth,
  renderHeight,
  2,
  { samples: samples, type: THREE.FloatType, depthBuffer: true },
);

depthNormalColorRenderTarget.depthTexture = new THREE.DepthTexture(
  renderWidth,
  renderHeight,
);
depthNormalColorRenderTarget.depthTexture.format = THREE.DepthFormat;
depthNormalColorRenderTarget.depthTexture.type = THREE.FloatType;

const colorRenderTarget = new THREE.WebGLRenderTarget(
  renderWidth,
  renderHeight,
  { samples: samples, type: THREE.FloatType, depthBuffer: true },
);

colorRenderTarget.depthTexture = new THREE.DepthTexture(
  renderWidth,
  renderHeight,
);
colorRenderTarget.depthTexture.format = THREE.DepthFormat;
colorRenderTarget.depthTexture.type = THREE.FloatType;

const GTAOMaskRenderTarget = new THREE.WebGLRenderTarget(
  renderWidth * state.GTAODownscaleMultiplier, //4
  renderHeight * state.GTAODownscaleMultiplier, // 4
  { type: THREE.FloatType },
);

const GTAOBlurRenderTarget = new THREE.WebGLRenderTarget(
  renderWidth * state.GTAODownscaleMultiplier, //4
  renderHeight * state.GTAODownscaleMultiplier, // 4
  { type: THREE.FloatType },
);

const applyMaskRenderTarget = new THREE.WebGLRenderTarget(
  renderWidth,
  renderHeight,
  { type: THREE.FloatType },
);

const blurDownsample1RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 1),
  renderHeight / Math.pow(2, 1),
  { type: THREE.FloatType },
);

const blurDownsample2RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 2),
  renderHeight / Math.pow(2, 2),
  { type: THREE.FloatType },
);
const blurDownsample3RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 3),
  renderHeight / Math.pow(2, 3),
  { type: THREE.FloatType },
);
const blurDownsample4RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 4),
  renderHeight / Math.pow(2, 4),
  { type: THREE.FloatType },
);
const blurDownsample5RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 5),
  renderHeight / Math.pow(2, 5),
  { type: THREE.FloatType },
);

const blurUpsample1RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 4),
  renderHeight / Math.pow(2, 4),
  { type: THREE.FloatType },
);
const blurUpsample2RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 3),
  renderHeight / Math.pow(2, 3),
  { type: THREE.FloatType },
);
const blurUpsample3RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 2),
  renderHeight / Math.pow(2, 2),
  { type: THREE.FloatType },
);
const blurUpsample4RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 1),
  renderHeight / Math.pow(2, 1),
  { type: THREE.FloatType },
);
const blurUpsample5RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 0),
  renderHeight / Math.pow(2, 0),
  { type: THREE.FloatType },
);

const blurDownsample1ShaderPass = new ShaderPass(
  shaders.BloomDownsample({
    width: renderWidth,
    height: renderHeight,
  }),
);
const blurDownsample2ShaderPass = new ShaderPass(
  shaders.BloomDownsample({
    width: renderWidth / 2,
    height: renderHeight / 2,
  }),
);
const blurDownsample3ShaderPass = new ShaderPass(
  shaders.BloomDownsample({
    width: renderWidth / 4,
    height: renderHeight / 4,
  }),
);
const blurDownsample4ShaderPass = new ShaderPass(
  shaders.BloomDownsample({
    width: renderWidth / 8,
    height: renderHeight / 8,
  }),
);
const blurDownsample5ShaderPass = new ShaderPass(
  shaders.BloomDownsample({
    width: renderWidth / 16,
    height: renderHeight / 16,
  }),
);

const blurUpsample1ShaderPass = new ShaderPass(shaders.BloomUpsample(false));
const blurUpsample2ShaderPass = new ShaderPass(shaders.BloomUpsample(false));
const blurUpsample3ShaderPass = new ShaderPass(shaders.BloomUpsample(false));
const blurUpsample4ShaderPass = new ShaderPass(shaders.BloomUpsample(false));
const blurUpsample5ShaderPass = new ShaderPass(shaders.BloomUpsample(true));

const GTAOShaderPass = new ShaderPass(shaders.GTAO);
const GTAOBlurShaderPass = new ShaderPass(shaders.SSAOBlur);
const applyMaskPass = new ShaderPass(shaders.applyMask);
const FinalPass = new ShaderPass(shaders.Final);
FinalPass.material = new LayeredMaterial(
  shaders.Final,
  createStandardLayeredShader(shaders.Final.fragmentShader),
  state,
);

function getActiveLayersFromState() {
  return state.layers.filter((layer) => layer.active);
}

function updateLayersData(material) {
  const assets = getAssets();
  const activeLayers = getActiveLayersFromState();
  material.cleanLayers();
  activeLayers.forEach((activeLayer) => {
    material.addLayer(activeLayer, assets.layerTextures);
  });
}

function setRenderTargetsSizes() {
  const pixelRatio = state.pixelRatio;
  const renderWidth = state.width * pixelRatio; //pixelRatio * window.innerWidth;
  const renderHeight = state.height * pixelRatio; //pixelRatio * window.innerHeight;

  const samples = state.geometryRenderMSAASamples;

  depthNormalColorRenderTarget.setSize(renderWidth, renderHeight);
  depthNormalColorRenderTarget.samples = samples;

  colorRenderTarget.setSize(renderWidth, renderHeight);
  colorRenderTarget.samples = samples;

  GTAOMaskRenderTarget.setSize(
    renderWidth * state.GTAODownscaleMultiplier,
    renderHeight * state.GTAODownscaleMultiplier,
  );
  GTAOBlurRenderTarget.setSize(
    renderWidth * state.GTAODownscaleMultiplier,
    renderHeight * state.GTAODownscaleMultiplier,
  );

  applyMaskRenderTarget.setSize(renderWidth, renderHeight);
  blurDownsample1RenderTarget.setSize(renderWidth / 2, renderHeight / 2);
  blurDownsample2RenderTarget.setSize(renderWidth / 4, renderHeight / 4);
  blurDownsample3RenderTarget.setSize(renderWidth / 8, renderHeight / 8);
  blurDownsample4RenderTarget.setSize(renderWidth / 16, renderHeight / 16);
  blurDownsample5RenderTarget.setSize(renderWidth / 32, renderHeight / 32);

  blurUpsample1RenderTarget.setSize(renderWidth / 16, renderHeight / 16);
  blurUpsample2RenderTarget.setSize(renderWidth / 8, renderHeight / 8);
  blurUpsample3RenderTarget.setSize(renderWidth / 4, renderHeight / 4);
  blurUpsample4RenderTarget.setSize(renderWidth / 2, renderHeight / 2);
  blurUpsample5RenderTarget.setSize(renderWidth, renderHeight);

  GTAOShaderPass.material.uniforms.resolution.value.set(
    renderWidth * state.GTAODownscaleMultiplier,
    renderHeight * state.GTAODownscaleMultiplier,
  );

  GTAOBlurShaderPass.material.uniforms.resolution.value.set(
    renderWidth * state.GTAODownscaleMultiplier,
    renderHeight * state.GTAODownscaleMultiplier,
  );
  FinalPass.material.uniforms.resolution.value.set(renderWidth, renderHeight);
}

function setUniforms(camera) {
  GTAOShaderPass.material.uniforms.viewMatrix.value = camera.matrixWorldInverse;
  GTAOShaderPass.material.uniforms.viewMatrixInverse.value = camera.matrixWorld;
  GTAOShaderPass.material.uniforms.projectionMatrixInverse.value =
    camera.projectionMatrixInverse;
  updatePostprocessesState();
}

function composerRender(renderer, scene, camera) {
  for (let i = 0; i < pipeline.length; i++) {
    pipeline[i](renderer, scene, camera);
  }
}

function buildPipeline() {
  while (pipeline.length > 0) pipeline.pop();

  if (state.GTAO !== "off")
    pipeline.push((renderer, scene, camera) => {
      renderer.setRenderTarget(depthNormalColorRenderTarget);
      renderer.render(scene, camera);
    });
  else
    pipeline.push((renderer, scene, camera) => {
      renderer.setRenderTarget(colorRenderTarget);
      renderer.render(scene, camera);
    });

  if (state.GTAO === "non-blured")
    pipeline.push((renderer, scene, camera) => {
      GTAOShaderPass.render(renderer, GTAOMaskRenderTarget);
      applyMaskPass.render(renderer, applyMaskRenderTarget);
    });

  if (state.GTAO === "blured")
    pipeline.push((renderer, scene, camera) => {
      GTAOShaderPass.render(renderer, GTAOMaskRenderTarget);
      GTAOBlurShaderPass.render(renderer, GTAOBlurRenderTarget);
      applyMaskPass.render(renderer, applyMaskRenderTarget);
    });

  if (state.Bloom === "on")
    pipeline.push((renderer, scene, camera) => {
      blurDownsample1ShaderPass.render(renderer, blurDownsample1RenderTarget);
      blurDownsample2ShaderPass.render(renderer, blurDownsample2RenderTarget);
      blurDownsample3ShaderPass.render(renderer, blurDownsample3RenderTarget);
      blurDownsample4ShaderPass.render(renderer, blurDownsample4RenderTarget);
      blurDownsample5ShaderPass.render(renderer, blurDownsample5RenderTarget);

      blurUpsample1ShaderPass.render(renderer, blurUpsample1RenderTarget);
      blurUpsample2ShaderPass.render(renderer, blurUpsample2RenderTarget);
      blurUpsample3ShaderPass.render(renderer, blurUpsample3RenderTarget);
      blurUpsample4ShaderPass.render(renderer, blurUpsample4RenderTarget);
      blurUpsample5ShaderPass.render(renderer, blurUpsample5RenderTarget);
    });

  pipeline.push((renderer, scene, camera) => {
    FinalPass.render(renderer, null);
  });

  GTAOShaderPass.material.uniforms.u_normal.value =
    depthNormalColorRenderTarget.texture[1];
  GTAOShaderPass.material.uniforms.u_depth.value =
    depthNormalColorRenderTarget.depthTexture;

  GTAOBlurShaderPass.material.uniforms.u_color.value =
    GTAOMaskRenderTarget.texture;

  applyMaskPass.material.uniforms.u_color.value =
    depthNormalColorRenderTarget.texture[0];
  applyMaskPass.material.uniforms.u_mask.value =
    state.GTAO === "blured"
      ? GTAOBlurRenderTarget.texture
      : GTAOMaskRenderTarget.texture;

  blurDownsample1ShaderPass.material.uniforms.u_previous.value =
    state.GTAO !== "off"
      ? applyMaskRenderTarget.texture
      : colorRenderTarget.texture;
  //depthNormalColorRenderTarget.texture[0]; //applyMaskRenderTarget.texture;
  blurDownsample2ShaderPass.material.uniforms.u_previous.value =
    blurDownsample1RenderTarget.texture;
  blurDownsample3ShaderPass.material.uniforms.u_previous.value =
    blurDownsample2RenderTarget.texture;
  blurDownsample4ShaderPass.material.uniforms.u_previous.value =
    blurDownsample3RenderTarget.texture;
  blurDownsample5ShaderPass.material.uniforms.u_previous.value =
    blurDownsample4RenderTarget.texture;

  blurUpsample1ShaderPass.material.uniforms.u_previous.value =
    blurDownsample5RenderTarget.texture;
  blurUpsample1ShaderPass.material.uniforms.u_background.value =
    blurDownsample4RenderTarget.texture;

  blurUpsample2ShaderPass.material.uniforms.u_previous.value =
    blurUpsample1RenderTarget.texture;
  blurUpsample2ShaderPass.material.uniforms.u_background.value =
    blurDownsample3RenderTarget.texture;

  blurUpsample3ShaderPass.material.uniforms.u_previous.value =
    blurUpsample2RenderTarget.texture;
  blurUpsample3ShaderPass.material.uniforms.u_background.value =
    blurDownsample2RenderTarget.texture;

  blurUpsample4ShaderPass.material.uniforms.u_previous.value =
    blurUpsample3RenderTarget.texture;
  blurUpsample4ShaderPass.material.uniforms.u_background.value =
    blurDownsample1RenderTarget.texture;

  blurUpsample5ShaderPass.material.uniforms.u_previous.value =
    blurUpsample4RenderTarget.texture;
  blurUpsample5ShaderPass.material.uniforms.u_background.value =
    state.GTAO !== "off"
      ? applyMaskRenderTarget.texture
      : colorRenderTarget.texture;

  //depthNormalColorRenderTarget.texture[0]; //applyMaskRenderTarget.texture;

  if (state.Bloom === "off" && state.GTAO === "off")
    FinalPass.material.uniforms.u_color.value = colorRenderTarget.texture;
  else if (state.Bloom === "on")
    FinalPass.material.uniforms.u_color.value =
      blurUpsample5RenderTarget.texture;
  else
    FinalPass.material.uniforms.u_color.value = applyMaskRenderTarget.texture;

  FinalPass.renderToScreen = true;
}

function updatePostprocessesState() {
  //GTAOShaderPass.material.uniforms.resolution.value.set(
  //  renderWidth / GTAOScaleDown,
  //  renderHeight / GTAOScaleDown
  //);
  GTAOShaderPass.material.defines["SLICE_COUNT"] = state.sliceCount;
  GTAOShaderPass.material.defines["DIR_SAMPLES"] = state.directionalSamples;

  GTAOShaderPass.material.uniforms.scaling.value = state.scaling;

  applyMaskPass.material.uniforms.gtaocolor.value.set(state.GTAOcolor);

  blurUpsample1ShaderPass.material.uniforms.filterRadius.value =
    state.filterRadius;
  blurUpsample1ShaderPass.material.uniforms.bloomStrength.value =
    state.bloomStrength;

  blurUpsample2ShaderPass.material.uniforms.filterRadius.value =
    state.filterRadius;
  blurUpsample2ShaderPass.material.uniforms.bloomStrength.value =
    state.bloomStrength;

  blurUpsample3ShaderPass.material.uniforms.filterRadius.value =
    state.filterRadius;
  blurUpsample3ShaderPass.material.uniforms.bloomStrength.value =
    state.bloomStrength;

  blurUpsample4ShaderPass.material.uniforms.filterRadius.value =
    state.filterRadius;
  blurUpsample4ShaderPass.material.uniforms.bloomStrength.value =
    state.bloomStrength;

  blurUpsample5ShaderPass.material.uniforms.filterRadius.value =
    state.filterRadius;
  blurUpsample5ShaderPass.material.uniforms.bloomStrength.value =
    state.bloomStrength;

  FinalPass.material.defines["TONEMAP"] = state.ToneMapping !== "NONE";
  FinalPass.material.defines["TONEMAP_SIMPLE"] =
    state.ToneMapping === "TONEMAP_SIMPLE";
  FinalPass.material.defines["TONEMAP_REINHARD"] =
    state.ToneMapping === "TONEMAP_REINHARD";
  FinalPass.material.defines["TONEMAP_REINHARD_LUMA"] =
    state.ToneMapping === "TONEMAP_REINHARD_LUMA";
  FinalPass.material.defines["TONEMAP_REINHARD_WHITE"] =
    state.ToneMapping === "TONEMAP_REINHARD_WHITE";
  FinalPass.material.defines["TONEMAP_FILMIC"] =
    state.ToneMapping === "TONEMAP_FILMIC";
  FinalPass.material.defines["TONEMAP_PHOTOGRAPHIC"] =
    state.ToneMapping === "TONEMAP_PHOTOGRAPHIC";
  FinalPass.material.defines["TONEMAP_UNCHARTED"] =
    state.ToneMapping === "TONEMAP_UNCHARTED";

  FinalPass.material.uniforms.toneMappingExposure.value =
    state.ToneMappingExposure;

  updateLayersData(FinalPass.material);
  FinalPass.material.compile();

  FinalPass.material.needsUpdate = true;
  GTAOShaderPass.material.needsUpdate = true;

  setRenderTargetsSizes();
  buildPipeline();
}

function cleanPipeline() {
  while (pipeline.length > 0) {
    pipeline.pop();
  }
}

export {
  cleanPipeline,
  composerRender,
  setUniforms,
  updatePostprocessesState,
  setRenderTargetsSizes,
};
