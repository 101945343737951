import * as THREE from "three";

export const typeInfo = {
  Med: {
    height: 0.5,
    chassisShapeCoefficients: { x: 1.2, y: 1.1, z: 0.45 },
    nWheels: 4,
    mass: 100,
    offsetBody: { x: 0, y: 1, z: -3 },
    suspensionStiffness: 50,
    restLength: 0.25,
    brakeForce: 0.6,
    maxSpeed: 7.5,
    wheelPositions: [
      new THREE.Vector3(0.3, -0.175, 0.04),
      new THREE.Vector3(0.3, 0.175, 0.04),
      new THREE.Vector3(-0.22, 0.18, 0.04),
      new THREE.Vector3(-0.22, -0.18, 0.04),
    ],
  },
  Taxi: {
    height: 0.5,
    chassisShapeCoefficients: { x: 1.2, y: 1.1, z: 0.45 },
    nWheels: 4,
    mass: 100,
    offsetBody: { x: 0, y: 1, z: -3 },
    suspensionStiffness: 50,
    restLength: 0.25,
    brakeForce: 0.6,
    maxSpeed: 9,
    wheelPositions: [
      new THREE.Vector3(0.22, -0.154, 0.04),
      new THREE.Vector3(0.22, 0.154, 0.04),
      new THREE.Vector3(-0.22, 0.154, 0.04),
      new THREE.Vector3(-0.22, -0.154, 0.04),
    ],
  },
  Car1: {
    height: 0.5,
    chassisShapeCoefficients: { x: 1.2, y: 1.1, z: 0.45 },
    nWheels: 4,
    mass: 100,
    offsetBody: { x: 0, y: 1, z: -3 },
    suspensionStiffness: 18,
    restLength: 0.3,
    brakeForce: 0.6,
    maxSpeed: 7,
    wheelPositions: [
      new THREE.Vector3(0.14, -0.1, 0.13),
      new THREE.Vector3(0.14, 0.1, 0.13),
      new THREE.Vector3(-0.15, 0.1, 0.13),
      new THREE.Vector3(-0.15, -0.1, 0.13),
    ],
  },
  Samosval: {
    height: 0.5,
    chassisShapeCoefficients: { x: 1.8, y: 1.2, z: 0.45 },
    nWheels: 6,
    mass: 150,
    offsetBody: { x: 0, y: 1, z: -3 },
    suspensionStiffness: 10,
    restLength: 0.5,
    brakeForce: 0.6,
    maxSpeed: 5,
    wheelPositions: [
      new THREE.Vector3(0.43, -0.3, 0.15),
      new THREE.Vector3(0.43, 0.3, 0.15),
      new THREE.Vector3(-0.15, 0.32, 0.18),
      new THREE.Vector3(-0.15, 0.16, 0.18),
      new THREE.Vector3(-0.15, -0.16, 0.18),
      new THREE.Vector3(-0.15, -0.32, 0.18),
    ],
  },
  Musorovoz: {
    height: 0.9,
    chassisShapeCoefficients: { x: 1.7, y: 0.8, z: 0.55 },
    nWheels: 6,
    mass: 150,
    offsetBody: { x: 0, y: 1, z: -3 },
    suspensionStiffness: 50, //3.6,
    restLength: 0.45,
    brakeForce: 0.7,
    maxSpeed: 6,
    wheelPositions: [
      new THREE.Vector3(0.5, -0.22, 0.04),
      new THREE.Vector3(0.5, 0.22, 0.04),

      new THREE.Vector3(-0.08, 0.22, 0.04),
      new THREE.Vector3(-0.08, -0.22, 0.04),

      new THREE.Vector3(-0.33, 0.22, 0.04),
      new THREE.Vector3(-0.33, -0.22, 0.04),
    ],
  },
  TankTruck: {
    height: 0.9,
    chassisShapeCoefficients: { x: 1.7, y: 0.8, z: 0.55 },
    nWheels: 10,
    mass: 150,
    offsetBody: { x: 0, y: 1, z: -3 },
    suspensionStiffness: 50, //3.6,
    restLength: 0.45,
    brakeForce: 0.7,
    maxSpeed: 6,
    wheelPositions: [
      new THREE.Vector3(0.594, -0.22, 0.04),
      new THREE.Vector3(0.594, 0.22, 0.04),

      new THREE.Vector3(-0.352, 0.22, 0.04),
      new THREE.Vector3(-0.352, 0.11, 0.04),
      new THREE.Vector3(-0.352, -0.11, 0.04),
      new THREE.Vector3(-0.352, -0.22, 0.04),

      new THREE.Vector3(-0.638, 0.22, 0.04),
      new THREE.Vector3(-0.638, 0.11, 0.04),
      new THREE.Vector3(-0.638, -0.11, 0.04),
      new THREE.Vector3(-0.638, -0.22, 0.04),
    ],
  },
  TankTruck2: {
    height: 0.9,
    chassisShapeCoefficients: { x: 1.7, y: 0.8, z: 0.55 },
    nWheels: 6,
    mass: 150,
    offsetBody: { x: 0, y: 1, z: -3 },
    suspensionStiffness: 50, //3.6,
    restLength: 0.45,
    brakeForce: 0.7,
    maxSpeed: 7,
    wheelPositions: [
      new THREE.Vector3(0.42, -0.22, 0.04),
      new THREE.Vector3(0.42, 0.22, 0.04),

      new THREE.Vector3(-0.18, 0.22, 0.04),
      new THREE.Vector3(-0.18, -0.22, 0.04),

      new THREE.Vector3(-0.44, 0.22, 0.04),
      new THREE.Vector3(-0.44, -0.22, 0.04),
    ],
  },
};
