import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { Curve } from "./curve";
import VehicleMesh from "./vehicle";
import { getCurvesList } from "./managers/curveManager";
import { addVehicle } from "./managers/vehicleManager";
import { addWorld } from "./managers/worldManager";
import { addCamera, addControls } from "./managers/cameraManager";
import { addRenderCamera, addRenderScene } from "./managers/renderer";
import { addCurve } from "./managers/curveManager";
import matcapMaterial from "./materials/matcapMaterial";
import physicalMaterial from "./materials/physicalMaterial";
import { addDynamicMaterial } from "./managers/dynamicMaterialsManager";
import getState from "./state";
import { getVehicles, vehiclesOnPointerMove } from "./managers/vehicleManager";
import { parseScene } from "./managers/importantObjectsManager";
import {
  addPhysicalCurve,
  createPhysicalVehicle,
  initPhysicsArrays,
} from "./managers/workerManager";
import hologramMaterial from "./materials/hologramMaterial";
import { getTransparent, transformGeometry } from "./transformGeometry";
import { addBackgroundToScene, setBackgroundColor } from "./background";

const state = getState();
let curves, vehicles;

const ambientColor = new THREE.Color(0x5689dc);
const ambientIntensity = 0.2;

let controls, transformControl;

const pointer = new THREE.Vector2(0, 0);
let zoomTarget = -0.5;

window.addEventListener("pointermove", (event) => {
  pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
  pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
});

window.addEventListener("wheel", (event) => {
  zoomTarget += 0.001 * event.deltaY;
  zoomTarget = clamp(zoomTarget, -1, 2.0);
});

let directionalLight;
let world;
let ground, scene, camera;
let plane;

let transforming = false;

let assetsData;
let curveObject, curveObject2;

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
function restart() {
  vehicles.forEach((vehicle) => {
    vehicle.resetTime();
    vehicle.placeVehicleAtCurveStart();
  });
}

// function setBackgroundColor(color) {
//   const colorTo = new THREE.Color(color);
//   colorTo.r = Math.pow(colorTo.r, 1.0 / 2.2);
//   colorTo.g = Math.pow(colorTo.g, 1.0 / 2.2);
//   colorTo.b = Math.pow(colorTo.b, 1.0 / 2.2);
//
//   scene.background = colorTo;
// }

function getPlane() {
  return plane;
}
function setRegime(regime) {
  vehicles[0].setRegime(regime);
}

function samosvalSendImpulse() {
  vehicles[0].sendImpulse(new THREE.Vector3(0, 0.2, 0));
  vehicles[0].sendImpulse(new THREE.Vector3(1, 0.2, 0));
  vehicles[0].sendImpulse(new THREE.Vector3(0, 0.2, 1));
}

function samosvalContinue() {
  vehicles[0].headToNextStopPoint();
}

function getTransforming() {
  return transforming;
}

function getCamera() {
  return camera;
}

function getScene() {
  return scene;
}

function getZoomTarget() {
  return zoomTarget;
}

function getPointer() {
  return pointer;
}

function getData() {
  return { world, scene, assetsData };
}

function buildGeometries(node, animatedMeshesNames, callback) {
  //those are animated objects or objects which need to be separate from other geometry
  //for example position of such an object is used to build curves from truck to the object in question
  const objects = {
    "A_Factory_OilStorage_01-BASE__Blue": null,
    "A_Factory_OilStorage_02-BASE__Blue": null,
    "A_Factory_OilStorage_03-BASE__Blue": null,
    "A_Factory_OilStorage_04-BASE__Blue": null,
    "A_Factory_OilStorage_05-BASE__Blue": null,
    "A_Factory_OilStorage_06-BASE__Blue": null,
    "A_Factory_Storage_02-BASE__Blue": null,
    "A_Factory_Column_02-BASE__Blue": null,
    "A_Factory_Column_01-BASE__Blue": null,
    "A_Factory_Column_03-BASE__Blue": null,
    A_Factory_GasStorage_01: null,
    A_Factory_GasStorage_02: null,

    "A_Factory_02_GasSphere_01-BASE__Blue": null,
    "A_Factory_02_GasSphere_02-BASE__Blue": null,
    "A_Factory_02_GasSphere_03-BASE__Blue": null,
    "A_Factory_02_GasSphere_04-BASE__Blue": null,
    "A_Factory_02_Column_01-BASE__Blue": null,
    "A_Factory_02_Column_02-BASE__Blue": null,
    "A_Factory_02_Column_03-BASE__Blue": null,
    "A_Factory_02_Column_04-BASE__Blue": null,

    "A_Septic_Pool_01-BASE__White": null,
    "A_Septic_Pool_02-BASE__White": null,
    "A_Septic_Pool_03-BASE__White": null,

    "A_Septic_Tank_02-BASE__Blue": null,

    C_Transport_01: null,
    C_Transport_02: null,
    Bricks: null,
    Bricks2: null,
    B_Region_FB_02: null,
  };

  const animatedMeshes = [];

  animatedMeshesNames.forEach((name) => {
    objects[name] = null;
  });

  node.updateMatrixWorld(true);
  node.traverse((m) => {
    m.isAnimated = false;
  });

  node.traverse((m) => {
    if (Object.keys(objects).indexOf(m.name) != -1) {
      if (!m.isAnimated) {
        m.updateMatrixWorld(true);
        const between = new THREE.Object3D();
        between.applyMatrix4(m.parent.matrixWorld);
        //between.add(m);
        //m.parent.remove(m);
        animatedMeshes.push({ between: between, m: m });
        m.isAnimated = true;
      }
      if (m.children.length > 0)
        m.traverse((o) => {
          o.isAnimated = true;
        });
      return;
    }
  });

  // const worker = new Worker("transformWorker.js");
  // worker.postMessage(["transform", { node: node.toJSON() }]);
  // worker.onmessage = (e) => {
  //   const geometries = e.data[1];
  //   callback(animatedMeshes, geometries);
  // };
  const geometries = transformGeometry(node);

  callback(animatedMeshes, geometries);
  // return { animatedMeshes, geometries };
}

function buildScene(renderer, assets, city, sizes) {
  assetsData = assets;
  curves = getCurvesList();
  vehicles = getVehicles();

  scene = new THREE.Scene();
  // scene.background = new THREE.Color(state.backgroundColor);

  city.scale.set(0.6, 0.6, 0.6);
  //assets.City.visible = false;
  //if (assets.City) scene.add(assets.City);
  addRenderScene(scene);

  //directionalLight = new THREE.DirectionalLight(0x73d4ff, 1.0, 0);
  directionalLight = new THREE.DirectionalLight(0xffffff, 1.0, 0);
  scene.add(directionalLight);
  directionalLight.shadow.mapSize.width = 4096; // default
  directionalLight.shadow.mapSize.height = 4096; // default
  directionalLight.shadow.camera.near = 0.5; // default
  directionalLight.shadow.camera.far = 500; // default
  directionalLight.shadow.camera.left = -50;
  directionalLight.shadow.camera.right = 50;
  directionalLight.shadow.camera.top = 50;
  directionalLight.shadow.camera.bottom = -50;

  directionalLight.shadow.bias = -0.0005;

  directionalLight.shadow.camera.updateProjectionMatrix();
  directionalLight.castShadow = true;

  directionalLight.position.set(0, 80, 0);
  directionalLight.target.position.set(-30, 30, 0);
  scene.add(directionalLight.target);

  //const ambientLight = new THREE.AmbientLight(0x73d4ff, 0.0); //0.35
  const ambientLight = new THREE.AmbientLight(ambientColor, ambientIntensity);
  scene.add(ambientLight);

  //scene.matrixWorldAutoUpdate = false;
  const cubeMatCap = hologramMaterial();
  const accentMatCap = matcapMaterial(
    assets[state.accentMatCap],
    null,
    ambientColor,
    ambientIntensity,
    false,
    state.backgroundColor,
  );
  const baseMatCap = matcapMaterial(
    assets[state.baseMatCap],
    null,
    ambientColor,
    ambientIntensity,
    false,
    state.backgroundColor,
  );
  const transparentAccentMatCap = matcapMaterial(
    assets[state.accentMatCap],
    null,
    ambientColor,
    ambientIntensity,
    false,
    state.backgroundColor,
  );
  const transparentBaseMatCap = matcapMaterial(
    assets[state.baseMatCap],
    null,
    ambientColor,
    ambientIntensity,
    false,
    state.backgroundColor,
  );
  const roadMatCap = matcapMaterial(
    assets[state.roadMatCap],
    null,
    ambientColor,
    ambientIntensity,
    false,
    state.backgroundColor,
  );
  const planeMatCap = matcapMaterial(
    assets[state.planeMatCap],
    null,
    ambientColor,
    ambientIntensity,
    false,
    state.backgroundColor,
  );
  const waterMatCap = matcapMaterial(
    assets[state.waterMatCap],
    null,
    ambientColor,
    ambientIntensity,
    false,
    state.backgroundColor,
  );
  const treesMatCap = matcapMaterial(
    assets[state.treesMatCap],
    null,
    ambientColor,
    ambientIntensity,
    false,
    state.backgroundColor,
  );

  waterMatCap.defines["WATER"] = true;
  waterMatCap.uniforms.flowMap.value = assets.flowMap;
  waterMatCap.uniforms.normalMap1.value = assets.waterNormal1;
  waterMatCap.uniforms.normalMap2.value = assets.waterNormal2;
  waterMatCap.transparent = true;
  waterMatCap.blending = THREE.AdditiveBlending;

  // planeMatCap.defines["GROUND"] = true;
  // roadMatCap.defines["GROUND"] = true;

  //accentMatCap.side = THREE.DoubleSide;
  addDynamicMaterial(accentMatCap);
  addDynamicMaterial(baseMatCap);
  addDynamicMaterial(roadMatCap);
  addDynamicMaterial(planeMatCap);
  addDynamicMaterial(waterMatCap);
  addDynamicMaterial(treesMatCap);
  addDynamicMaterial(transparentAccentMatCap);
  addDynamicMaterial(transparentBaseMatCap);

  const assignMaterial = (m) => {
    const transparent = getTransparent();
    const name = m.material.name.slice(0, 12);

    if (name === "BASE__Blue" && transparent.indexOf(m.name) == -1) {
      m.material = accentMatCap;
    } else if (name === "BASE__Blue" && transparent.indexOf(m.name) != -1) {
      m.material = transparentAccentMatCap;
    } else if (transparent.indexOf(m.name) != -1) {
      m.material = transparentBaseMatCap;
    } else {
      m.material = baseMatCap;
    }

    if (
      (m.name.slice(0, 7) === "C_Water" || m.name.slice(0, 5) === "Water") &&
      name === "BASE__Blue"
    ) {
      m.material = waterMatCap;
    }
    if (m.name === "C_Communications_Roads-BASE__White_1")
      m.material = roadMatCap;

    if (m.name === "Ground_Cube") m.material = planeMatCap;
  };

  let groundQuaternion = new THREE.Quaternion();
  groundQuaternion.setFromAxisAngle(
    new THREE.Vector3(1.0, 0.0, 0.0),
    -Math.PI / 2,
  );

  ground = new THREE.Mesh(
    new THREE.PlaneGeometry(),
    physicalMaterial(), //new THREE.MeshPhysicalMaterial()
  );
  ground.receiveShadow = true;
  const startPosition = new THREE.Vector3();

  for (let i = 0; i < assets.curves.length; i++) {
    const curve = assets.curves[i];
    const curveObject = new Curve(curve, scene);

    curveObject.addCylinderMesh(
      scene,
      assets[state.PathBeforeMatCap],
      //assets.orangeMatCap
    );
    curveObject.setCylinderVisibility(false);
    curveObject.makePath();
    addCurve(curveObject);
    addPhysicalCurve(curveObject);
  }

  initPhysicsArrays(state.vehicles);
  for (let i = 0; i < state.vehicles.length; i++) {
    const vehicleDescription = state.vehicles[i];
    const vehicle = new VehicleMesh(
      vehicleDescription.type,
      assets,
      matcapMaterial(
        assets[state.carsMatCap],
        null,
        ambientColor,
        ambientIntensity,
        false,
      ),
      scene,
    );
    vehicle.setPath(
      curves["curve" + vehicleDescription.path],
      vehicleDescription.initialPositionIndex,
    );
    addVehicle(vehicle);

    createPhysicalVehicle(vehicle, vehicleDescription);
    //if (idx == 0) startPosition.copy(vehicleObject.chassisBody.position);
  }

  camera = new THREE.PerspectiveCamera(
    60,
    sizes.width / sizes.height,
    0.3, //0.1,
    400,
  );
  //camera.position.y = 1.0;
  //camera.position.z = 2;
  //camera.lookAt(new THREE.Vector3(0.0, 0.0, 0.0));
  //

  addCamera(camera);
  addRenderCamera(camera);
  addBackgroundToScene(scene);
  setBackgroundColor(state.backgroundColor);

  const waterPosition = new THREE.Vector3();

  city.traverse((n) => {
    if (n.name === "Water-BASE__Blue") n.getWorldPosition(waterPosition);
  });

  // controls = new OrbitControls(camera, renderer.domElement);
  // addControls(controls);

  camera.position.set(100, 40, 0);
  camera.rotation.set(-Math.PI / 2, (0.8 * Math.PI) / 2, Math.PI / 2);
  //document.onkeydown = vehicles[0].handler;
  //document.onkeyup = vehicles[0].handler;

  const cubeTarget = new THREE.WebGLCubeRenderTarget(256, {
    generateMipmaps: true,
    minFilter: THREE.LinearFilter,
    magFilter: THREE.LinearFilter,
  });
  const cubeCamera = new THREE.CubeCamera(1, 100000, cubeTarget);
  cubeCamera.position.copy(waterPosition);

  scene.add(cubeCamera);
  setTimeout(() => {
    cubeCamera.update(renderer, scene);
    waterMatCap.uniforms.reflectionMap.value = cubeTarget.texture;
  }, 2000);

  buildGeometries(
    city,
    Object.keys(assets.animatedMeshes),
    (animatedMeshes, geometries) => {
      const water = new THREE.Mesh(geometries.water, waterMatCap);
      plane = new THREE.Mesh(geometries.plane, planeMatCap);
      const road = new THREE.Mesh(geometries.road, roadMatCap);
      const tree = new THREE.Mesh(geometries.tree, treesMatCap);
      const base = new THREE.Mesh(geometries.base, baseMatCap);
      const accent = new THREE.Mesh(geometries.accent, accentMatCap);
      const transparentBase = new THREE.Mesh(
        geometries.transparentBase,
        transparentBaseMatCap,
      );
      const transparentAccent = new THREE.Mesh(
        geometries.transparentAccent,
        transparentAccentMatCap,
      );
      transparentAccentMatCap.transparent = true;
      transparentAccentMatCap.defines["TRANSPARENT"] = true;
      transparentAccentMatCap.side = THREE.DoubleSide;
      transparentBaseMatCap.transparent = true;
      transparentBaseMatCap.defines["TRANSPARENT"] = true;
      transparentBaseMatCap.side = THREE.DoubleSide;
      // transparentBase.material.compile();
      // transparentAccent.material.compule();

      water.renderOrder = 10;
      scene.add(water);
      scene.add(plane);
      scene.add(road);
      scene.add(tree);
      scene.add(base);
      scene.add(accent);
      scene.add(transparentAccent);
      scene.add(transparentBase);

      animatedMeshes.forEach((o) => {
        let { between, m } = o;
        between.add(m);
        scene.add(between);
        if (m.isMesh) assignMaterial(m);
        m.traverse((t) => {
          if (t.isMesh) assignMaterial(t);
        });
      });

      parseScene(scene);
    },
  );
}

function destroyScene() {
  directionalLight = null;
  world = null;
  ground = null;
  scene = null;
  camera = null;
  plane = null;

  transforming = false;

  assetsData = null;
  curveObject = null;
  curveObject2 = null;

  while (curves.length > 0) {
    curves.pop();
  }
  while (vehicles.length > 0) {
    vehicles.pop();
  }
}

export {
  getData,
  getPointer,
  getCamera,
  getTransforming,
  getZoomTarget,
  buildScene,
  curveObject,
  getScene,
  getPlane,
  restart,
  setRegime,
  samosvalSendImpulse,
  samosvalContinue,
  destroyScene,
};
