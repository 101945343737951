import * as THREE from "three";
import { dynamicMaterialInteraction } from "./dynamicMaterialsManager";
import { curveImpulseInteraction } from "./impulseManager";
import { vehicleContinueMovementInteraction } from "./interactions";
import { cameraInteraction } from "./cameraManager";
import { getScene } from "../scene";
import {
  getObjectWorldPosition,
  trainWaitInteraction,
  bricksInteraction,
} from "./importantObjectsManager";
import { textTransitionInteraction } from "./textManager";
const interactionSequences = [];

function addInteractionSequence(vehicle, interactionSequenceDescription) {
  const interactionSequence = {
    sequence: [],
    running: false,
    finished: false,
    currentRunningIndex: 0,
  };

  const scene = getScene();
  interactionSequenceDescription.sequence.forEach((interactionDescription) => {
    let interaction;

    switch (interactionDescription.type) {
      case "materialImpulse":
        const center = new THREE.Vector3();
        if (
          typeof interactionDescription.center == "string" &&
          interactionDescription.center === "vehicle"
        )
          center.copy(vehicle.body.position);
        else if (
          typeof interactionDescription.center == "string" &&
          interactionDescription.center !== "vehicle"
        ) {
          getObjectWorldPosition(interactionDescription.center, center);
        } else
          center.set(
            interactionDescription.center.x,
            interactionDescription.center.y,
            interactionDescription.center.z,
          );

        interaction = new dynamicMaterialInteraction(
          { position: center },
          "default",
        );
        break;

      case "tankAnimation":
        interaction = new dynamicMaterialInteraction(
          { up: interactionDescription.up, material: vehicle.tankMaterial },
          "tank",
        );
        break;
      case "continueMovement":
        interaction = new vehicleContinueMovementInteraction(vehicle);
        break;

      case "textTransition":
        interaction = new textTransitionInteraction({
          textIndex: interactionDescription.textIndex,
          reverse: interactionDescription.reverse,
        });

        break;

      case "cameraTransition":
        interaction = new cameraInteraction(
          {
            vehicle: vehicle ? vehicle.num : null,
            next: interactionDescription.next,
          },
          interactionDescription.cameraAnimationType,
        );
        break;

      case "bricksInteraction":
        interaction = new bricksInteraction(vehicle);
        break;

      case "waitTrain":
        interaction = new trainWaitInteraction(vehicle);
        break;

      case "curveImpulse":
      default:
        const position1 = new THREE.Vector3();
        const position2 = new THREE.Vector3();

        if (
          typeof interactionDescription.position1 == "string" &&
          interactionDescription.position1 === "vehicle"
        )
          position1.copy(vehicle.body.position);
        else if (
          typeof interactionDescription.position1 == "string" &&
          interactionDescription.position1 !== "vehicle"
        ) {
          getObjectWorldPosition(interactionDescription.position1, position1);
        } else
          position1.set(
            interactionDescription.position1.x,
            interactionDescription.position1.y,
            interactionDescription.position1.z,
          );

        if (
          typeof interactionDescription.position2 == "string" &&
          interactionDescription.position2 === "vehicle"
        )
          position2.copy(vehicle.body.position);
        else if (
          typeof interactionDescription.position2 == "string" &&
          interactionDescription.position2 !== "vehicle"
        ) {
          getObjectWorldPosition(interactionDescription.position2, position2);
        } else
          position2.set(
            interactionDescription.position2.x,
            interactionDescription.position2.y,
            interactionDescription.position2.z,
          );

        if (interactionDescription.offset1)
          position1.add(interactionDescription.offset1);
        if (interactionDescription.offset2)
          position2.add(interactionDescription.offset2);

        interaction = new curveImpulseInteraction(
          position1,
          position2,
          scene,
          interactionDescription.reverse,
        );
    }
    interaction.setNextInteractionStart(
      interactionDescription.nextInteractionStart,
    );
    interactionSequence.sequence.push(interaction);
  });
  interactionSequences.push(interactionSequence);

  return interactionSequence;
}

function handleInteractionSequence(interactionSequence) {
  const currentInteraction =
    interactionSequence.sequence[interactionSequence.currentRunningIndex];

  if (!currentInteraction.checkIfStarted()) currentInteraction.start();
  const finished = currentInteraction.checkIfFinished();
  const nextStepNow = currentInteraction.toStartNextInteraction();

  if (
    (finished || nextStepNow) &&
    interactionSequence.currentRunningIndex <
      interactionSequence.sequence.length - 1
  )
    interactionSequence.currentRunningIndex++;
  else if (
    finished &&
    interactionSequence.currentRunningIndex ===
      interactionSequence.sequence.length - 1
  )
    interactionSequence.finished = true;
}

function updateInteractions() {
  let toKill = true;
  let toKillN = -1;
  for (let i = 0; i < interactionSequences.length; i++) {
    const interactionSequence = interactionSequences[i];
    if (interactionSequence.finished && toKill) toKillN = i;
    else toKill = false;

    if (interactionSequence.finished) continue;
    handleInteractionSequence(interactionSequence);
  }

  for (let i = 0; i < toKillN + 1; i++) interactionSequences.shift();
}

function cleanInteractions() {
  while (interactionSequences.length > 0) {
    interactionSequences.pop();
  }
}

export { cleanInteractions, addInteractionSequence, updateInteractions };
