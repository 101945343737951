import * as THREE from "three";
import vertex from "./matcap.glslv";
import fragment from "./matcap.glslf";
import { getAssets } from "../loader";
import { LayeredMaterial } from "../layers/LayeredMaterial.mjs";
import { createStandardLayeredShader } from "../layers/layers";
import getState from "../state";

const state = getState();

const matcapMaterial = (
  matCap,
  normalMap,
  ambientColor,
  ambientIntensity,
  isTank,
  backgroundColor,
) => {
  const assets = getAssets();
  return new LayeredMaterial(
    {
      vertexShader: vertex,
      fragmentShader: fragment,
      // side: THREE.DoubleSide,
      uniforms: {
        matCap: { value: matCap ? matCap : assets.blackMatCap },
        normalMap: { value: normalMap },
        patternTexture: { value: assets.patternTexture },
        toneMappingExposure: { value: 1.0 },

        bigPulseColor: { value: new THREE.Vector3(0, 0, 0) },
        bigPulseIntensity: { value: 1.0 },

        smallPulseColor: { value: new THREE.Vector3(0, 0, 0) },
        smallPulseIntensity: { value: 1.0 },
        materialImpulseCenter0: { value: new THREE.Vector3(0, 0, 0) },
        materialImpulseCenter1: { value: new THREE.Vector3(0, 0, 0) },
        materialImpulseCenter2: { value: new THREE.Vector3(0, 0, 0) },
        materialImpulseCenter3: { value: new THREE.Vector3(0, 0, 0) },
        sceneImpulseCenter: { value: new THREE.Vector3(0, 0, 0) },
        sceneImpulseTime: { value: 0 },
        circularT: { value: 0 },
        circularCenter: { value: new THREE.Vector3(-1000, 0, 0) },
        t0: { value: 0 },
        t1: { value: 0 },
        t2: { value: 0 },
        t3: { value: 0 },
        tankT: { value: 0 },
        ambientColor: {
          value: ambientColor ? ambientColor : new THREE.Color(0x0),
        },
        ambientIntensity: { value: ambientIntensity ? ambientIntensity : 0 },
        backgroundColor: { value: new THREE.Color(backgroundColor) },
        opacity: { value: 1.0 },
        flowMap: { value: null },
        normalMap1: { value: null },
        normalMap2: { value: null },
        reflectionMap: { value: null },
        waterT: { value: 0.0 },
        resolution: {
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
        carIntersection: { value: 1.3 },
        carIntersectionDelayed: { value: 3.3 },
        cameraIntersection: { value: 1000 },
        cameraDown: { value: 0 },
	      leftdir: { value: new THREE.Vector3(0, 1, 0) }
      },
      defines: {
        CAR: false,
        TRANSPARENT: false,
        NORMAL_MAP: normalMap ? true : false,
        TANK: isTank ? true : false,
        BRICKS: false,
        TRAIN: false,
        WATER: false,
        NORMAL_TARGET: false,
        FINAL_OUTPUT: true,
        TONEMAP: false,
        TONEMAP_SIMPLE: false,
        TONEMAP_REINHARD: false,
        TONEMAP_REINHARD_LUMA: false,
        TONEMAP_REINHARD_WHITE: false,
        TONEMAP_FILMIC: false,
        TONEMAP_FILMIC: false,
        TONEMAP_PHOTOGRAPHIC: false,
        TONEMAP_UNCHARTED: true,
        LAYERS: false,
        FULLY_DOWN: false,
        GROUND: false,
      },
      transparent: false,
    },
    createStandardLayeredShader(fragment),
    state,
  );
};

export default matcapMaterial;
