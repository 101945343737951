import * as THREE from "three";
import { easings } from "./easing";
import { ParametricGeometry } from "three/examples/jsm/geometries/ParametricGeometry";
import { buildGUI, updateGUI } from "./gui";
import Stats from "three/examples/jsm/libs/stats.module";
import { buildScene, getCamera, getScene, getPlane, getPointer } from "./scene";
import { updateImpulses, sendImpulse } from "./managers/impulseManager";
import { getVehicles, updateVehicles } from "./managers/vehicleManager";
import { updateWorld } from "./managers/worldManager";
import { updateCamera } from "./managers/cameraManager";
import { updateBackground } from "./background";
import { addRenderer, render, updateRenderingState } from "./managers/renderer";
import { startSceneImpulse } from "./managers/dynamicMaterialsManager";
import {
  updateDynamicMaterials,
  startAnimation,
} from "./managers/dynamicMaterialsManager";
import {
  updateInteractions,
  addInteractionSequence,
} from "./managers/interactionManager";
import getState, { setRenderer, getChoice } from "./state";
import {
  addText,
  textTransitionInteraction,
  updateTextTransitions,
  updateTextsPositiioning,
} from "./managers/textManager";
import {
  buildLoadScene,
  renderLoadScene,
  getSize,
  getLoadCamera,
} from "./loadPage";
import { getAssets, getComplete, loadAssets } from "./loader";
import { setUniforms } from "./composer";

import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import {
  getObjectPosition,
  getObjectWorldPosition,
} from "./managers/importantObjectsManager";
import { initializeWorker, sendUpdateRequest } from "./managers/workerManager";

const trainPosition1 = new THREE.Vector3();
const trainPosition2 = new THREE.Vector3();

const state = getState();
let lastInteractionSequence = { finished: true };
let firstRender = true;
let initialized = false;
let mixer;

let renderer;
let sceneBuilder;
let loaderTransitionTime = 0;
let loaderTransitionDeltaTime = 0.008;

let lastTime = 0;

let frameCounter = 0;
let startTime = 0;
let renderingChosen = false;
let startFPS = 0;
let firstCheck = false;
let secondCheck = false;
// Splitting();
// addText(1);
// addText(2);
// addText(3);
// addText(4);

window.ParametricGeometry = ParametricGeometry;
window.THREE = THREE;
renderer = new THREE.WebGLRenderer({
  antialias: state.defaultFrameBufferAntialiasing,
  precision: "highp", //"lowp",
});
//renderer.shadowMap.enabled = true;
//renderer.shadowMap.type = THREE.PCFShadowMap; //THREE.PCFSoftShadowMap;
renderer.setSize(window.innerWidth, window.innerHeight);
state.width = window.innerWidth;
state.height = window.innerHeight;
renderer.setPixelRatio(2);
renderer.sortObjects = false;

//renderer.toneMapping = THREE.ACESFilmicToneMapping;
//renderer.outputColorSpace = THREE.SRGBColorSpace;
// renderer.outputColorSpace = THREE.LinearSRGBColorSpace;

document.body.appendChild(renderer.domElement);

const raycaster = new THREE.Raycaster();

window.addEventListener("resize", () => {
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setPixelRatio(state.pixelRatio);

  state.width = window.innerWidth;
  state.height = window.innerHeight;

  const loadCamera = getLoadCamera();
  if (loadCamera) {
    loadCamera.aspect = window.innerWidth / window.innerHeight;
    loadCamera.updateProjectionMatrix();
  }
  const camera = getCamera();
  if (camera) {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    updateRenderingState();
  }
});
// const stats = new Stats();
// document.body.appendChild(stats.dom);

buildLoadScene(renderer);
initializeWorker();
animate();
loadAssets().then(() => {
  init();
});

function firstCheckProcedure(startFrame, startTime, frameCounter) {
  let timeOverCounter =
    (performance.now() - startTime) / (frameCounter - startFrame);
  timeOverCounter /= 1000;
  startFPS = 1 / timeOverCounter;
  const choice = getChoice();

  let final = false;
  if (startFPS < 40) {
    setRenderer("direct");

    if (choice == "mobile") {
      state.pixelRatio = 1.0;
      final = true;
      console.log("First check FPS is ", startFPS);
    } else {
      state.pixelRatio = 1.0;
      console.log("First check FPS is ", startFPS, ". Needs a second check.");
      final = false;
    }

    renderer.setPixelRatio(state.pixelRatio);
    updateRenderingState();
  } else {
    final = true;
    console.log(
      "First check FPS is ",
      startFPS,
      ". Good Enough with high settings!",
    );
  }
  updateGUI();
  return final;
}

function secondCheckProcedure(startFrame, startTime, frameCounter) {
  let timeOverCounter =
    (performance.now() - startTime) / (frameCounter - startFrame);
  timeOverCounter /= 1000;
  startFPS = 1 / timeOverCounter;

  if (startFPS < 30) {
    setRenderer("direct");
    state.pixelRatio = 0.75;
    state.geometryRenderMSAASamples = 0;
    renderer.setPixelRatio(state.pixelRatio);
    updateRenderingState();
    console.log("Second check FPS is ", startFPS, ". Setting lowest settings.");
  } else {
    console.log("Second check FPS is ", startFPS, ". Setting middle settings.");
  }

  updateGUI();
}

function intersect() {
  // const scene = getScene();
  const camera = getCamera();
  const plane = getPlane();
  const pointer = getPointer();

  raycaster.setFromCamera(pointer, camera);
  const intersects = raycaster.intersectObjects([plane]);
  if (intersects.length > 0) {
    const iposition = intersects[0].point;
    startSceneImpulse(iposition.x, 0, iposition.z);
  } else if (!state.cameraUp && !state.startScreenCamera) {
    const focusVehicle = getVehicles()[state.focusVehicle];
    const position = focusVehicle.body.position;
    startSceneImpulse(position.x, position.y, position.z);
  } else {
    startSceneImpulse(0, 0, 0);
  }
}

// window.addEventListener("mousemove", (e) => {
//   onPointerMove(e);
// });
window.addEventListener("click", () => {
  if (initialized && state.toSendImpulse) intersect();
});

function init() {
  const assets = getAssets();

  const city = assets.City.clone();
  mixer = new THREE.AnimationMixer(city);
  const a = mixer.clipAction(assets.animations[0]);
  a.setLoop(THREE.LoopRepeat);
  a.play();

  window.addEventListener("keydown", (e) => {
    if (e.keyCode === 78) startAnimation(new THREE.Vector3(0, 0, 0));
    if (e.keyCode === 39) {
      if (!lastInteractionSequence.finished || firstRender) return;

      if (state.startScreenCamera) {
        state.previousFocusVehicle = state.focusVehicle;
        state.focusVehicle++;
        if (state.focusVehicle < 0)
          state.focusVehicle += state.numberOfVehicles;
        state.focusVehicle = state.focusVehicle % state.numberOfVehicles;

        const vehicles = getVehicles();
        vehicles[state.previousFocusVehicle].path.setCylinderVisibility(false);
        vehicles[state.focusVehicle].path.setCylinderVisibility(true);
        return;
      }
      const previousFocusVehicle = state.focusVehicle;
      let focusVehicle = state.focusVehicle + 1;
      focusVehicle = focusVehicle % state.numberOfVehicles; // state.vehicles.length;

      const vehicles = getVehicles();
      vehicles[previousFocusVehicle].path.setCylinderVisibility(false);
      vehicles[focusVehicle].path.setCylinderVisibility(true);

      // const fromTextIndex = state.vehicles[previousFocusVehicle].textIndex
      //   ? state.vehicles[previousFocusVehicle].textIndex
      //   : -1;
      // const toTextIndex = state.vehicles[focusVehicle].textIndex
      //   ? state.vehicles[focusVehicle].textIndex
      //   : -1;

      const sequence = [
        {
          type: "cameraTransition",
          cameraAnimationType: "upMoveDown",
          next: focusVehicle,
          nextInteractionStart: "wait",
        },
      ];

      // if (fromTextIndex != -1)
      //   sequence.unshift({
      //     type: "textTransition",
      //     nextInteractionStart: "wait",
      //     textIndex: fromTextIndex,
      //     reverse: true,
      //   });
      //
      // if (toTextIndex != -1)
      //   sequence.push({
      //     type: "textTransition",
      //     nextInteractionStart: "wait",
      //     textIndex: toTextIndex,
      //     reverse: false,
      //   });

      lastInteractionSequence = addInteractionSequence(null, { sequence });
    }
    if (e.keyCode === 37) {
      if (!lastInteractionSequence.finished || firstRender) return;

      if (state.startScreenCamera) {
        state.previousFocusVehicle = state.focusVehicle;
        state.focusVehicle--;
        if (state.focusVehicle < 0)
          state.focusVehicle += state.numberOfVehicles;
        state.focusVehicle = state.focusVehicle % state.numberOfVehicles;

        const vehicles = getVehicles();
        vehicles[state.previousFocusVehicle].path.setCylinderVisibility(false);
        vehicles[state.focusVehicle].path.setCylinderVisibility(true);
        return;
      }

      const previousFocusVehicle = state.focusVehicle;
      let focusVehicle = state.focusVehicle - 1;
      if (focusVehicle < 0) focusVehicle += state.numberOfVehicles; //state.vehicles.length;
      focusVehicle = focusVehicle % state.numberOfVehicles; //state.vehicles.length;

      const vehicles = getVehicles();
      vehicles[previousFocusVehicle].path.setCylinderVisibility(false);
      vehicles[focusVehicle].path.setCylinderVisibility(true);

      const fromTextIndex = state.vehicles[previousFocusVehicle].textIndex
        ? state.vehicles[previousFocusVehicle].textIndex
        : -1;
      const toTextIndex = state.vehicles[focusVehicle].textIndex
        ? state.vehicles[focusVehicle].textIndex
        : -1;

      const sequence = [
        {
          type: "cameraTransition",
          cameraAnimationType: "upMoveDown",
          next: focusVehicle,
          nextInteractionStart: "wait",
        },
      ];

      // if (fromTextIndex != -1)
      //   sequence.unshift({
      //     type: "textTransition",
      //     nextInteractionStart: "wait",
      //     textIndex: fromTextIndex,
      //     reverse: true,
      //   });
      //
      // if (toTextIndex != -1)
      //   sequence.push({
      //     type: "textTransition",
      //     nextInteractionStart: "wait",
      //     textIndex: toTextIndex,
      //     reverse: false,
      //   });

      lastInteractionSequence = addInteractionSequence(null, { sequence });
    }
    if (e.keyCode === 40) {
      if (firstRender) return;
      if (!state.cameraUp && !state.startScreenCamera) {
        state.cameraUp = true;
        // updateTextsPositiioning();
      } else if (state.cameraUp && !state.startScreenCamera) {
        state.startScreenCamera = true;
        state.cameraUp = false;
      }
    }
    if (e.keyCode === 38) {
      if (firstRender) return;
      if (state.startScreenCamera) {
        state.cameraUp = true;
        state.startScreenCamera = false;
        //state.focusVehicle = 0;
        const vehicles = getVehicles();
        vehicles[state.focusVehicle].path.setCylinderVisibility(true);
        // const toTextIndex = state.vehicles[state.focusVehicle].textIndex
        //   ? state.vehicles[state.focusVehicle].textIndex
        //   : -1;
        // lastInteractionSequence = addInteractionSequence(null, {
        //   sequence: [
        //     {
        //       type: "textTransition",
        //       nextInteractionStart: "wait",
        //       textIndex: 0,
        //       reverse: true,
        //     },
        //     {
        //       type: "textTransition",
        //       nextInteractionStart: "wait",
        //       textIndex: toTextIndex,
        //       reverse: false,
        //     },
        //   ],
        // });
      } else state.cameraUp = false;
      // updateTextsPositiioning();
    }
  });

  addRenderer(renderer);

  buildScene(renderer, assets, city, {
    width: window.innerWidth,
    height: window.innerHeight,
  });

  buildGUI();

  updateRenderingState();
  const camera = getCamera();
  setUniforms(camera);
  initialized = true;
}

const animationSpeed = 0.02; //0.012

let cubeRendered = false;

function animate() {
  requestAnimationFrame(animate);

  let dt = performance.now() - lastTime;
  dt /= 1000;

  const complete = getSize();
  //const scene = getScene();
  // stats.begin();
  if (complete < 1 || !initialized) {
    renderLoadScene(renderer);
  } else if (complete >= 1 && loaderTransitionTime < 1 && initialized) {
    const easedTime = easings["cubicInOut"](loaderTransitionTime);
    if (renderingChosen) {
      renderer.setScissorTest(true);
      renderer.setScissor(
        0,
        0,
        window.innerWidth * easedTime,
        window.innerHeight,
      );
    }

    mixer.update(animationSpeed);
    updateDynamicMaterials();
    updateVehicles();
    updateImpulses();
    updateTextTransitions();
    updateInteractions();
    updateCamera();
    updateBackground();
    sendUpdateRequest(1000.0 / 120);
    render();
    // updateWorld();
    if (renderingChosen) {
      renderer.setScissor(
        window.innerWidth * easedTime,
        0,
        window.innerWidth * (1 - easedTime) + 1,
        window.innerHeight,
      );
    }

    renderLoadScene(renderer);

    frameCounter++;
    let startFrame1 = 20;
    let finishFrame1 = 60;

    let startFrame2 = 61;
    let finishFrame2 = 121;

    if (
      frameCounter > startFrame1 &&
      !renderingChosen &&
      frameCounter == finishFrame1
    ) {
      firstCheck = firstCheckProcedure(startFrame1, startTime, frameCounter);
      if (firstCheck) renderingChosen = true;
      // console.log(1 / timeOverCounter, 1 / dt);
    }
    if (
      !firstCheck &&
      frameCounter > startFrame2 &&
      frameCounter == finishFrame2 &&
      !renderingChosen
    ) {
      secondCheckProcedure(startFrame2, startTime, frameCounter);
      renderingChosen = true;
    }

    if (frameCounter == startFrame1) {
      startTime = performance.now();
    }

    if (frameCounter == startFrame2) {
      startTime = performance.now();
    }

    if (renderingChosen) {
      loaderTransitionTime += loaderTransitionDeltaTime;
    }
  } else {
    if (firstRender) {
      lastInteractionSequence = addInteractionSequence(null, {
        sequence: [
          {
            type: "textTransition",
            nextInteractionStart: "wait",
            textIndex: 0,
            reverse: false,
          },
        ],
      });
      renderer.setScissorTest(false);
    }

    mixer.update(animationSpeed);
    updateDynamicMaterials();
    updateVehicles();
    updateImpulses();
    updateTextTransitions();
    updateInteractions();
    updateCamera();
    updateBackground();
    sendUpdateRequest(dt);
    render();
    // updateWorld();
    // if (!firstRender) {
    //   frameCounter++;
    //   let timeOverCounter = (performance.now() - startTime) / frameCounter;
    //   timeOverCounter /= 1000;
    //   console.log(1 / timeOverCounter, 1 / dt);
    // } else {
    //   startTime = performance.now();
    // }
    firstRender = false;
  }
  lastTime = performance.now();
  // stats.end();
}
