import Splitting from "splitting";
import getState from "./state";

const state = getState();

const defaultEasing = (t) => t;

const callbacks = [
  (char, index, time) => {
    //char.style.rotate = "1 0 0 " + 90 * (1 - time) + "deg";
    const startTimeForIndex = 0.05 * index;
    const s = time; //Math.max(0, time - startTimeForIndex) / (1 - startTimeForIndex);
    char.style.transform = "rotate3d(1, 0, 0, " + 90 * (1 - s) + "deg)";
    char.style.rotate = "0 0 1 " + char.rotation * (1 - s) + "deg";

    //char.style.top = state.cameraUp ? "40%" : "20%";
  },
];

class AnimatedText {
  constructor(index) {
    this.index = index;
    const queryText = "[text" + index + "]";
    const queryHeader = "[header" + index + "]";

    this.elementText = document.querySelector(queryText);
    //this.elementText.style.opacity = 0;
    this.elementHeader = document.querySelector(queryHeader);

    const results = Splitting({
      target: ".text2",
      by: "rows",
      //matching: ".col",
    });

    this.charsHeader = this.elementHeader
      ? this.elementHeader.querySelectorAll(".char")
      : [];
    this.charsText = this.elementText
      ? this.elementText.querySelectorAll(".char")
      : [];

    this.chars = [...this.charsHeader, ...this.charsText];
    this.chars.forEach((char, index) => {
      char.rotation = 60 * Math.random();
      char.style.rotate = "0 0 1 " + char.rotation + "deg";
    });
    this.t = 0;
  }

  setAnimation(perCharUpdateCallbackIndex, easing) {
    this.perCharUpdateCallback = callbacks[perCharUpdateCallbackIndex];
    this.easing = easing ? easing : defaultEasing;
  }

  updatePositioning() {
    this.elementHeader.style.top = state.cameraUp ? "40%" : "20%";
    if (this.elementText) this.elementText.style.opacity = 0; //state.cameraUp ? 0 : this.t;
  }

  setTime(time) {
    this.t = time;
    this.elementHeader.style.top = state.cameraUp ? "40%" : "20%";
    if (this.elementText) this.elementText.style.opacity = 0; //state.cameraUp ? 0 : this.t;

    this.chars.forEach((char, index) => {
      this.perCharUpdateCallback(char, index, this.easing(time));
    });
  }
}

export default AnimatedText;
