import getState from "../state";
import { getVehicles } from "./vehicleManager";
import { getPointer, getZoomTarget, getTransforming } from "../scene";
import * as THREE from "three";
import { clamp } from "three/src/math/MathUtils";

const state = getState();
const cameras = [];
const orbitControls = [];
let vehicleList;
const cameraDirection = new THREE.Vector3(0, 0, 0);
const cameraDirectionTarget = new THREE.Vector3();
const cameraTargetTarget = new THREE.Vector3();
const cameraTarget = new THREE.Vector3();
const farCameraTarget = new THREE.Vector3(
  -Math.PI / 2,
  Math.PI / 2,
  Math.PI / 2,
);

//const startScreenCameraTarget = new THREE.Vector3(
//  -Math.PI / 2,
//  Math.PI / 2,
//  Math.PI / 2
//);

const startScreenCameraTarget = new THREE.Camera();
let startRotationVelocity = 0;

let startScreenCameraTime = 0;

const targetObject = new THREE.Camera();
const cameraToPosition = new THREE.Vector3();
const xAxis = new THREE.Vector3(1, 0, 0);
const yAxis = new THREE.Vector3(0, 1, 0);
const zAxis = new THREE.Vector3(0, 0, 1);
const farHeight = 25; //15
const transitions = [];

const origin = new THREE.Vector3(0, 0, 0);

let zoom = -0.5;

function addCamera(camera) {
  //not great, but need to set every initialization
  vehicleList = getVehicles();
  cameras.push(camera);
}

function addControls(controls) {
  orbitControls.push(controls);
}

function commitVehicleTransition(data) {
  transitions.push({ ...data, ...{ dead: false, time: 0 } });
}

function clampRotationToRange(rotation) {
  rotation.x =
    rotation.x < 0
      ? (rotation.x + 100 * 2 * Math.PI) % (2 * Math.PI)
      : rotation.x % (2 * Math.PI);
  rotation.y =
    rotation.y < 0
      ? (rotation.y + 100 * 2 * Math.PI) % (2 * Math.PI)
      : rotation.y % (2 * Math.PI);
  rotation.z =
    rotation.z < 0
      ? (rotation.z + 100 * 2 * Math.PI) % (2 * Math.PI)
      : rotation.z % (2 * Math.PI);
}

function chooseRotation(target, rot) {
  let a = Math.abs(rot - target);
  let b = Math.abs(rot - (target - 2 * Math.PI));
  let c = Math.abs(rot - (target + 2 * Math.PI));

  let r = Math.min(Math.min(a, b), c);

  if (r === a) return target;
  if (r === b) return target - 2 * Math.PI;
  if (r === c) return target + 2 * Math.PI;
}

function updateRotationToTarget(targetRotation, rotation, velocity) {
  clampRotationToRange(targetRotation);
  clampRotationToRange(rotation);

  const rotTargetX = chooseRotation(targetRotation.x, rotation.x);
  const rotTargetY = chooseRotation(targetRotation.y, rotation.y);
  const rotTargetZ = chooseRotation(targetRotation.z, rotation.z);

  rotation.x += velocity * (rotTargetX - rotation.x);
  rotation.y += velocity * (rotTargetY - rotation.y);
  rotation.z += velocity * (rotTargetZ - rotation.z);
}

function handleTankCameraTransition(transition, updateVelocity) {
  const height = vehicleList[state.vehicleToFollow].height;
  const pointer = getPointer();
  const vehiclePosition = vehicleList[state.focusVehicle].body.position;

  //console.log(transition.time);
  if (transition.time > 0.6) {
    closeCameraUpdate(updateVelocity);
    return;
  }

  vehicleList[state.focusVehicle].getVehicleDirection(cameraDirectionTarget);

  cameraDirectionTarget.normalize();
  cameraDirectionTarget.applyAxisAngle(yAxis, 0.7 * -pointer.x);

  cameraDirection.set(
    cameraDirection.x +
      state.velocities.tankCameraUpdate *
        (cameraDirectionTarget.x - cameraDirection.x),
    cameraDirection.y +
      state.velocities.tankCameraUpdate *
        (cameraDirectionTarget.y - cameraDirection.y),
    cameraDirection.z +
      state.velocities.tankCameraUpdate *
        (cameraDirectionTarget.z - cameraDirection.z),
  );

  cameraTarget.copy(cameraDirection);

  const dt = 5;
  //transition.time < 0.2 ? Math.max((transition.time / 0.2) * 5, zoom) : 5;

  cameraTarget.multiplyScalar(1.9 + dt);

  cameraToPosition.copy(vehiclePosition);
  //cameras[0].position.copy(vehiclePosition);
  cameraToPosition.sub(cameraTarget);
  cameraToPosition.y += height;

  //cameras[0].position.sub(cameraTarget);
  //cameras[0].position.y += height;

  cameras[0].position.x +=
    state.velocities.tankCameraUpdate *
    (cameraToPosition.x - cameras[0].position.x);
  cameras[0].position.y +=
    state.velocities.tankCameraUpdate *
    (cameraToPosition.y - cameras[0].position.y);
  cameras[0].position.z +=
    state.velocities.tankCameraUpdate *
    (cameraToPosition.z - cameras[0].position.z);

  cameraTarget.add(vehiclePosition);
  cameraTargetTarget.copy(cameraTarget);

  cameraTarget.y += 5;

  //cameras[0].lookAt(cameraTarget);
  targetObject.position.copy(cameras[0].position);
  targetObject.lookAt(cameraTarget);

  updateRotationToTarget(
    targetObject.rotation,
    cameras[0].rotation,
    updateVelocity,
  );
}

function handleUpMoveDown(updateVelocity) {
  const vehiclePosition = vehicleList[state.focusVehicle].body.position;
  const previousVehiclePosition =
    vehicleList[state.previousFocusVehicle].body.position;

  const upDownMultiplier = state.velocities.positionMultiplier * 1.0;

  const s = (zoom + 1) / 3;
  if (state.cameraTransitionTime < 0.4) {
    cameras[0].position.y +=
      upDownMultiplier *
      updateVelocity *
      (farHeight * (0.8 * (1 - s) + 3 * s) - cameras[0].position.y);

    cameras[0].position.x +=
      upDownMultiplier *
      updateVelocity *
      (previousVehiclePosition.x - cameras[0].position.x);
    cameras[0].position.z +=
      upDownMultiplier *
      updateVelocity *
      (previousVehiclePosition.z - cameras[0].position.z);

    farCameraTarget.y *= 0;
    updateRotationToTarget(
      farCameraTarget,
      cameras[0].rotation,
      updateVelocity,
    );
    farCameraTarget.set(-Math.PI / 2, Math.PI / 2, Math.PI / 2);
  } else if (state.cameraTransitionTime < 1.0) {
    cameraToPosition.copy(vehiclePosition);
    cameraToPosition.y = farHeight * (0.8 * (1 - s) + 3 * s);

    cameras[0].position.x +=
      upDownMultiplier *
      updateVelocity *
      (cameraToPosition.x - cameras[0].position.x);
    cameras[0].position.y +=
      upDownMultiplier *
      updateVelocity *
      (cameraToPosition.y - cameras[0].position.y);
    cameras[0].position.z +=
      upDownMultiplier *
      updateVelocity *
      (cameraToPosition.z - cameras[0].position.z);

    farCameraTarget.y *= 0;
    updateRotationToTarget(
      farCameraTarget,
      cameras[0].rotation,
      updateVelocity,
    );
    farCameraTarget.set(-Math.PI / 2, Math.PI / 2, Math.PI / 2);
  } else {
    state.cameraTransition = null;
    state.cameraUp = false;
  }
  state.cameraTransitionTime += state.velocities.cameraUpMoveDownDt;
}

function handleVehicleCloseCameraTransition(transition, updateVelocity) {
  switch (transition.type) {
    case "tankCameraTransition":
    default:
      handleTankCameraTransition(transition, updateVelocity);
      break;
  }
}

function handleCameraTransition(updateVelocity) {
  switch (state.cameraTransition) {
    case "upMoveDown":
    default:
      handleUpMoveDown(1.5 * updateVelocity);
      break;
  }
}

function getKeyFramedParametersValue(time) {
  const keyframes = state.vehicles[state.focusVehicle].cameraKeyframes;

  const value = { rotationY: 0, height: 0, distance: 0 };
  for (let i = 0; i < keyframes.length - 1; i++) {
    if (time >= keyframes[i].t && time <= keyframes[i + 1].t) {
      const coef =
        (time - keyframes[i].t) / (keyframes[i + 1].t - keyframes[i].t);
      value["rotationY"] =
        (1 - coef) * keyframes[i]["rotationY"] +
        coef * keyframes[i + 1]["rotationY"];
      value["height"] =
        (1 - coef) * keyframes[i]["height"] + coef * keyframes[i + 1]["height"];
      value["distance"] =
        (1 - coef) * keyframes[i]["distance"] +
        coef * keyframes[i + 1]["distance"];
    }
  }

  return value;
}

function getKeyFramedParametersGlobalCameraValue(time) {
  const keyframes = state.globalCameraKeyframes;

  const value = { radius: 0, height: 0, targetPosition: { x: 0, y: 0, z: 0 } };
  for (let i = 0; i < keyframes.length - 1; i++) {
    if (time >= keyframes[i].t && time <= keyframes[i + 1].t) {
      const coef =
        (time - keyframes[i].t) / (keyframes[i + 1].t - keyframes[i].t);
      value["targetPosition"].x =
        (1 - coef) * keyframes[i]["targetPosition"].x +
        coef * keyframes[i + 1]["targetPosition"].x;
      value["targetPosition"].y =
        (1 - coef) * keyframes[i]["targetPosition"].y +
        coef * keyframes[i + 1]["targetPosition"].y;
      value["targetPosition"].z =
        (1 - coef) * keyframes[i]["targetPosition"].z +
        coef * keyframes[i + 1]["targetPosition"].z;

      value["radius"] =
        (1 - coef) * keyframes[i]["radius"] + coef * keyframes[i + 1]["radius"];

      value["height"] =
        (1 - coef) * keyframes[i]["height"] + coef * keyframes[i + 1]["height"];
    }
  }

  return value;
}

function closeCameraUpdate(updateVelocity) {
  const time = vehicleList[state.focusVehicle].innerTime;
  const useKeyframes = state.vehicles[state.focusVehicle].useKeyframes;
  let rotationY, height, distance;
  if (useKeyframes) {
    const keyFramed = getKeyFramedParametersValue(time);
    rotationY = keyFramed.rotationY;
    distance = keyFramed.distance;
    height = keyFramed.height;
  } else {
    rotationY = state.vehicles[state.focusVehicle].rotation.y;
    height = state.vehicles[state.focusVehicle].height;
    distance = state.vehicles[state.focusVehicle].distance;
  }
  const pointer = getPointer();
  const vehiclePosition = vehicleList[state.focusVehicle].body.position;
  vehicleList[state.focusVehicle].getVehicleDirection(cameraDirectionTarget);

  cameraDirectionTarget.normalize();
  //cameraDirectionTarget.applyAxisAngle(xAxis, rotation.x);
  //cameraDirectionTarget.applyAxisAngle(zAxis, rotation.z);
  cameraDirectionTarget.applyAxisAngle(yAxis, 0.7 * -pointer.x + rotationY);

  cameraDirection.set(
    cameraDirection.x +
      updateVelocity * (cameraDirectionTarget.x - cameraDirection.x),
    cameraDirection.y +
      updateVelocity * (cameraDirectionTarget.y - cameraDirection.y),
    cameraDirection.z +
      updateVelocity * (cameraDirectionTarget.z - cameraDirection.z),
  );

  cameraTarget.copy(cameraDirection);
  cameraTarget.multiplyScalar(distance + 0.8 * zoom);

  cameraToPosition.copy(vehiclePosition);
  //cameraToPosition.x += 0.02 * Math.random();
  //cameraToPosition.z += 0.02 * Math.random();
  //cameras[0].position.copy(vehiclePosition);
  cameraToPosition.sub(cameraTarget);
  cameraToPosition.y = height;

  //cameras[0].position.sub(cameraTarget);<t_��>hcall v:lua.cmp.utils.feedkeys.call.run(2523)
  //ý
  //cameras[0].position.y += height;

  const positionMultiplier = state.velocities.positionMultiplier;

  cameras[0].position.x +=
    positionMultiplier *
    updateVelocity *
    (cameraToPosition.x - cameras[0].position.x);
  cameras[0].position.y +=
    positionMultiplier *
    updateVelocity *
    (cameraToPosition.y - cameras[0].position.y);
  cameras[0].position.z +=
    positionMultiplier *
    updateVelocity *
    (cameraToPosition.z - cameras[0].position.z);

  cameraTarget.copy(vehiclePosition);
  cameraTargetTarget.copy(cameraTarget);

  //cameras[0].lookAt(cameraTarget);
  targetObject.position.copy(cameras[0].position);
  targetObject.lookAt(cameraTarget);

  updateRotationToTarget(
    targetObject.rotation,
    cameras[0].rotation,
    updateVelocity,
  );
}

function farCameraUpdate(updateVelocity) {
  const pointer = getPointer();
  const vehiclePosition = vehicleList[state.focusVehicle].body.position;

  cameraToPosition.copy(vehiclePosition);
  const s = (zoom + 1) / 3;
  cameraToPosition.y = farHeight * (0.8 * (1 - s) + 3 * s);

  const farMultiplier = state.velocities.positionMultiplier;

  cameras[0].position.x +=
    farMultiplier *
    updateVelocity *
    (cameraToPosition.x - cameras[0].position.x);
  cameras[0].position.y +=
    farMultiplier *
    updateVelocity *
    (cameraToPosition.y - cameras[0].position.y);
  cameras[0].position.z +=
    farMultiplier *
    updateVelocity *
    (cameraToPosition.z - cameras[0].position.z);

  farCameraTarget.y *= 0.0 + 0.2 * pointer.y;
  farCameraTarget.x *= 1.0 - 0.2 * pointer.x;

  updateRotationToTarget(farCameraTarget, cameras[0].rotation, updateVelocity);
  farCameraTarget.set(-Math.PI / 2, Math.PI / 2, Math.PI / 2);
}

function setStartCameraTime(time) {
  startScreenCameraTime = time;
}

function startScreenCameraUpdate(updateVelocity) {
  const pointer = getPointer();

  if (!state.stopStartCameraTime)
    startScreenCameraTime += state.velocities.startScreenDt;
  startScreenCameraTime = startScreenCameraTime % 1;

  cameraToPosition.set(100, 40, 0);
  //cameraToPosition.applyAxisAngle(yAxis, startScreenCameraTime * 2 * Math.PI);

  const { radius, height, targetPosition } =
    getKeyFramedParametersGlobalCameraValue(startScreenCameraTime);

  cameraToPosition.x = radius * Math.cos(startScreenCameraTime * 2 * Math.PI);
  cameraToPosition.z = radius * Math.sin(startScreenCameraTime * 2 * Math.PI);

  cameraToPosition.y = height;

  const positionMultiplier = state.velocities.positionMultiplier;

  cameras[0].position.x +=
    updateVelocity *
    positionMultiplier *
    (cameraToPosition.x - cameras[0].position.x);
  cameras[0].position.y +=
    updateVelocity *
    positionMultiplier *
    (cameraToPosition.y - cameras[0].position.y);
  cameras[0].position.z +=
    updateVelocity *
    positionMultiplier *
    (cameraToPosition.z - cameras[0].position.z);

  //startScreenCameraTarget.y *= 0.8 + 0.2 * pointer.y;
  //startScreenCameraTarget.x *= 1.0 - 0.2 * pointer.x;

  //origin.set(0, 0, 0);
  //origin.x += 5.0 * pointer.x;
  origin.copy(targetPosition);
  origin.y += 0.05 * (245.0 * pointer.y - origin.y);

  startScreenCameraTarget.position.copy(cameras[0].position);
  startScreenCameraTarget.lookAt(origin);
  // console.log(startScreenCameraTarget.rotation.y, -pointer.x / 5.0);
  startScreenCameraTarget.rotation.y += -pointer.x / 5.0;

  updateRotationToTarget(
    startScreenCameraTarget.rotation,
    cameras[0].rotation,
    updateVelocity,
  );

  if (state.logGlobalCameraTime) console.log(startScreenCameraTime);

  //cameras[0].lookAt(origin);
  //startScreenCameraTarget.set(-Math.PI / 2, Math.PI / 2, Math.PI / 2);
}

function updateCamera() {
  if (orbitControls.length > 0 && !state.cameraFollowsVehicle) {
    orbitControls[0].update();
    const transforming = getTransforming();

    if (transforming) orbitControls[0].enabled = false;
    else orbitControls[0].enabled = true;
  } else {
    //console.log(zoom);
    const updateVelocity = state.velocities.generalCameraUpdate;

    if (orbitControls.length > 0) orbitControls[0].enabled = false;
    const zoomTarget = getZoomTarget();
    zoom += (zoomTarget - zoom) * updateVelocity;

    //handle transitions specific for each vehicle
    let foundVehicleTransition = null;
    let toKill = true;
    let toKillN = -1;
    for (let i = 0; i < transitions.length; i++) {
      const transition = transitions[i];
      if (
        transition.vehicle === state.focusVehicle &&
        !state.cameraUp &&
        !state.startScreenCamera &&
        !transition.dead
      )
        foundVehicleTransition = transition;

      if (transition.dead && toKill) toKillN = i;
      else toKill = false;

      if (transition.dead) continue;

      transition.time += state.velocities.cameraTransitionDt;
      if (transition.time >= 1.0) transition.dead = true;
    }

    for (let i = 0; i < toKillN + 1; i++) transitions.shift();

    //if (state.focusVehicle != -1)
    //  console.log(vehicleList[state.focusVehicle].innerTime);

    //handle global camera transitions like move from one vehicle to another
    if (state.cameraTransition) {
      handleCameraTransition(updateVelocity);
      return;
    }

    if (foundVehicleTransition) {
      handleVehicleCloseCameraTransition(
        foundVehicleTransition,
        updateVelocity,
      );
      return;
    }

    if (state.startScreenCamera) startScreenCameraUpdate(updateVelocity);
    else if (state.cameraUp) farCameraUpdate(updateVelocity);
    else closeCameraUpdate(updateVelocity);

    //if (zoom < 2) closeCameraUpdate();
    //else farCameraUpdate();
  }
}

class cameraInteraction {
  constructor(data, type) {
    this.data = data;
    this.type = type ? type : "default";
    this.running = false;
    this.started = false;
    this.finished = false;
  }

  start() {
    switch (this.type) {
      case "tankCameraTransition":
        commitVehicleTransition({
          type: this.type,
          vehicle: this.data.vehicle,
        });

        this.transition = transitions[transitions.length - 1];

        this.getT = () => {
          return this.transition.time;
        };
        break;
      case "upMoveDown":
      default:
        state.previousFocusVehicle = state.focusVehicle;
        state.focusVehicle = this.data.next;

        if (state.focusVehicle < 0)
          state.focusVehicle += state.numberOfVehicles; //state.vehicles.length;
        state.focusVehicle = state.focusVehicle % state.numberOfVehicles; //state.vehicles.length;
        if (!state.cameraUp) {
          state.cameraTransition = "upMoveDown";
          state.cameraTransitionTime = 0;
        }

        this.getT = () => {
          return state.cameraTransition ? state.cameraTransitionTime : 1;
        };
        break;
    }

    this.running = true;
    this.started = true;
  }

  checkIfFinished() {
    if (!this.started) return false;

    let t = this.getT();

    if (t >= 1) {
      this.running = false;
      this.finished = true;
    }

    return this.finished;
  }
  checkIfStarted() {
    return this.started;
  }

  checkIfRunning() {
    if (!this.started) return false;

    let t = this.getT();
    if (t < 1) return true;
    else return false;
  }
  toStartNextInteraction() {
    return this.nextInteractionStart == "now";
  }

  setNextInteractionStart(nextInteractionStart) {
    this.nextInteractionStart = nextInteractionStart;
  }
}

function cleanCameraManager() {
  startScreenCameraTime = 0;
  while (cameras.length > 0) {
    cameras.pop();
  }
  while (orbitControls.length > 0) {
    orbitControls.pop();
  }
  while (vehicleList.length > 0) {
    vehicleList.pop();
  }
  while (transitions.length > 0) {
    transitions.pop();
  }
}

export {
  addCamera,
  addControls,
  updateCamera,
  cameraInteraction,
  setStartCameraTime,
  cleanCameraManager,
};
