const functionsSeparator = "#layers functions";
const inputsSeparator = "#layers inputs";
const outputSeparator = "#layers output";

function stringFloat(num) {
  let numString = "" + num;
  if (numString.length == 1) numString += ".0";
  return numString;
}

class LayeredShader {
  constructor(defaultShader, layerTypeFunctionsCode) {
    this.defaultShader = defaultShader;
    this.compiledShader = defaultShader;
    this.layerTypeFunctionsCode = layerTypeFunctionsCode;
  }

  clone() {
    return new this.constructor(
      this.defaultShader,
      this.layerTypeFunctionsCode,
    );
  }

  compile(layers) {
    let shader = this.defaultShader;
    //inputs
    const splittedForInputs = shader.split(inputsSeparator);
    let inputsString = "";
    layers.forEach((layer, index) => {
      switch (layer.input.type) {
        case "texture":
          inputsString += "uniform sampler2D layerTexture" + index + ";\r\n";
          break;
        case "color":
          inputsString += "uniform vec3 layerColor" + index + ";\r\n";
          break;
        default:
          break;
      }
    });
    shader = splittedForInputs.join(inputsString);

    //functions

    const counterMap = {};
    Object.keys(this.layerTypeFunctionsCode).forEach((key) => {
      counterMap[key] = 0;
    });

    const splittedForFunctions = shader.split(functionsSeparator);
    let functionsString = "";
    layers.forEach((layer) => {
      if (counterMap[layer.type] === 0)
        functionsString += this.layerTypeFunctionsCode[layer.type];
      counterMap[layer.type]++;
    });
    shader = splittedForFunctions.join(functionsString);

    //output
    const splittedForOutput = shader.split(outputSeparator);
    let outputString = "";
    outputString += "\tvec2 layerUV = gl_FragCoord.xy / resolution;\r\n";
    outputString +=
      "\tfinal.rgb *= " + stringFloat(layers[0].transparency) + ";\r\n";
    layers.forEach((layer, index) => {
      if (index === 0) return;
      if (layer.input.type === "texture") {
        outputString +=
          "\t vec4 sample" +
          index +
          " = texture(layerTexture" +
          index +
          ", layerUV);\r\n";

        outputString +=
          "\tvec3 layerColor" +
          index +
          " = sample" +
          index +
          ".a * sample" +
          index +
          ".xyz;\r\n";
      }
      outputString +=
        "\tfinal.rgb = " +
        stringFloat(layer.transparency) +
        " * " +
        layer.type +
        "(layerColor" +
        index +
        ", final.rgb) + " +
        stringFloat(layer.previousLayerTransparency) +
        " * final.rgb;\r\n";
    });
    shader = splittedForOutput.join(outputString);
    this.compiledShader = shader;
    return this.compiledShader;
  }
}

export { LayeredShader };
