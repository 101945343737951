export const easings = {
  linear: (t) => t,

  quadraticIn: (t) => t * t,
  quadraticOut: (t) => t * (2 - t),
  quadraticInOut: (t) => {
    if ((t *= 2) < 1) {
      return 0.5 * t * t;
    }

    return -0.5 * (--t * (t - 2) - 1);
  },

  cubicIn: (t) => t * t * t,
  cubicOut: (t) => --t * t * t + 1,
  cubicInOut: (t) => {
    if ((t *= 2) < 1) {
      return 0.5 * t * t * t;
    }

    return 0.5 * ((t -= 2) * t * t + 2);
  },

  quarticIn: (t) => t * t * t * t,
  quarticOut: (t) => 1 - --t * t * t * t,
  quarticInOut: (t) => {
    if ((t *= 2) < 1) {
      return 0.5 * t * t * t * t;
    }

    return -0.5 * ((t -= 2) * t * t * t - 2);
  },

  quinticIn: (t) => t * t * t * t * t,
  quinticOut: (t) => --t * t * t * t * t + 1,
  quinticInOut: (t) => {
    if ((t *= 2) < 1) {
      return 0.5 * t * t * t * t * t;
    }

    return 0.5 * ((t -= 2) * t * t * t * t + 2);
  },

  SinusoidalIn: (t) => 1 - Math.cos((t * Math.PI) / 2),
  SinusoidalOut: (t) => Math.sin((t * Math.PI) / 2),
  SinusoidalInOut: (t) => 0.5(1 - Math.cos(Math.PI * t)),

  ExponentialIn: (t) => (t === 0 ? 0 : Math.pow(1024, t - 1)),
  ExponentialOut: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
  ExponentialInOut: (t) => {
    if (t === 0) {
      return 0;
    }

    if (t === 1) {
      return 1;
    }

    if ((t *= 2) < 1) {
      return 0.5 * Math.pow(1024, t - 1);
    }

    return 0.5 * (-Math.pow(2, -10 * (t - 1)) + 2);
  },

  CircularIn: (t) => 1 - Math.sqrt(1 - t * t),
  CircularOut: (t) => Math.sqrt(1 - --t * t),
  CircularInOut: (t) => {
    if ((t *= 2) < 1) {
      return -0.5 * (Math.sqrt(1 - t * t) - 1);
    }

    return 0.5 * (Math.sqrt(1 - (t -= 2) * t) + 1);
  },
};
