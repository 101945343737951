import { Pane } from "tweakpane";
import * as THREE from "three";
import {
  curveObject,
  getScene,
  restart,
  setRegime,
  samosvalSendImpulse,
  samosvalContinue,
} from "./scene";
import { getVehicles, createVehicle } from "./managers/vehicleManager";
import {
  getCurvesList,
  createDefaultCurve,
  updateStateCurves,
} from "./managers/curveManager";
import getState from "./state";
import { updateRenderingState } from "./managers/renderer";
import { setStartCameraTime } from "./managers/cameraManager";
import {
  setDynamicMaterialMatCapByIndex,
  startSceneImpulse,
} from "./managers/dynamicMaterialsManager";
import { getAssets, loadMatCap, loadLayerTexture } from "./loader";
import { setBackgroundColor, compileBackground } from "./background";
import { layerNames } from "./layers/layers";

const state = getState();
let pane;

const v3 = new THREE.Vector3();

const curveList = getCurvesList();
const vehicleList = getVehicles();

const input = document.createElement("input");

const matCapInputsArray = [
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
];

const matcapOptions = {
  whiteMatCap: "whiteMatCap",
  waterMatCap: "waterMatCap",
  //orangeMatCap: "orangeMatCap",
  blackMatCap: "blackMatCap",
  //bluishMatCap: "bluishMatCap",
  colorfulMatCap: "colorfulMatCap",
  //violetYellowMatCap: "violetYellowMatCap",
  customMatCap0: "customMatCap0",
  customMatCap1: "customMatCap1",
  customMatCap2: "customMatCap2",
  customMatCap3: "customMatCap3",
  customMatCap4: "customMatCap4",
  customMatCap5: "customMatCap5",
  customMatCap6: "customMatCap6",
  customMatCap7: "customMatCap7",
  customMatCap8: "customMatCap8",
  none: "none",
};

const layerTextureInputsArray = [
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
];

const assets = getAssets();
const layerTextureOptions = { None: "None" };
Object.keys(assets.layerTextures).forEach((key) => {
  layerTextureOptions[key] = key;
});

function createCurveFolder(inFolder, curveKey) {
  const cfolder = inFolder.addFolder({ title: curveKey, expanded: false });

  const curveIndex = curveKey.slice(5);

  cfolder.addInput(state.curves[curveIndex], "render").on("change", () => {
    curveList[curveKey].setCylinderVisibility(state.curves[curveIndex].render);
  });
}
function createVehicleFolder(inFolder, vehicleIndex) {
  const vfolder = inFolder.addFolder({
    title: "vehicle" + vehicleIndex,
    expanded: false,
  });

  vfolder.addInput(state.vehicles[vehicleIndex], "rotation", {
    x: { min: -Math.PI, max: Math.PI },
    y: { min: -Math.PI, max: Math.PI },
    z: { min: -Math.PI, max: Math.PI },
  });

  vfolder.addInput(state.vehicles[vehicleIndex], "height", { min: 0, max: 20 });
  vfolder.addInput(state.vehicles[vehicleIndex], "distance", {
    min: 0,
    max: 20,
  });

  vfolder
    .addBlade({
      view: "text",
      label: "path",
      parse: (v) => String(v),
      value: vehicleIndex,
    })
    .on("change", (e) => {
      const v = e.value;
      state.vehicles[vehicleIndex].path = v;
      vehicleList[vehicleIndex].setCurve(
        curveList["curve" + v],
        null,
        null,
        false,
        0,
      );
    });

  if (state.vehicles[vehicleIndex].cameraKeyframes) {
    const keyFolder = vfolder.addFolder({
      title: "CameraKeyframes",
      expanded: false,
    });
    keyFolder.addInput(state.vehicles[vehicleIndex], "useKeyframes");

    state.vehicles[vehicleIndex].cameraKeyframes.forEach((keyframe, index) => {
      const currentKeyFolder = keyFolder.addFolder({
        title: "keyframe" + index + "folder",
        expanded: false,
      });

      currentKeyFolder.addInput(
        state.vehicles[vehicleIndex].cameraKeyframes[index],
        "t",
        { min: 0, max: 1 },
      );
      currentKeyFolder.addInput(
        state.vehicles[vehicleIndex].cameraKeyframes[index],
        "rotationY",
        { min: -Math.PI, max: Math.PI },
      );
      currentKeyFolder.addInput(
        state.vehicles[vehicleIndex].cameraKeyframes[index],
        "height",
        { min: 0, max: 20 },
      );
      currentKeyFolder.addInput(
        state.vehicles[vehicleIndex].cameraKeyframes[index],
        "distance",
        { min: 0, max: 20 },
      );
    });
  }
}

export function updateGUI() {
	pane.refresh();
}

export function buildGUI() {
  pane = new Pane();
  const options = pane.addFolder({ title: "Options", expanded: false });
  const cylinder = options.addFolder({
    title: "LoadCylinder",
    expanded: false,
  });
  cylinder.addInput(state, "loadCylinderStartX", { min: -10, max: 10 });
  cylinder.addInput(state, "loadCylinderStartY", { min: -10, max: 10 });
  cylinder.addInput(state, "loadCylinderScaleY", { min: 1, max: 20 });

  const rendering = options.addFolder({ title: "Rendering", expanded: false });

  rendering
    .addInput(state, "renderer", {
      options: { composer: "composer", direct: "direct" },
    })
    .on("change", () => {
      updateRenderingState();
    });

  rendering
    .addInput(state, "geometryRenderMSAASamples", { min: 0, max: 32, step: 1 })
    .on("change", () => {
      updateRenderingState();
    });
  rendering
    .addInput(state, "pixelRatio", { min: 0.5, max: 3 })
    .on("change", () => {
      updateRenderingState();
    });

  rendering.addInput(state, "backgroundColor").on("change", () => {
    setBackgroundColor(state.backgroundColor);
    updateRenderingState();
  });

  rendering.addInput(state, "ambientColor").on("change", () => {
    updateRenderingState();
  });

  rendering
    .addInput(state, "ambientIntensity", { min: 0, max: 20 })
    .on("change", () => {
      updateRenderingState();
    });
  rendering
    .addInput(state, "ToneMapping", {
      options: {
        NO_TONEMAP: "NONE",
        TONEMAP_SIMPLE: "TONEMAP_SIMPLE",
        TONEMAP_REINHARD: "TONEMAP_REINGHARD",
        TONEMAP_REINHARD_LUMA: "TONEMAP_REINHARD_LUMA",
        TONEMAP_REINHARD_WHITE: "TONEMAP_REINHARD_WHITE",
        TONEMAP_FILMIC: "TONEMAP_FILMIC",
        TONEMAP_PHOTOGRAPHIC: "TONEMAP_PHOTOGRAPHIC",
        TONEMAP_UNCHARTED: "TONEMAP_UNCHARTED",
      },
    })
    .on("change", () => {
      updateRenderingState();
    });

  rendering
    .addInput(state, "ToneMappingExposure", { min: 0, max: 5 })
    .on("change", () => {
      updateRenderingState();
    });

  const matcaps = rendering.addFolder({
    title: "non-curve matcaps",
    expanded: false,
  });

  matcaps
    .addInput(state, "carsMatCap", { options: matcapOptions })
    .on("change", () => {
      updateRenderingState();
    });

  matcaps
    .addInput(state, "accentMatCap", { options: matcapOptions })
    .on("change", () => {
      const assets = getAssets();
      setDynamicMaterialMatCapByIndex(0, assets[state.accentMatCap]);
      setDynamicMaterialMatCapByIndex(6, assets[state.accentMatCap]);
    });
  matcaps
    .addInput(state, "baseMatCap", { options: matcapOptions })
    .on("change", () => {
      const assets = getAssets();
      setDynamicMaterialMatCapByIndex(1, assets[state.baseMatCap]);
      setDynamicMaterialMatCapByIndex(7, assets[state.baseMatCap]);
    });
  matcaps
    .addInput(state, "roadMatCap", { options: matcapOptions })
    .on("change", () => {
      const assets = getAssets();
      setDynamicMaterialMatCapByIndex(2, assets[state.roadMatCap]);
    });
  matcaps
    .addInput(state, "planeMatCap", { options: matcapOptions })
    .on("change", () => {
      const assets = getAssets();
      setDynamicMaterialMatCapByIndex(3, assets[state.planeMatCap]);
    });
  matcaps
    .addInput(state, "waterMatCap", { options: matcapOptions })
    .on("change", () => {
      const assets = getAssets();
      setDynamicMaterialMatCapByIndex(4, assets[state.waterMatCap]);
    });
  matcaps
    .addInput(state, "treesMatCap", { options: matcapOptions })
    .on("change", () => {
      const assets = getAssets();
      setDynamicMaterialMatCapByIndex(5, assets[state.treesMatCap]);
    });
  matcaps
    .addInput(state, "bricksMatCap", { options: matcapOptions })
    .on("change", () => {
      const assets = getAssets();
      setDynamicMaterialMatCapByIndex(9, assets[state.bricksMatCap]);
      setDynamicMaterialMatCapByIndex(10, assets[state.bricksMatCap]);
    });
  matcaps
    .addInput(state, "trainMatCap", { options: matcapOptions })
    .on("change", () => {
      const assets = getAssets();
      setDynamicMaterialMatCapByIndex(8, assets[state.trainMatCap]);
    });

  const curveRendering = rendering.addFolder({
    title: "CurveRendering",
    expanded: false,
  });
  curveRendering
    .addInput(state, "OilInsideMatCap", {
      options: matcapOptions,
    })
    .on("change", () => {
      updateRenderingState();
    });
  curveRendering
    .addInput(state, "OilInsideIntensity", { min: 0, max: 550 })
    .on("change", () => {
      updateRenderingState();
    });
  curveRendering.addInput(state, "OilInsideColor").on("change", () => {
    updateRenderingState();
  });

  curveRendering
    .addInput(state, "OilOutsideMatCap", {
      options: matcapOptions,
    })
    .on("change", () => {
      updateRenderingState();
    });
  curveRendering
    .addInput(state, "OilOutsideIntensity", { min: 0, max: 550 })
    .on("change", () => {
      updateRenderingState();
    });
  curveRendering.addInput(state, "OilOutsideColor").on("change", () => {
    updateRenderingState();
  });
  curveRendering
    .addInput(state, "PathBeforeMatCap", {
      options: matcapOptions,
    })
    .on("change", () => {
      updateRenderingState();
    });
  curveRendering
    .addInput(state, "PathBeforeIntensity", { min: 0, max: 550 })
    .on("change", () => {
      updateRenderingState();
    });
  curveRendering.addInput(state, "PathBeforeColor").on("change", () => {
    updateRenderingState();
  });
  curveRendering
    .addInput(state, "PathAfterMatCap", {
      options: matcapOptions,
    })
    .on("change", () => {
      updateRenderingState();
    });
  curveRendering
    .addInput(state, "PathAfterIntensity", { min: 0, max: 550 })
    .on("change", () => {
      updateRenderingState();
    });
  curveRendering.addInput(state, "PathAfterColor").on("change", () => {
    updateRenderingState();
  });

  const pulseRendering = rendering.addFolder({
    title: "PulseRendering",
    expanded: false,
  });
  pulseRendering.addInput(state, "BigPulseColor").on("change", () => {
    updateRenderingState();
  });
  pulseRendering
    .addInput(state, "BigPulseIntensity", { min: 0, max: 550 })
    .on("change", () => {
      updateRenderingState();
    });
  pulseRendering.addInput(state, "SmallPulseColor").on("change", () => {
    updateRenderingState();
  });
  pulseRendering
    .addInput(state, "SmallPulseIntensity", { min: 0, max: 550 })
    .on("change", () => {
      updateRenderingState();
    });

  const GTAO = rendering.addFolder({ title: "GTAO", expanded: false });
  GTAO.addInput(state, "GTAO", {
    options: { off: "off", nonBlured: "non-blured", Blured: "blured" },
  }).on("change", () => {
    updateRenderingState();
  });

  GTAO.addInput(state, "GTAODownscaleMultiplier", { min: 0.3, max: 2 }).on(
    "change",
    () => {
      updateRenderingState();
    },
  );

  GTAO.addInput(state, "GTAOcolor").on("change", () => {
    updateRenderingState();
  });
  GTAO.addInput(state, "scaling", { min: 0, max: 10 }).on("change", () => {
    updateRenderingState();
  });
  GTAO.addInput(state, "sliceCount", { min: 0, max: 32, step: 1 }).on(
    "change",
    () => {
      updateRenderingState();
    },
  );
  GTAO.addInput(state, "directionalSamples", { min: 0, max: 32, step: 1 }).on(
    "change",
    () => {
      updateRenderingState();
    },
  );

  const Bloom = rendering.addFolder({ title: "Bloom", expanded: false });
  Bloom.addInput(state, "Bloom", { options: { off: "off", on: "on" } }).on(
    "change",
    () => {
      updateRenderingState();
    },
  );
  Bloom.addInput(state, "filterRadius", { min: 0.0, max: 0.5 }).on(
    "change",
    () => {
      updateRenderingState();
    },
  );
  Bloom.addInput(state, "bloomStrength", { min: 0.0, max: 0.5 }).on(
    "change",
    () => {
      updateRenderingState();
    },
  );

  const cameraKeyframes = options.addFolder({
    title: "startCameraKeyframes",
    expanded: false,
  });
  cameraKeyframes.addInput(state, "logGlobalCameraTime");
  cameraKeyframes.addInput(state, "stopStartCameraTime");
  cameraKeyframes
    .addInput(state, "startCameraTime", { min: 0, max: 1 })
    .on("change", () => {
      setStartCameraTime(state.startCameraTime);
    });

  state.globalCameraKeyframes.forEach((keyframe, index) => {
    const currentKeyFolder = cameraKeyframes.addFolder({
      title: "keyframe" + index + "folder",
      expanded: false,
    });

    currentKeyFolder.addInput(state.globalCameraKeyframes[index], "t", {
      min: 0,
      max: 1,
    });
    currentKeyFolder.addInput(state.globalCameraKeyframes[index], "radius", {
      min: 0,
      max: 200,
    });
    currentKeyFolder.addInput(state.globalCameraKeyframes[index], "height", {
      min: 0,
      max: 100,
    });
    currentKeyFolder.addInput(
      state.globalCameraKeyframes[index],
      "targetPosition",
      {
        x: { min: -100, max: 100 },
        y: { min: -100, max: 100 },
        z: { min: -100, max: 100 },
      },
    );
  });

  const vehicle = options.addFolder({
    title: "Vehicle",
    expanded: false,
  });
  vehicle
    .addButton({
      title: "Restart",
    })
    .on("click", restart);
  vehicle
    .addButton({
      title: "SendImpulse",
    })
    .on("click", samosvalSendImpulse);
  vehicle
    .addButton({
      title: "ContinueMovement",
    })
    .on("click", samosvalContinue);

  vehicle.addInput(state, "vehicleToCreate", {
    options: {
      Car1: "Car1",
      Samosval: "Samosval",
      TankTruck: "TankTruck",
      Taxi: "Taxi",
    },
  });

  vehicle
    .addButton({
      title: "CreateVehicle",
    })
    .on("click", () => {
      createVehicle(state.vehicleToCreate);
      createVehicleFolder(vehicle, vehicleList.length - 1);
      state.vehicles.push({ type: "Samosval", path: 0 });
    });
  vehicleList.forEach((veh, ind) => {
    createVehicleFolder(vehicle, ind);
  });

  const offsets = options.addFolder({
    title: "CarTypeOffsets",
    expanded: false,
  });
  Object.keys(state.carTypes).forEach((type) => {
    const carType = offsets.addFolder({
      title: type,
      expanded: false,
    });

    carType.addInput(state.carTypes[type], "bodyOffset", {
      x: { min: -0.5, max: 0.5 },
      y: { min: -0.5, max: 0.5 },
      z: { min: -0.5, max: 0.5 },
    });
    Object.keys(state.carTypes[type].wheelOffsets).forEach((wo, ind) => {
      carType.addInput(state.carTypes[type].wheelOffsets, wo, {
        x: { min: -0.5, max: 0.5 },
        y: { min: -0.5, max: 0.5 },
        z: { min: -0.5, max: 0.5 },
      });
    });
  });

  //    fixedTimeStep: 1.1 / 360.0,
  //  physDt: 1.1 * 0.016,
  //curveImpulseDt: 0.001,
  // globalMaterialsDt: 0.01,
  //circularVehicleDt: 0.003,
  //tankMaterialDt: 0.001,
  //cameraTransitionDt: 0.001,
  //cameraUpMoveDownDt: 0.004,
  //tankCameraUpdate: 0.015,
  //generalCameraUpdate: 0.05,

  const velocities = options.addFolder({
    title: "Velocities",
    expanded: false,
  });
  velocities.addInput(state.velocities, "numSteps", {
    min: 1,
    max: 125,
    step: 1,
  });
  velocities.addInput(state.velocities, "fixedTimeStep", {
    min: 0.0001,
    max: 0.05,
  });
  velocities.addInput(state.velocities, "timeSpeed", {
    min: 0.5,
    max: 3,
  });
  velocities.addInput(state.velocities, "globalMaterialsDt", {
    min: 0.001,
    max: 0.05,
  });
  velocities.addInput(state.velocities, "curveImpulseDt", {
    min: 0.001,
    max: 0.05,
  });
  velocities.addInput(state.velocities, "circularVehicleDt", {
    min: 0.001,
    max: 0.033,
  });
  velocities.addInput(state.velocities, "tankMaterialDt", {
    min: 0.0005,
    max: 0.1,
  });
  velocities.addInput(state.velocities, "cameraTransitionDt", {
    min: 0.0006,
    max: 0.05,
  });
  velocities.addInput(state.velocities, "cameraUpMoveDownDt", {
    min: 0.001,
    max: 0.05,
  });
  velocities.addInput(state.velocities, "tankCameraUpdate", {
    min: 0.001,
    max: 0.05,
  });
  velocities.addInput(state.velocities, "generalCameraUpdate", {
    min: 0.001,
    max: 0.1,
  });
  velocities.addInput(state.velocities, "maxSpeedMultiplier", {
    min: 0.001,
    max: 2,
  });

  const curves = options.addFolder({
    title: "Curves",
    expanded: false,
  });

  curves
    .addBlade({
      view: "text",
      label: "chosenCurve",
      parse: (v) => String(v),
      value: "0",
    })
    .on("change", (e) => {
      state.chosenCurve = e.value;
    });

  curves.addInput(state, "point", {
    x: { min: -20, max: 20 },
    y: { min: -20, max: 20 },
    z: { min: -20, max: 20 },
  });
  curves
    .addButton({
      title: "Add",
      label: "Add point", // optional
    })
    .on("click", () => {
      v3.set(state.point.x, state.point.y, state.point.z);
      curveList["curve" + state.chosenCurve].addPoint(v3.clone());
    });
  curves
    .addButton({
      title: "Add",
      label: "Add nearby point", // optional
    })
    .on("click", () => {
      const curve = curveList["curve" + state.chosenCurve];
      const lastPoint = curve.curve.points[curve.curve.points.length - 1];
      v3.copy(lastPoint);
      v3.x += 0.5;
      curveList["curve" + state.chosenCurve].addPoint(v3.clone());
    });
  curves
    .addButton({
      title: "CreateDefaultCurve",
    })
    .on("click", () => {
      const scene = getScene();
      createDefaultCurve(scene, 2);
      updateStateCurves();
      const N = Object.keys(curveList).length - 1;
      createCurveFolder(curves, "curve" + N);
    });
  // curves
  //   .addButton({
  //     title: "Toggle",
  //     label: "Hide",
  //   })
  //   .on("click", toggleTransformControl);
  Object.keys(curveList).forEach((curveKey) => {
    //createCurveFolder(curves, curveKey);
  });
  const matCapLoaders = options.addFolder({
    title: "MatCapLoaders",
    expanded: false,
  });

  for (let i = 0; i < 9; i++) {
    const loadMatCapButton = matCapLoaders
      .addButton({
        title: "LoadMatCap" + i,
      })
      .on("click", () => {
        matCapInputsArray[i].type = "file";
        matCapInputsArray[i].click();
        const inputCallback = () => {
          if (
            matCapInputsArray[i].files &&
            matCapInputsArray[i].files.length > 0
          ) {
            loadMatCap(
              URL.createObjectURL(matCapInputsArray[i].files[0]),
              i,
              () => {
                const assets = getAssets();
                updateRenderingState();
                setDynamicMaterialMatCapByIndex(0, assets[state.accentMatCap]);
                setDynamicMaterialMatCapByIndex(1, assets[state.baseMatCap]);
                setDynamicMaterialMatCapByIndex(2, assets[state.roadMatCap]);
                setDynamicMaterialMatCapByIndex(3, assets[state.planeMatCap]);
                setDynamicMaterialMatCapByIndex(4, assets[state.waterMatCap]);
                setDynamicMaterialMatCapByIndex(5, assets[state.treesMatCap]);
                setDynamicMaterialMatCapByIndex(6, assets[state.accentMatCap]);
                setDynamicMaterialMatCapByIndex(7, assets[state.baseMatCap]);
                setDynamicMaterialMatCapByIndex(8, assets[state.trainMatCap]);
                setDynamicMaterialMatCapByIndex(9, assets[state.bricksMatCap]);
                setDynamicMaterialMatCapByIndex(10, assets[state.bricksMatCap]);
                matCapInputsArray[i] = document.createElement("input");
              },
            );
          } else requestAnimationFrame(inputCallback);
        };

        requestAnimationFrame(inputCallback);
      });
  }

  const layers = options.addFolder({ title: "Layers", expanded: false });
  const baseLayerFolder = layers.addFolder({
    title: "BaseLayer",
    expanded: false,
  });
  baseLayerFolder
    .addInput(state.layers[0], "transparency", { min: 0.0, max: 1.0 })
    .on("change", () => {
      updateRenderingState();
    });
  state.layers.forEach((layer, index) => {
    if (index === 0) return;
    const layerFolder = layers.addFolder({
      title: "Layer" + index,
      expanded: false,
    });

    layerFolder
      .addInput(state.layers[index], "type", { options: layerNames })
      .on("change", () => {
        updateRenderingState();
      });
    layerFolder
      .addInput(state.layers[index].input, "type", {
        options: { texture: "texture", color: "color" },
      })
      .on("change", () => {
        updateRenderingState();
      });
    layerFolder
      .addInput(state.layers[index].input, "color")
      .on("change", (v) => {
        updateRenderingState();
      });
    layerFolder
      .addInput(state.layers[index].input, "texture", {
        options: layerTextureOptions,
      })
      .on("change", (v) => {
        updateRenderingState();
      });
    layerFolder
      .addInput(state.layers[index], "previousLayerTransparency", {
        min: 0.0,
        max: 1.0,
      })
      .on("change", () => {
        updateRenderingState();
      });
    layerFolder
      .addInput(state.layers[index], "transparency", { min: 0.0, max: 1.0 })
      .on("change", () => {
        updateRenderingState();
      });
    layerFolder.addInput(state.layers[index], "active").on("change", () => {
      updateRenderingState();
    });
  });

  const layerTextureLoaders = options.addFolder({
    title: "LayerTextureLoaders",
    expanded: false,
  });

  for (let i = 0; i < 9; i++) {
    const loadLayerTextureButton = layerTextureLoaders
      .addButton({
        title: "LoadLayerTexture" + i,
      })
      .on("click", () => {
        layerTextureInputsArray[i].type = "file";
        layerTextureInputsArray[i].click();
        const inputCallback = () => {
          if (
            layerTextureInputsArray[i].files &&
            layerTextureInputsArray[i].files.length > 0
          ) {
            loadLayerTexture(
              URL.createObjectURL(layerTextureInputsArray[i].files[0]),
              i,
              () => {
                updateRenderingState();
                layerTextureInputsArray[i] = document.createElement("input");
              },
            );
          } else requestAnimationFrame(inputCallback);
        };

        requestAnimationFrame(inputCallback);
      });
  }

  const other = options.addFolder({ title: "Other", expanded: false });

  other.addInput(state, "toSendImpulse");

  other.addInput(state, "cameraFollowsVehicle");
  other
    .addBlade({
      view: "text",
      label: "vehicleToFollow",
      parse: (v) => String(v),
      value: "0",
    })
    .on("change", (e) => {
      state.vehicleToFollow = e.value;
      state.focusVehicle = e.value;
    });

  other.addButton({ title: "SendSceneImpule" }).on("click", () => {
    startSceneImpulse(0, 0, 0);
  });
  other.addButton({ title: "SaveState" }).on("click", () => {
    const a = document.createElement("a");

    // updateStateCurves();
    const stateToExport = { ...state };

    // const stateToExport = pane.exportPreset();

    let file = new Blob([JSON.stringify(stateToExport)], {
      type: "text/plain",
    });
    a.href = URL.createObjectURL(file);
    a.download = "state.json";
    a.click();
  });
  const loadState = other
    .addButton({
      title: "LoadState",
    })
    .on("click", () => {
      input.type = "file";
      input.click();
    });
  const importButton = other
    .addButton({
      title: "Import",
    })
    .on("click", () => {
      if (input.files && input.files.length > 0) {
        input.files[0].text().then((res) => {
          let newState = JSON.parse(res);

          const badNames = [
            "vehicles",
            "interactions",
            "carTypes",
            "startScreenCamera",
            "cameraUp",
            "cameraTransition",
            "cameraTransitionTime",
            "vehicleToFollow",
            "renderer",
            "width",
            "height",
            "velocities",
            "velocitiesHighFPS",
          ];
          const recursiveStateUpdate = (inState, toState) => {
            if (Array.isArray(toState)) {
              for (let i = 0; i < toState.length; i++) {
                if (typeof toState[i] === "object")
                  recursiveStateUpdate(inState[i], toState[i]);
                else toState[i] = inState[i];
              }
            } else {
              Object.keys(toState).forEach((key) => {
                if (badNames.indexOf(key) !== -1) return;
                if (
                  typeof inState[key] !== "undefined" &&
                  typeof inState[key] !== "object"
                ) {
                  toState[key] = inState[key];
                } else if (inState[key] && typeof inState[key] === "object") {
                  recursiveStateUpdate(inState[key], toState[key]);
                }
              });
            }
          };

          recursiveStateUpdate(newState, state);

          setBackgroundColor(state.backgroundColor);
          updateRenderingState();
          const assets = getAssets();
          setDynamicMaterialMatCapByIndex(
            0,
            assets[state.accentMatCap]
              ? assets[state.accentMatCap]
              : assets.blackMatCap,
          );
          setDynamicMaterialMatCapByIndex(
            1,
            assets[state.baseMatCap]
              ? assets[state.baseMatCap]
              : assets.blackMatCap,
          );
          setDynamicMaterialMatCapByIndex(
            2,
            assets[state.roadMatCap]
              ? assets[state.roadMatCap]
              : assets.blackMatCap,
          );
          setDynamicMaterialMatCapByIndex(
            3,
            assets[state.planeMatCap]
              ? assets[state.planeMatCap]
              : assets.blackMatCap,
          );
          setDynamicMaterialMatCapByIndex(
            4,
            assets[state.waterMatCap]
              ? assets[state.waterMatCap]
              : assets.blackMatCap,
          );
          setDynamicMaterialMatCapByIndex(
            5,
            assets[state.treesMatCap]
              ? assets[state.treesMatCap]
              : assets.blackMatCap,
          );
          setDynamicMaterialMatCapByIndex(
            6,
            assets[state.accentMatCap]
              ? assets[state.accentMatCap]
              : assets.blackMatCap,
          );
          setDynamicMaterialMatCapByIndex(
            7,
            assets[state.baseMatCap]
              ? assets[state.baseMatCap]
              : assets.blackMatCap,
          );
          setDynamicMaterialMatCapByIndex(
            8,
            assets[state.trainMatCap]
              ? assets[state.trainMatCap]
              : assets.blackMatCap,
          );
          setDynamicMaterialMatCapByIndex(
            9,
            assets[state.bricksMatCap]
              ? assets[state.bricksMatCap]
              : assets.blackMatCap,
          );
          setDynamicMaterialMatCapByIndex(
            10,
            assets[state.bricksMatCap]
              ? assets[state.bricksMatCap]
              : assets.blackMatCap,
          );

          // pane.importPreset(newState);
          pane.refresh();
        });
      }
    });
}
