import * as THREE from "three";
import getState from "./state";
import backgroundMaterial from "./materials/backgroundMaterial";
import { getCamera } from "./scene";
import { getAssets } from "./loader";


const state = getState();
let camera;
const background = new THREE.Mesh(
  new THREE.BoxGeometry(300, 300, 300),
  backgroundMaterial(),
);

function getActiveLayersFromState() {
  return state.layers.filter((layer) => layer.active);
}

function updateLayersData(material) {
  const assets = getAssets();
  const activeLayers = getActiveLayersFromState();
  material.cleanLayers();
  activeLayers.forEach((activeLayer) => {
    material.addLayer(activeLayer, assets.layerTextures);
  });
}

export function updateBackground() {
  background.position.copy(camera.position);
}

export function setBackgroundColor(color) {
  const colorTo = new THREE.Color(color);
  colorTo.r = Math.pow(colorTo.r, 1.0 / 2.2);
  colorTo.g = Math.pow(colorTo.g, 1.0 / 2.2);
  colorTo.b = Math.pow(colorTo.b, 1.0 / 2.2);
  background.material.uniforms.color.value.set(colorTo);
}

export function compileBackground() {
  updateLayersData(background.material);
  background.material.compile();

  background.material.defines["FINAL_OUTPUT"] = state.renderer !== "composer";
  background.material.needsUpdate = true;
}

export function addBackgroundToScene(scene) {
  camera = getCamera();
  scene.add(background);
  compileBackground();
}
