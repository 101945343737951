import device from "current-device";
import updateFile from "./updateState.json";
const isLow = false;
const isBlack = true;

let browserName;

const userAgent = navigator.userAgent;
if (userAgent.match(/chrome|chromium|crios/i)) {
  browserName = "chrome";
} else if (userAgent.match(/firefox|fxios/i)) {
  browserName = "firefox";
} else if (userAgent.match(/safari/i)) {
  browserName = "safari";
} else if (userAgent.match(/opr/ / i)) {
  browserName = "opera";
} else if (userAgent.match(/edg/i)) {
  browserName = "edge";
} else {
  browserName = "No browser detection";
}

const isSafari = browserName === "safari" && device.os === "macos";
// const renderer =
//   device.mobile() || device.tablet() || isSafari ? "direct" : "composer";
let choice = "desktop";
let renderer = "composer";
let pixelRatio = 1.0;

if (device.mobile() || device.tablet()) {
  renderer = "direct";
  choice = "mobile";
  pixelRatio = Math.min(window.devicePixelRatio, 2.0);
}

export function getChoice() {
  return choice;
}

const state = {
  point: { x: 0, y: 0.1, z: 0 },
  regime: "CurveFollow",
  chosenCurve: "0",
  toSendImpulse: true,

  width: window.innerWidth,
  height: window.innerHeight,

  preloaderEasing: "cubicInOut",

  preset: "initial",
  renderer: renderer,
  carsMatCap: isLow ? "bluishMatCap" : "customMatCap1",
  accentMatCap: isLow ? "bluishMatCap" : "customMatCap1",
  baseMatCap: "customMatCap0",
  waterMatCap: "customMatCap0",
  treesMatCap: "customMatCap4",
  roadMatCap: "customMatCap3",
  planeMatCap: "customMatCap0",

  bricksMatCap: "customMatCap1",
  trainMatCap: "customMatCap1",

  defaultFrameBufferAntialiasing: true, // true,
  geometryRenderMSAASamples: 4,
  pixelRatio: pixelRatio, //device.mobile() || device.tablet() ? 2 : isSafari ? 0.75 : 1,
  backgroundColor: isBlack ? "#000000" : "#f2f5ff",
  ambientColor: isBlack ? "#030303" : "#5689dc",
  ambientIntensity: isBlack ? 0.9 : 0.6521739130434783,
  ToneMapping: isLow || isBlack ? "NONE" : "TONEMAP_UNCHARTED",
  ToneMappingExposure: 1,
  OilInsideIntensity: renderer === "direct" ? 1 : 90,
  OilInsideColor: "#ff9000",
  OilInsideMatCap: "none",
  OilOutsideIntensity: renderer === "direct" ? 1 : 90,
  OilOutsideColor: "#f58100",
  OilOutsideMatCap: isLow ? "orangeMatCap" : "none",
  PathBeforeIntensity: renderer === "direct" ? 1 : 90,
  PathBeforeColor: "#3478e2",
  PathBeforeMatCap: isLow ? "bluishMatCap" : "none",
  PathAfterIntensity: renderer === "direct" ? 1 : 90,
  PathAfterColor: "#d2741b",
  PathAfterMatCap: isLow ? "orangeMatCap" : "none",
  BigPulseColor: "#5689dc",
  BigPulseIntensity: 1,
  SmallPulseColor: "#5689dc",
  SmallPulseIntensity: 1,
  GTAO: isBlack ? "off" : "non-blured",
  GTAODownscaleMultiplier: 1,
  GTAOcolor: "#0000ff",

  gtaoMultibounce: true,
  scaling: 4.456521739130435,
  sliceCount: 6,
  directionalSamples: 5,
  Bloom: "on",
  filterRadius: 0.001,
  bloomStrength: 0.013,

  visibleHelperObjects: false,

  loadCylinderStartX: 4.5,
  loadCylinderStartY: -5,
  loadCylinderScaleY: 9,

  focusVehicle: 6,
  previousFocusVehicle: 0,
  pointedVehicle: -1,
  vehicleToCreate: "Taxi",

  startScreenCamera: true,
  cameraUp: true,
  cameraTransition: null,
  cameraTransitionTime: 0,

  cameraFollowsVehicle: true,
  vehicleToFollow: 0,
  numberOfVehicles: 10, //17, //10,

  layers: [
    {
      type: "base",
      input: { type: null },
      active: true,
      transparency: 1.0,
      previousLayerTransparency: 1.0,
    },
    {
      type: "overlay",
      input: { type: "color", color: "#0000ff", texture: "None" },
      active: false,
      transparency: 0.8,
      previousLayerTransparency: 1.0,
    },
    {
      type: "overlay",
      input: { type: "color", color: "#ff0000", texture: "None" },
      active: false,
      transparency: 0.5,
      previousLayerTransparency: 1.0,
    },
    {
      type: "overlay",
      input: { type: "color", color: "#000000", texture: "None" },
      active: false,
      transparency: 0.0,
      previousLayerTransparency: 1.0,
    },
    {
      type: "overlay",
      input: { type: "color", color: "#000000", texture: "None" },
      active: false,
      transparency: 0.0,
      previousLayerTransparency: 1.0,
    },
    {
      type: "overlay",
      input: { type: "color", color: "#000000", texture: "None" },
      active: false,
      transparency: 0.0,
      previousLayerTransparency: 1.0,
    },
    {
      type: "overlay",
      input: { type: "color", color: "#000000", texture: "None" },
      active: false,
      transparency: 0.0,
      previousLayerTransparency: 1.0,
    },
    {
      type: "overlay",
      input: { type: "color", color: "#000000", texture: "None" },
      active: false,
      transparency: 0.0,
      previousLayerTransparency: 1.0,
    },
    {
      type: "overlay",
      input: { type: "color", color: "#000000", texture: "None" },
      active: false,
      transparency: 0.0,
      previousLayerTransparency: 1.0,
    },
    {
      type: "overlay",
      input: { type: "color", color: "#000000", texture: "None" },
      active: false,
      transparency: 0.0,
      previousLayerTransparency: 1.0,
    },
    {
      type: "overlay",
      input: { type: "color", color: "#000000", texture: "None" },
      active: false,
      transparency: 0.0,
      previousLayerTransparency: 1.0,
    },
  ],

  globalCameraKeyframes: [
    {
      t: 0,
      radius: 104.34782608695652,
      height: 40,
      targetPosition: {
        x: 0,
        y: 0,
        z: 0,
      },
    },
    {
      t: 0.1,
      radius: 100,
      height: 40,
      targetPosition: {
        x: 0,
        y: 0,
        z: 0,
      },
    },
    {
      t: 0.2,
      radius: 63.04347826086957,
      height: 40,
      targetPosition: {
        x: 14.500000000000004,
        y: 0,
        z: 0,
      },
    },
    {
      t: 0.3,
      radius: 60.86956521739131,
      height: 40,
      targetPosition: {
        x: 0.1,
        y: 0,
        z: 13.700000000000001,
      },
    },
    {
      t: 0.4,
      radius: 43.47826086956522,
      height: 40,
      targetPosition: {
        x: -9.200000000000001,
        y: 0,
        z: 0,
      },
    },
    {
      t: 0.5,
      radius: 41.30434782608695,
      height: 18.478260869565215,
      targetPosition: {
        x: 0,
        y: 0,
        z: 0,
      },
    },
    {
      t: 0.6,
      radius: 43.47826086956522,
      height: 29.347826086956523,
      targetPosition: {
        x: 0,
        y: 0,
        z: 0,
      },
    },
    {
      t: 0.7,
      radius: 80.43478260869566,
      height: 36.95652173913043,
      targetPosition: {
        x: 0,
        y: 0,
        z: 0,
      },
    },
    {
      t: 0.8,
      radius: 100,
      height: 40,
      targetPosition: {
        x: 0,
        y: 0,
        z: 0,
      },
    },
    {
      t: 0.9,
      radius: 100,
      height: 40,
      targetPosition: {
        x: 0,
        y: 0,
        z: 0,
      },
    },
    {
      t: 1,
      radius: 104.34782608695652,
      height: 40,
      targetPosition: {
        x: 0,
        y: 0,
        z: 0,
      },
    },
  ],

  logGlobalCameraTime: false,
  stopStartCameraTime: false,
  startCameraTime: 0,
  velocities: {
    numSteps: 6, //10,
    fixedTimeStep: 1.1 / 360.0,
    timeSpeed: 2.0, //2.0,
    curveImpulseDt: 0.002,
    globalMaterialsDt: 0.012,
    sceneImpulseDt: 0.00015,
    circularVehicleDt: 0.009,
    tankMaterialDt: 0.002,
    cameraTransitionDt: 0.001,
    cameraUpMoveDownDt: 0.0063,
    tankCameraUpdate: 0.025,
    generalCameraUpdate: 0.043,
    textTransitionSpeed: 1.0, //0.02,
    positionMultiplier: 0.4,
    maxSpeedMultiplier: 1,
    startScreenDt: 0.0002,
  },

  velocitiesHighFPS: {
    numSteps: 4, //10,
    fixedTimeStep: 1.1 / 360.0,
    timeSpeed: 1.0, //2.0,
    curveImpulseDt: 0.002,
    globalMaterialsDt: 0.01,
    circularVehicleDt: 0.009,
    tankMaterialDt: 0.002,

    cameraTransitionDt: 0.001,
    cameraUpMoveDownDt: 0.0063,
    tankCameraUpdate: 0.025,
    generalCameraUpdate: 0.043,
    textTransitionSpeed: 1.0, //0.02,
    positionMultiplier: 0.4,
    maxSpeedMultiplier: 1,
  },

  vehicles: [
    {
      type: "TankTruck",
      path: 0,
      stopIndices: [155, 355], //[16, 36],
      interactionIndices: [0, 2],
      loopPath: true,
      initialPositionIndex: 0,
      textIndex: 1,
      rotation: { x: 0, y: 1.06, z: 0 },
      height: 10, //7.83,
      distance: 12, //5.0,
      useKeyframes: true,
      cameraKeyframes: [
        { t: 0, height: 10, distance: 12, rotationY: -0.61 },
        { t: 0.1, height: 10, distance: 12, rotationY: 1.06 },
        { t: 0.48, height: 10, distance: 12, rotationY: 1.06 },
        { t: 0.65, height: 10, distance: 12, rotationY: -0.61 },
        { t: 0.99, height: 10, distance: 12, rotationY: -0.61 },
        { t: 1, height: 10, distance: 12, rotationY: -0.61 },
      ],
    },
    {
      type: "Samosval",
      path: 1,
      stopIndices: [199, 347],
      interactionIndices: [8, 8],
      loopPath: true,
      initialPositionIndex: 0,
      textIndex: 2,
      rotation: { x: 0, y: -1.3, z: 0 },
      height: 0.65,
      distance: 2.52,
      useKeyframes: true,
      cameraKeyframes: [
        {
          t: 0,
          height: 3.9130434782608696,
          distance: 2.52,
          rotationY: -1.3,
        },
        {
          t: 0.21739130434782608,
          height: 0.65,
          distance: 2.52,
          rotationY: -1.3,
        },
        {
          t: 0.31521739130434784,
          height: 1,
          distance: 2.52,
          rotationY: 0.3414774623467167,
        },
        {
          t: 0.5543478260869565,
          height: 1,
          distance: 2.608695652173913,
          rotationY: 0.3414774623467167,
        },
        {
          t: 0.6195652173913043,
          height: 3.9130434782608696,
          distance: 4,
          rotationY: 3.005001668651107,
        },
        {
          t: 0.7065217391304348,
          height: 0.65,
          distance: 4,
          rotationY: 2.9367061761817626,
        },
        {
          t: 0.9021739130434783,
          height: 1.0869565217391304,
          distance: 4,
          rotationY: 2.9367061761817626,
        },
        {
          t: 1,
          height: 3.9130434782608696,
          distance: 2.52,
          rotationY: -1.2976143569175231,
        },
      ],
    },
    {
      type: "Med",
      path: 2,
      stopIndices: [187, 277],
      interactionIndices: [3, 3],
      loopPath: true,
      initialPositionIndex: 0,
      textIndex: 3,
      rotation: { x: 0, y: 0, z: 0 },
      height: 2.83,
      distance: 5.0,
      useKeyframes: true,
      cameraKeyframes: [
        { t: 0, height: 2.83, distance: 5.0, rotationY: 0 },
        { t: 0.33, height: 2.83, distance: 5.0, rotationY: 0 },
        { t: 0.66, height: 2.83, distance: 5.0, rotationY: 0 },
        { t: 0.98, height: 2.83, distance: 5.0, rotationY: 0 },
        { t: 0.99, height: 2.83, distance: 5.0, rotationY: 0 },
        { t: 1, height: 2.83, distance: 5.0, rotationY: 0 },
      ],
    },
    {
      type: "TankTruck2",
      path: 3,
      stopIndices: [135, 194, 250, 319], //[25, 36, 49, 75],
      interactionIndices: [7, 5, 7, 6], //[7, 5, 7, 6],
      loopPath: true,
      initialPositionIndex: 0,
      textIndex: 1,
      rotation: { x: 0, y: 1.06, z: 0 },
      height: 10, //7.83,
      distance: 12, //5.0,
      useKeyframes: false,
      cameraKeyframes: [
        { t: 0, height: 10, distance: 12, rotationY: -0.61 },
        { t: 0.1, height: 10, distance: 12, rotationY: 1.06 },
        { t: 0.48, height: 10, distance: 12, rotationY: 1.06 },
        { t: 0.65, height: 10, distance: 12, rotationY: -0.61 },
        { t: 0.99, height: 10, distance: 12, rotationY: -0.61 },
        { t: 1, height: 10, distance: 12, rotationY: -0.61 },
      ],
    },
    {
      type: "Musorovoz",
      path: 4,
      stopIndices: [90, 110, 168, 225, 305], //[19, 32, 44, 58, 81],
      interactionIndices: [3, 7, 3, 7, 3], //[3, 7, 3, 7, 3],
      loopPath: true,
      initialPositionIndex: 0,
      textIndex: 2,
      rotation: { x: 0, y: -1.3, z: 0 },
      height: 0.65,
      distance: 2.52,
      useKeyframes: true,
      cameraKeyframes: [
        {
          t: 0,
          height: 3.9130434782608696,
          distance: 2.52,
          rotationY: -1.3,
        },
        {
          t: 0.21739130434782608,
          height: 0.65,
          distance: 2.52,
          rotationY: -1.3,
        },
        {
          t: 0.31521739130434784,
          height: 1,
          distance: 2.52,
          rotationY: 0.3414774623467167,
        },
        {
          t: 0.5543478260869565,
          height: 1,
          distance: 2.608695652173913,
          rotationY: 0.3414774623467167,
        },
        {
          t: 0.6195652173913043,
          height: 3.9130434782608696,
          distance: 4,
          rotationY: 3.005001668651107,
        },
        {
          t: 0.7065217391304348,
          height: 0.65,
          distance: 4,
          rotationY: 2.9367061761817626,
        },
        {
          t: 0.9021739130434783,
          height: 1.0869565217391304,
          distance: 4,
          rotationY: 2.9367061761817626,
        },
        {
          t: 1,
          height: 3.9130434782608696,
          distance: 2.52,
          rotationY: -1.2976143569175231,
        },
      ],
    },
    {
      type: "TankTruck",
      path: 0,
      stopIndices: [155, 355], //[16, 36],
      interactionIndices: [0, 2],
      loopPath: true,
      initialPositionIndex: 156,
      textIndex: 1,
      rotation: { x: 0, y: 1.06, z: 0 },
      height: 10,
      distance: 12,
      useKeyframes: true,
      cameraKeyframes: [
        { t: 0, height: 10, distance: 12, rotationY: -0.61 },
        { t: 0.1, height: 10, distance: 12, rotationY: 1.06 },
        { t: 0.48, height: 10, distance: 12, rotationY: 1.06 },
        { t: 0.65, height: 10, distance: 12, rotationY: -0.61 },
        { t: 0.99, height: 10, distance: 12, rotationY: -0.61 },
        { t: 1, height: 10, distance: 12, rotationY: -0.61 },
      ],
    },
    {
      type: "Samosval",
      path: 1,
      stopIndices: [199, 347],
      interactionIndices: [8, 8],
      loopPath: true,
      initialPositionIndex: 195, //30,
      textIndex: 2,
      rotation: { x: 0, y: -3.14, z: 0 },
      height: 1.3,
      distance: 6.3,
      useKeyframes: true,
      cameraKeyframes: [
        {
          t: 0,
          height: 3.9130434782608696,
          distance: 2.52,
          rotationY: -1.3,
        },
        {
          t: 0.21739130434782608,
          height: 0.65,
          distance: 2.52,
          rotationY: -1.3,
        },
        {
          t: 0.31521739130434784,
          height: 1,
          distance: 2.52,
          rotationY: 0.3414774623467167,
        },
        {
          t: 0.5543478260869565,
          height: 1,
          distance: 2.608695652173913,
          rotationY: 0.3414774623467167,
        },
        {
          t: 0.6195652173913043,
          height: 3.9130434782608696,
          distance: 4,
          rotationY: 3.005001668651107,
        },
        {
          t: 0.7065217391304348,
          height: 0.65,
          distance: 4,
          rotationY: 2.9367061761817626,
        },
        {
          t: 0.9021739130434783,
          height: 1.0869565217391304,
          distance: 4,
          rotationY: 2.9367061761817626,
        },
        {
          t: 1,
          height: 3.9130434782608696,
          distance: 2.52,
          rotationY: -1.2976143569175231,
        },
      ],
    },
    {
      type: "Med",
      path: 2,
      stopIndices: [187, 277],
      interactionIndices: [3, 3],
      loopPath: true,
      initialPositionIndex: 188,
      textIndex: 3,
      rotation: { x: 0, y: 0, z: 0 },
      height: 2.83,
      distance: 5.0,
      useKeyframes: true,
      cameraKeyframes: [
        { t: 0, height: 2.83, distance: 5.0, rotationY: 0 },
        { t: 0.33, height: 2.83, distance: 5.0, rotationY: 0 },
        { t: 0.66, height: 2.83, distance: 5.0, rotationY: 0 },
        { t: 0.98, height: 2.83, distance: 5.0, rotationY: 0 },
        { t: 0.99, height: 2.83, distance: 5.0, rotationY: 0 },
        { t: 1, height: 2.83, distance: 5.0, rotationY: 0 },
      ],
    },
    {
      type: "TankTruck2",
      path: 3,
      stopIndices: [135, 194, 250, 319], //[25, 36, 49, 75],
      interactionIndices: [7, 5, 7, 6], //[7, 5, 7, 6],
      loopPath: true,
      initialPositionIndex: 195,
      textIndex: 1,
      rotation: { x: 0, y: 1.06, z: 0 },
      height: 10, //7.83,
      distance: 12, //5.0,
      useKeyframes: true,
      cameraKeyframes: [
        { t: 0, height: 10, distance: 12, rotationY: -0.61 },
        { t: 0.1, height: 10, distance: 12, rotationY: 1.06 },
        { t: 0.48, height: 10, distance: 12, rotationY: 1.06 },
        { t: 0.65, height: 10, distance: 12, rotationY: -0.61 },
        { t: 0.99, height: 10, distance: 12, rotationY: -0.61 },
        { t: 1, height: 10, distance: 12, rotationY: -0.61 },
      ],
    },
    {
      type: "Musorovoz",
      path: 4,
      stopIndices: [90, 110, 168, 225, 305], //[19, 32, 44, 58, 81],
      interactionIndices: [3, 7, 3, 7, 3], //[3, 7, 3, 7, 3],
      loopPath: true,
      initialPositionIndex: 169,
      textIndex: 2,
      rotation: { x: 0, y: -1.3, z: 0 },
      height: 0.65,
      distance: 2.52,
      useKeyframes: true,
      cameraKeyframes: [
        {
          t: 0,
          height: 3.9130434782608696,
          distance: 2.52,
          rotationY: -1.3,
        },
        {
          t: 0.21739130434782608,
          height: 0.65,
          distance: 2.52,
          rotationY: -1.3,
        },
        {
          t: 0.31521739130434784,
          height: 1,
          distance: 2.52,
          rotationY: 0.3414774623467167,
        },
        {
          t: 0.5543478260869565,
          height: 1,
          distance: 2.608695652173913,
          rotationY: 0.3414774623467167,
        },
        {
          t: 0.6195652173913043,
          height: 3.9130434782608696,
          distance: 4,
          rotationY: 3.005001668651107,
        },
        {
          t: 0.7065217391304348,
          height: 0.65,
          distance: 4,
          rotationY: 2.9367061761817626,
        },
        {
          t: 0.9021739130434783,
          height: 1.0869565217391304,
          distance: 4,
          rotationY: 2.9367061761817626,
        },
        {
          t: 1,
          height: 3.9130434782608696,
          distance: 2.52,
          rotationY: -1.2976143569175231,
        },
      ],
    },
    {
      type: "Taxi",
      path: 5,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 0,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 5,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 70,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 5,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 140,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 6,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 0,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 6,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 70,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 6,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 140,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 7,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 70,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 7,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 140,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 7,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 0,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 8,
      stopIndices: [120, 255],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 0,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 8,
      stopIndices: [120, 255],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 100,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 8,
      stopIndices: [120, 255],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 180,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 9,
      stopIndices: [100, 255],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 0,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 9,
      stopIndices: [100, 255],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 100,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 9,
      stopIndices: [100, 255],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 180,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 10,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 0,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 10,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 70,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 10,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 140,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 11,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 0,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 11,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 70,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
    {
      type: "Taxi",
      path: 11,
      stopIndices: [100, 191],
      interactionIndices: [4, 4],
      loopPath: true,
      initialPositionIndex: 140,
      rotation: { x: 0, y: 0, z: 0 },
      height: 0.5,
      distance: 5.0,
    },
  ],

  interactions: [
    [
      {
        type: "materialImpulse",
        center: "vehicle",
        nextInteractionStart: "now",
      },
      {
        type: "cameraTransition",
        cameraAnimationType: "tankCameraTransition",
        nextInteractionStart: "now",
        vehicle: 0,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_OilStorage_01-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_OilStorage_02-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_OilStorage_03-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_OilStorage_04-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_OilStorage_05-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_OilStorage_06-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_Storage_02-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_Column_02-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_Column_01-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_Column_03-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_GasStorage_01",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_GasStorage_02",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "tankAnimation",
        up: true,
        nextInteractionStart: "wait",
      },
      {
        type: "materialImpulse",
        center: "vehicle",
        nextInteractionStart: "wait",
      },
      { type: "continueMovement" },
    ],
    [{ type: "materialImpulse", center: "vehicle" }],
    [
      {
        type: "materialImpulse",
        center: "vehicle",
        nextInteractionStart: "now",
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_02_Column_01-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: true,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_02_Column_02-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: true,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_02_Column_03-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: true,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_02_Column_04-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: true,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_02_GasSphere_01-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: true,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_02_GasSphere_02-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: true,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_02_GasSphere_03-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: true,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Factory_02_GasSphere_04-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: true,
      },
      { type: "tankAnimation", up: false, nextInteractionStart: "wait" },
      {
        type: "materialImpulse",
        center: "vehicle",
        nextInteractionStart: "wait",
      },
      { type: "continueMovement" },
    ],
    [
      {
        type: "materialImpulse",
        center: "vehicle",
        nextInteractionStart: "wait",
      },
      { type: "continueMovement" },
    ],
    [{ type: "continueMovement" }],
    [
      {
        type: "materialImpulse",
        center: "vehicle",
        nextInteractionStart: "now",
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Septic_Pool_01-BASE__White",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: true,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Septic_Pool_02-BASE__White",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: true,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Septic_Pool_03-BASE__White",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: true,
      },
      { type: "tankAnimation", up: false, nextInteractionStart: "wait" },
      {
        type: "materialImpulse",
        center: "vehicle",
        nextInteractionStart: "wait",
      },
      { type: "continueMovement" },
    ],
    [
      {
        type: "materialImpulse",
        center: "vehicle",
        nextInteractionStart: "now",
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Septic_Tank_02-BASE__Blue",
        offset1: { x: 0.0, y: 0.3, z: 0 },
        offset2: { x: -3.2, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Septic_Tank_02-BASE__Blue",
        offset1: { x: 0.0, y: 0.3, z: 0 },
        offset2: { x: -1.5, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Septic_Tank_02-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 1.0, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      {
        type: "curveImpulse",
        position1: "vehicle",
        position2: "A_Septic_Tank_02-BASE__Blue",
        offset1: { x: 0, y: 0.3, z: 0 },
        offset2: { x: 2.7, y: 0, z: 0 },
        nextInteractionStart: "now",
        reverse: false,
      },
      { type: "tankAnimation", up: false, nextInteractionStart: "wait" },
      {
        type: "materialImpulse",
        center: "vehicle",
        nextInteractionStart: "wait",
      },
      { type: "continueMovement" },
    ],
    [{ type: "waitTrain" }, { type: "continueMovement" }],
    [
      {
        type: "materialImpulse",
        center: "vehicle",
        nextInteractionStart: "wait",
      },
      {
        type: "bricksInteraction",
        nextInteractionStart: "wait",
      },
      { type: "continueMovement" },
    ],
  ],

  carTypes: {
    Samosval: {
      bodyOffset: { x: 0, y: -0.06, z: 0 },
      wheelOffsets: {
        w0: { x: 0, y: 0, z: 0 },
        w1: { x: 0, y: 0, z: 0 },
        w2: { x: 0, y: 0, z: 0 },
        w3: { x: 0, y: 0, z: 0 },
        w4: { x: 0, y: 0, z: 0 },
        w5: { x: 0, y: 0, z: 0 },
      },
    },

    TankTruck: {
      bodyOffset: { x: 0, y: -0.257, z: 0 },
      wheelOffsets: {
        w0: { x: 0.0, y: 0, z: 0.0 },
        w1: { x: 0.0, y: 0, z: 0.0 },
        w2: { x: 0.0, y: 0, z: 0.0 },
        w3: { x: 0.0, y: 0, z: 0.0 },
        w4: { x: 0, y: 0, z: 0 },
        w5: { x: 0, y: 0, z: 0 },
        w6: { x: 0, y: 0, z: 0 },
        w7: { x: 0, y: 0, z: 0 },
        w8: { x: 0, y: 0, z: 0 },
        w9: { x: 0, y: 0, z: 0 },
      },
    },
    Musorovoz: {
      bodyOffset: { x: 0, y: -0.08, z: 0 },
      wheelOffsets: {
        w0: { x: 0, y: 0, z: 0 },
        w1: { x: 0, y: 0, z: 0 },
        w2: { x: 0, y: 0, z: 0 },
        w3: { x: 0, y: 0, z: 0 },
        w4: { x: 0, y: 0, z: 0 },
        w5: { x: 0, y: 0, z: 0 },
      },
    },
    TankTruck2: {
      bodyOffset: { x: 0, y: -0.08, z: 0 },
      wheelOffsets: {
        w0: { x: 0, y: 0, z: 0 },
        w1: { x: 0, y: 0, z: 0 },
        w2: { x: 0, y: 0, z: 0 },
        w3: { x: 0, y: 0, z: 0 },
        w4: { x: 0, y: 0, z: 0 },
        w5: { x: 0, y: 0, z: 0 },
      },
    },
    Med: {
      bodyOffset: { x: 0, y: 0.0, z: 0 },
      wheelOffsets: {
        w0: { x: 0, y: 0, z: 0 },
        w1: { x: 0, y: 0, z: 0 },
        w2: { x: 0, y: 0, z: 0 },
        w3: { x: 0, y: 0, z: 0 },
      },
    },
    Car1: {
      bodyOffset: { x: 0, y: 0.0, z: 0 },
      wheelOffsets: {
        w0: { x: 0, y: 0, z: 0 },
        w1: { x: 0, y: 0, z: 0 },
        w2: { x: 0, y: 0, z: 0 },
        w3: { x: 0, y: 0, z: 0 },
      },
    },
    Taxi: {
      bodyOffset: { x: 0, y: 0.0, z: 0 },
      wheelOffsets: {
        w0: { x: 0, y: 0, z: 0 },
        w1: { x: 0, y: 0, z: 0 },
        w2: { x: 0, y: 0, z: 0 },
        w3: { x: 0, y: 0, z: 0 },
      },
    },
  },
};

const nonUpdateNames = [
  "OilInsideIntensity",
  "OilOutsideIntensity",
  "PathBeforeIntensity",
  "PathAfterIntensity",
];

Object.keys(updateFile).forEach((key) => {
  if (nonUpdateNames.indexOf(key) === -1) state[key] = updateFile[key];
});
// console.log(updateFile);

export function resetState() {
  state.startScreenCamera = true;
  state.cameraUp = true;
  state.cameraTransition = null;
  state.cameraTransitionTime = 0;

  state.startCameraTime = 0;
  state.vehicleToFollow = 0;
}

export function setRenderer(renderer) {
  state.renderer = renderer;
  state.OilInsideIntensity = renderer == "composer" ? 90 : 1;
  state.OilOutsideIntensity = renderer == "composer" ? 90 : 1;
  state.PathBeforeIntensity = renderer == "composer" ? 90 : 1;
  state.PathAfterIntensity = renderer == "composer" ? 90 : 1;
}

export default () => {
  return state;
};
