//import CANNON, { Quaternion } from "cannon";

import CANNON from "../thirdparty/cannon.js/src/Cannon";
import * as THREE from "three";
import matcapMaterial from "./materials/matcapMaterial";
import { sendImpulse } from "./managers/impulseManager";
import { startAnimation } from "./managers/dynamicMaterialsManager";
import getState from "./state";
import { addInteractionSequence } from "./managers/interactionManager";
import { addDynamicMaterial } from "./managers/dynamicMaterialsManager";
import { createPhysicalVehicle } from "./managers/workerManager";
import { typeInfo } from "./vehicleTypes";

let vehicleTime = 0;
let startTime = 0;

const state = getState();
let BodyQuaternion = new CANNON.Quaternion();
let wheelQuaternion = new THREE.Quaternion();
let directionWorld = new CANNON.Vec3();
let normalWheelWorld = new CANNON.Vec3();
let directionToPoint = new THREE.Vector3();
let dottedV = new CANNON.Vec3();
let forward = new THREE.Vector3(1, 0, 0);
let worldForward = new THREE.Vector3(0, 0, -1);

const offsetVector = new THREE.Vector3(0, 0, 0);

//let deltaTime = 0.0001;

let numVehicle = 0;

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

let currentOffsetPositions = 0;
let currentOffsetQuaternions = 0;

class VehicleMesh {
  constructor(type, assets, material, scene) {
    let boundingSphereRadius = 0;

    //addDynamicMaterial(material);
    this.material = material;
    this.material.defines["CAR"] = true;

    material.transparent = true;
    //material.blending = THREE.AdditiveBlending;

    this.name = "v" + numVehicle;
    this.num = numVehicle;
    numVehicle++;

    this.type = type;
    this.innerTime = 0;
    this.deltaTime = 0.01;
    this.regime = "CurveFollow"; //"FreeRide" | "CurveFollow"
    this.scene = scene;

    this.nWheels = typeInfo[type].nWheels;
    this.height = typeInfo[type].height;

    this.offsetPositions = currentOffsetPositions;
    this.offsetQuaternions = currentOffsetQuaternions;

    currentOffsetPositions += 3 + this.nWheels * 3;
    currentOffsetQuaternions += 4 + this.nWheels * 4;

    assets[type].traverse((child) => {
      if (
        child.isMesh &&
        child.geometry.boundingSphere.radius > boundingSphereRadius
      )
        boundingSphereRadius = child.geometry.boundingSphere.radius;
    });

    this.chassisMesh = new THREE.Mesh(
      new THREE.BoxGeometry(
        boundingSphereRadius * typeInfo[type].chassisShapeCoefficients.x,
        boundingSphereRadius * typeInfo[type].chassisShapeCoefficients.y,
        boundingSphereRadius * typeInfo[type].chassisShapeCoefficients.z,
      ),
      new THREE.MeshBasicMaterial({ color: 0xff0000 }),
    );

    this.Bbox = new THREE.Mesh(
      new THREE.BoxGeometry(
        boundingSphereRadius * 3,
        boundingSphereRadius * 3,
        boundingSphereRadius * 3,
      ),
      new THREE.MeshBasicMaterial({ color: 0xff0000 }),
    );
    this.Bbox.visible = false;
    this.Bbox.name = this.name;

    scene.add(this.Bbox);

    //scene.add(this.chassisMesh);

    // Create the vehicle
    const vehicle = this.vehicle;
    let wheelRadius = 0;
    assets[type].traverse((child) => {
      if (
        child.isMesh &&
        child.name.slice(0, 5) === "Wheel" &&
        child.geometry.boundingSphere.radius > wheelRadius
      )
        wheelRadius = child.geometry.boundingSphere.radius;
    });

    this.wheelRadius = wheelRadius;
    this.boundingSphereRadius = boundingSphereRadius;

    let wheelBodies = [];
    let cylinders = [];
    let wheels = [];
    let wheelCounter = 0;

    //this.body = [];
    this.body = new THREE.Object3D();
    this.loader = null;

    this.position = new THREE.Vector3();
    this.quaternion = new THREE.Quaternion();
    this.velocity = new THREE.Vector3();
    this.timeToSet = 0;

    this.targetQuaternion = new THREE.Quaternion();
    this.wheelsPositions = [];
    this.wheelsQuaternions = [];

    for (let i = 0; i < this.nWheels; i++) {
      this.wheelsPositions.push(new THREE.Vector3());
      this.wheelsQuaternions.push(new THREE.Quaternion());
    }

    assets[type].children.forEach((o) => {
      //if (!o.isMesh) return;

      o.traverse((m) => {
        if (!m.isMesh) return;
        if (material) m.material = material;

        if (m.name == "Tank" || m.name == "Tank001") {
          m.material = material.clone();
          m.material.defines["TANK"] =
            o.name == "Tank" || o.name == "Tank001" ? true : false;
          m.material.uniforms.tankT.value =
            this.num === 0 || this.num === 3 ? 0 : 1;
          this.tankMaterial = m.material;
          this.tankMaterial.defines["CAR"] = true;
          //addDynamicMaterial(o.material);
        }

        m.material.side = THREE.DoubleSide;
        m.castShadow = true; //default is false
        m.receiveShadow = true;
      });

      if (o.name.slice(0, 5) === "Wheel") {
        const position = typeInfo[type].wheelPositions[wheelCounter];
        const geometry = new THREE.CylinderGeometry(
          wheelRadius,
          wheelRadius,
          wheelRadius / 2,
          20,
        );
        const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
        const cylinder = new THREE.Mesh(geometry, material);
        //cylinder.quaternion.copy(q);

        //cylinders.push(cylinder);
        const wheel = o.clone();
        wheel.material = this.material;
        wheels.push(wheel);

        wheelCounter++;
      } else {
        const m = o.clone();
        if (m.name == "LoaderHandle") this.loader = m;
        this.body.add(m);
      }
    });

    scene.add(this.body);
    wheels.forEach((w) => {
      scene.add(w);
    });
    this.wheels = wheels;

    this.distanceVectors = [];
    this.distances = [];
    for (let i = 0; i < state.vehicles.length; i++) {
      this.distanceVectors.push(new THREE.Vector3());
      this.distances.push(1000000);
    }
  }

  resetTime() {
    this.innerTime = this.initialTime;
  }

  perFrameUpdate(vehiclePositions, vehicles) {
    let { path, body, offsetPositions, offsetQuaternions } = this;

    this.velocity.copy(this.position);
    this.velocity.sub(body.position);
    this.velocity.normalize();

    vehiclePositions.forEach((p, index) => {
      this.distanceVectors[index].copy(p).sub(body.position);
      this.distances[index] = this.distanceVectors[index].length();
    });

    let l = 3.3; //10000;
    let index = 100;
    for (let i = 0; i < this.distances.length; i++) {
      if (i !== this.num && this.distances[i] < l) {
        l = this.distances[i];
        index = i;
      }
    }

    if (
      this.num > index &&
      this.velocity.dot(vehicles[index].velocity) > -0.8
    ) {
      this.material.uniforms.carIntersectionDelayed.value +=
        0.008 * (l - this.material.uniforms.carIntersectionDelayed.value);
      this.material.uniforms.carIntersection.value +=
        0.1 * (l - this.material.uniforms.carIntersection.value);
    } else {
      this.material.uniforms.carIntersectionDelayed.value +=
        0.008 * (3.3 - this.material.uniforms.carIntersectionDelayed.value);
      this.material.uniforms.carIntersection.value +=
        0.1 * (1.3 - this.material.uniforms.carIntersection.value);
    }

    this.material.uniforms.leftdir.value.set(0, 1, 0);
    this.material.uniforms.leftdir.value.applyQuaternion(body.quaternion);

    if (this.tankMaterial) {
      this.tankMaterial.uniforms.carIntersectionDelayed.value =
        this.material.uniforms.carIntersectionDelayed.value;
      this.tankMaterial.uniforms.carIntersection.value =
        this.material.uniforms.carIntersection.value;
      this.tankMaterial.uniforms.leftdir.value.copy(
        this.material.uniforms.leftdir.value,
      );
    }

    // if (l < 2.0) this.material.blending = THREE.AdditiveBlending;
    // else this.material.blending = THREE.NormalBlending;

    let factor = 0.05;
    for (let i = 0; i < this.nWheels; i++) {
      // this.wheels[i].position.copy(this.wheelsPositions[i]);
      // this.wheels[i].quaternion.copy(this.wheelsQuaternions[i]);
      this.wheels[i].position.x +=
        factor * (this.wheelsPositions[i].x - this.wheels[i].position.x);
      this.wheels[i].position.y +=
        factor * (this.wheelsPositions[i].y - this.wheels[i].position.y);
      this.wheels[i].position.z +=
        factor * (this.wheelsPositions[i].z - this.wheels[i].position.z);

      this.wheels[i].quaternion.slerp(this.wheelsQuaternions[i], 0.3);
      // this.wheels[i].quaternion.x +=
      //   1 * (this.wheelsQuaternions[i].x - this.wheels[i].quaternion.x);
      // this.wheels[i].quaternion.y +=
      //   1 * (this.wheelsQuaternions[i].y - this.wheels[i].quaternion.y);
      // this.wheels[i].quaternion.z +=
      //   1 * (this.wheelsQuaternions[i].z - this.wheels[i].quaternion.z);
      // this.wheels[i].quaternion.w +=
      //   1 * (this.wheelsQuaternions[i].w - this.wheels[i].quaternion.w);
    }

    factor = 0.05;

    // body.position.copy(this.position);
    // body.quaternion.copy(this.quaternion);
    body.position.x += factor * (this.position.x - body.position.x);
    body.position.y += factor * (this.position.y - body.position.y);
    body.position.z += factor * (this.position.z - body.position.z);

    body.quaternion.x += factor * (this.quaternion.x - body.quaternion.x);
    body.quaternion.y += factor * (this.quaternion.y - body.quaternion.y);
    body.quaternion.z += factor * (this.quaternion.z - body.quaternion.z);
    body.quaternion.w += factor * (this.quaternion.w - body.quaternion.w);

    this.innerTime = this.timeToSet;

    if (path) {
      if (this.num == state.focusVehicle)
        path.cylMesh.material.uniforms.insideTime.value = this.innerTime;
      //curveObject.setLowerTimeLittleCylinder(this.innerTime);
      path.setLowerTimeBigCylinder(0.0);
    }
  }

  updateVehicleSceneMeshes(positions, quaternions, times) {
    let { path, body, offsetPositions, offsetQuaternions } = this;
    this.position.set(
      positions[offsetPositions],
      positions[offsetPositions + 1],
      positions[offsetPositions + 2],
    );
    this.quaternion.set(
      quaternions[offsetQuaternions],
      quaternions[offsetQuaternions + 1],
      quaternions[offsetQuaternions + 2],
      quaternions[offsetQuaternions + 3],
    );

    for (let i = 0; i < this.nWheels; i++) {
      this.wheelsPositions[i].set(
        positions[offsetPositions + (i + 1) * 3],
        positions[offsetPositions + (i + 1) * 3 + 1],
        positions[offsetPositions + (i + 1) * 3 + 2],
      );
      this.wheelsQuaternions[i].set(
        quaternions[offsetQuaternions + (i + 1) * 4],
        quaternions[offsetQuaternions + (i + 1) * 4 + 1],
        quaternions[offsetQuaternions + (i + 1) * 4 + 2],
        quaternions[offsetQuaternions + (i + 1) * 4 + 3],
      );
    }

    this.timeToSet = times[this.num];

    // let { path, body, offsetPositions, offsetQuaternions } = this;
    //
    // for (let i = 0; i < this.nWheels; i++) {
    //   this.wheels[i].position.set(
    //     positions[offsetPositions + (i + 1) * 3],
    //     positions[offsetPositions + (i + 1) * 3 + 1],
    //     positions[offsetPositions + (i + 1) * 3 + 2],
    //   );
    //   this.wheels[i].quaternion.set(
    //     quaternions[offsetQuaternions + (i + 1) * 4],
    //     quaternions[offsetQuaternions + (i + 1) * 4 + 1],
    //     quaternions[offsetQuaternions + (i + 1) * 4 + 2],
    //     quaternions[offsetQuaternions + (i + 1) * 4 + 3],
    //   );
    // }
    //
    // body.position.set(
    //   positions[offsetPositions],
    //   positions[offsetPositions + 1],
    //   positions[offsetPositions + 2],
    // );
    // body.quaternion.set(
    //   quaternions[offsetQuaternions],
    //   quaternions[offsetQuaternions + 1],
    //   quaternions[offsetQuaternions + 2],
    //   quaternions[offsetQuaternions + 3],
    // );
    //
    // this.innerTime = times[this.num];
    //
    // if (path) {
    //   if (this.num == state.focusVehicle)
    //     path.cylMesh.material.uniforms.insideTime.value = this.innerTime;
    //   //curveObject.setLowerTimeLittleCylinder(this.innerTime);
    //   path.setLowerTimeBigCylinder(0.0);
    // }
  }

  setPath(inCurve, initialPositionIndex) {
    this.initialTime = initialPositionIndex
      ? initialPositionIndex / inCurve.positions.length
      : 0.0;

    this.innerTime = this.initialTime;
    this.path = inCurve;
    // this.path.cylMesh.renderOrder = 105.0;
  }

  getVehicleDirection(target) {
    target.set(1.0, 0.0, 0.0);
    target.applyQuaternion(this.body.quaternion);
  }

  setInnerTime(t) {
    this.innerTime = t;
  }
}

export function setDefaultNum() {
  numVehicle = 0;
  currentOffsetPositions = 0;
  currentOffsetQuaternions = 0;
}

export default VehicleMesh;
