import * as THREE from "three";
import curveMaterial from "./materials/curveMaterial";
import getState from "./state";
import { getAssets } from "./loader";
const N_SEGMENTS = 800;

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
const point = new THREE.Vector3();
const dir = new THREE.Vector3();
const state = getState();
const assetsData = getAssets();

class Curve {
  constructor(positions, scene, dt, toDie, nSegments, reverseImpulse) {
    this.scene = scene;
    this.N = positions.length;
    this.splineHelperObjects = [];
    this.nSegments = nSegments ? nSegments : N_SEGMENTS;
    for (let i = 0; i < this.N; i++) {
      this.addSplineObject(positions[i]);
    }

    this.positions = this.splineHelperObjects.map((o) => o.position);
    this.curve = new THREE.CatmullRomCurve3(this.positions);

    this.points = this.curve.getPoints(this.nSegments - 1);
    const geometry = new THREE.BufferGeometry().setFromPoints(this.points);

    const material = new THREE.LineBasicMaterial({
      color: 0x00ff00,
    });

    // Create the final object to add to the scene
    this.curveMesh = new THREE.Line(geometry, material);
    //this.scene.add(this.curveMesh);
    this.raycaster = new THREE.Raycaster();
    this.pointer = new THREE.Vector2();
    this.time = 0;
    this.upperTime = dt ? 0.0 : 1.0;
    this.lowerTime = dt ? 0.0 : 1.0;
    this.dt = dt ? dt : 0.0;
    this.alive = true;
    this.toDie = toDie ? toDie : false;
    this.reverse = reverseImpulse;
    this.path = false;
  }

  makePath() {
    this.path = true;
    this.cylMesh.material.defines["PATH"] = true;
  }

  addPoint(position) {
    this.N += 1;
    let object = this.addSplineObject(position);
    this.positions.push(object.position);

    this.updateSplineOutline();
  }

  addCylinderMesh(scene, matcap, matcap2, smallWidth) {
    this.bakeCurveShapeTexture();
    this.cylGeometry = new THREE.CylinderGeometry(1, 1, 1, 24, this.nSegments);
    //this.cylGeometry = new THREE.PlaneGeometry(1, 1, 6, this.nSegments);
    this.cylMesh = new THREE.Mesh(
      this.cylGeometry,
      curveMaterial(this.curveTexture, matcap, 0.025), //new THREE.MeshBasicMaterial({ color: 0x00ff00 })
    );

    this.cylMesh.frustumCulled = false;
    this.cylMesh.renderOrder = 4;
    this.cylMesh.position.y = -0.065;

    scene.add(this.cylMesh);

    if (matcap2) {
      this.cylMesh2 = new THREE.Mesh(
        this.cylGeometry,
        curveMaterial(
          this.curveTexture,
          matcap2,
          smallWidth ? smallWidth : 0.015,
        ), //new THREE.MeshBasicMaterial({ color: 0x00ff00 })
      );

      this.cylMesh2.frustumCulled = false;
      this.cylMesh2.renderOrder = 5;
      scene.add(this.cylMesh2);
    }

    this.setUpMeshMaterial();
  }

  setUpMeshMaterial() {
    const curveMaterial1 = this.cylMesh.material;
    const curveMaterial2 = this.cylMesh2 ? this.cylMesh2.material : null;

    curveMaterial1.defines["NO_TEXTURE"] = state.OilOutsideMatCap === "none";
    curveMaterial1.defines["NORMAL_TARGET"] =
      state.GTAO !== "off" && state.renderer === "composer";
    curveMaterial1.defines["FINAL_OUTPUT"] = state.renderer !== "composer";
    curveMaterial1.defines["TONEMAP"] =
      state.ToneMapping !== "NONE" && state.renderer !== "composer";
    curveMaterial1.defines["TONEMAP_SIMPLE"] =
      state.ToneMapping === "TONEMAP_SIMPLE";
    curveMaterial1.defines["TONEMAP_REINHARD"] =
      state.ToneMapping === "TONEMAP_REINHARD";
    curveMaterial1.defines["TONEMAP_REINHARD_LUMA"] =
      state.ToneMapping === "TONEMAP_REINHARD_LUMA";
    curveMaterial1.defines["TONEMAP_REINHARD_WHITE"] =
      state.ToneMapping === "TONEMAP_REINHARD_WHITE";
    curveMaterial1.defines["TONEMAP_FILMIC"] =
      state.ToneMapping === "TONEMAP_FILMIC";
    curveMaterial1.defines["TONEMAP_PHOTOGRAPHIC"] =
      state.ToneMapping === "TONEMAP_PHOTOGRAPHIC";
    curveMaterial1.defines["TONEMAP_UNCHARTED"] =
      state.ToneMapping === "TONEMAP_UNCHARTED";

    curveMaterial1.uniforms.matCap.value =
      state.OilOutsideMatCap === "none"
        ? null
        : assetsData[state.OilOutsideMatCap];
    //curveMaterial1.uniforms.width.value = state.pathWidth
    curveMaterial1.uniforms.intensity.value = state.OilOutsideIntensity;
    curveMaterial1.uniforms.toneMappingExposure.value =
      state.ToneMappingExposure;
    curveMaterial1.uniforms.curveColor.value = new THREE.Color(
      state.OilOutsideColor,
    );

    if (curveMaterial2) {
      curveMaterial2.defines["NO_TEXTURE"] = state.OilInsideMatCap === "none";
      curveMaterial2.defines["NORMAL_TARGET"] =
        state.GTAO !== "off" && state.renderer === "composer";
      curveMaterial2.defines["FINAL_OUTPUT"] = state.renderer !== "composer";
      curveMaterial2.defines["TONEMAP"] =
        state.ToneMapping !== "NONE" && state.renderer !== "composer";
      curveMaterial2.defines["TONEMAP_SIMPLE"] =
        state.ToneMapping === "TONEMAP_SIMPLE";
      curveMaterial2.defines["TONEMAP_REINHARD"] =
        state.ToneMapping === "TONEMAP_REINHARD";
      curveMaterial2.defines["TONEMAP_REINHARD_LUMA"] =
        state.ToneMapping === "TONEMAP_REINHARD_LUMA";
      curveMaterial2.defines["TONEMAP_REINHARD_WHITE"] =
        state.ToneMapping === "TONEMAP_REINHARD_WHITE";
      curveMaterial2.defines["TONEMAP_FILMIC"] =
        state.ToneMapping === "TONEMAP_FILMIC";
      curveMaterial2.defines["TONEMAP_PHOTOGRAPHIC"] =
        state.ToneMapping === "TONEMAP_PHOTOGRAPHIC";
      curveMaterial2.defines["TONEMAP_UNCHARTED"] =
        state.ToneMapping === "TONEMAP_UNCHARTED";
      curveMaterial2.uniforms.matCap.value =
        state.OilInsideMatCap === "none"
          ? null
          : assetsData[state.OilInsideMatCap];
      //curveMaterial2.uniforms.width.value = state.pathWidth
      curveMaterial2.uniforms.intensity.value = state.OilInsideIntensity;
      curveMaterial2.uniforms.toneMappingExposure.value =
        state.ToneMappingExposure;
      curveMaterial2.uniforms.curveColor.value = new THREE.Color(
        state.OilInsideColor,
      );
    }
  }

  setCylinderVisibility(visibility) {
    this.cylMesh.visible = visibility;
    if (this.cylMesh2) this.cylMesh2.visible = visibility;
  }

  bakeCurveShapeTexture() {
    const width = this.nSegments + 1;
    const height = 2;

    const size = width * height;
    const data = new Float32Array(4 * size);
    const color = new THREE.Color(0xffffff);

    for (let i = 0; i < width - 1; i++) {
      const stride = i * 4;

      this.curve.getPoint(i / width, point);
      data[stride] = point.x;
      data[stride + 1] = point.y;
      data[stride + 2] = point.z;
      data[stride + 3] = 1.0;
    }

    this.curve.getPoint(1, point);
    data[(width - 1) * 4] = point.x;
    data[(width - 1) * 4 + 1] = point.y;
    data[(width - 1) * 4 + 2] = point.z;
    data[(width - 1) * 4 + 3] = 1.0;

    for (let i = 0; i < width; i++) {
      const stride = 4 * width + i * 4;

      this.curve.getPoint(i / width, point);
      this.curve.getPoint((i + 1) / width, dir);

      dir.sub(point);
      dir.normalize();

      const q = new THREE.Quaternion();
      q.setFromUnitVectors(new THREE.Vector3(0, 1, 0), dir);
      q.normalize();

      data[stride] = q.x;
      data[stride + 1] = q.y;
      data[stride + 2] = q.z;
      data[stride + 3] = q.w;
    }
    const stride = 4 * width + (width - 1) * 4;

    this.curve.getPoint(1, point);
    this.curve.getPoint(1 / width, dir);

    dir.sub(point);
    dir.normalize();

    const q = new THREE.Quaternion();
    q.setFromUnitVectors(new THREE.Vector3(0, 1, 0), dir);
    q.normalize();

    data[stride] = q.x;
    data[stride + 1] = q.y;
    data[stride + 2] = q.z;
    data[stride + 3] = q.w;
    // used the buffer to create a DataTexture
    this.curveTexture = new THREE.DataTexture(data, width, height);
    this.curveTexture.type = THREE.FloatType;
    this.curveTexture.minFilter = THREE.LinearFilter;
    this.curveTexture.magFilter = THREE.LinearFilter;
    this.curveTexture.needsUpdate = true;
  }

  updateSplineOutline() {
    const position = this.curveMesh.geometry.attributes.position;

    for (let i = 0; i < this.nSegments; i++) {
      const t = i / (this.nSegments - 1);
      this.curve.getPoint(t, point);
      position.setXYZ(i, point.x, point.y, point.z);
    }

    position.needsUpdate = true;
    this.bakeCurveShapeTexture();
    this.cylMesh.material.uniforms.curveTexture.value = this.curveTexture;
    if (this.cylMesh2)
      this.cylMesh2.material.uniforms.curveTexture.value = this.curveTexture;
  }

  addSplineObject(position) {
    const material = new THREE.MeshBasicMaterial({
      color: Math.random() * 0xffffff,
    });
    const object = new THREE.Mesh(
      new THREE.BoxGeometry(0.05, 0.05, 0.05),
      material,
    );

    if (position) {
      object.position.copy(position);
    } else {
      object.position.x = Math.random() * 1000 - 500;
      object.position.y = Math.random() * 600;
      object.position.z = Math.random() * 800 - 400;
    }

    //object.position.y = 0.2;

    this.scene.add(object);
    this.splineHelperObjects.push(object);
    object.visible = state.visibleHelperObjects;
    return object;
  }

  setUpperTime(t) {
    this.upperTime = t;
    if (this.cylMesh) this.cylMesh.material.uniforms.upperTime.value = t;
    if (this.cylMesh2) this.cylMesh2.material.uniforms.upperTime.value = t;
  }

  setLowerTime(s) {
    this.lowerTime = s;

    if (this.cylMesh) this.cylMesh.material.uniforms.lowerTime.value = s;
    if (this.cylMesh2) this.cylMesh2.material.uniforms.lowerTime.value = s;
  }
  setLowerTimeLittleCylinder(s) {
    if (this.cylMesh2) this.cylMesh2.material.uniforms.lowerTime.value = s;
  }

  setLowerTimeBigCylinder(s) {
    if (this.cylMesh) this.cylMesh.material.uniforms.lowerTime.value = s;
  }

  onPointerMove(event, camera, transformControl) {
    let { raycaster, pointer, splineHelperObjects } = this;

    pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(pointer, camera);

    const intersects = raycaster.intersectObjects(splineHelperObjects, false);

    if (intersects.length > 0) {
      const object = intersects[0].object;

      if (object !== transformControl.object) {
        transformControl.attach(object);
      }
    }
  }

  update() {
    this.upperTime += this.dt;
    if (this.cylMesh) {
      this.cylMesh.material.uniforms.upperTime.value = this.upperTime;
    }
    if (this.cylMesh2)
      this.cylMesh2.material.uniforms.upperTime.value = this.upperTime;
  }
  updateImpulse() {
    if (this.reverse) {
      this.updateImpulseReverse();
    } else {
      this.updateImpulseForward();
    }
  }
  updateImpulseReverse() {
    this.time += this.dt;
    if (this.time <= 0.2) {
      this.upperTime += 5 * this.dt;
      //if (this.upperTime > 0.4) this.lowerTime += 5 * this.dt;
    } else if (this.time > 0.8) {
      this.upperTime -= 5 * this.dt;
      //if (this.lowerTime < 0.6) this.upperTime -= 5 * this.dt;
    }
    this.lowerTime = clamp(this.lowerTime, 0, 1);
    this.upperTime = clamp(this.upperTime, 0, 1);

    if (this.cylMesh) {
      this.cylMesh.material.uniforms.upperTime.value = this.upperTime;
      this.cylMesh.material.uniforms.lowerTime.value = this.lowerTime;
      this.cylMesh.material.uniforms.insideTime.value = 10.0;
    }

    const oilLowerTime =
      this.time > 0.8
        ? 1.0 - clamp((0.9 - this.time) / (0.9 - 0.8), 0, 1)
        : 0.0;

    const oilUpperTime =
      this.time < 0.1
        ? 0.0
        : 1.0 - clamp((0.2 - this.time) / (0.2 - 0.1), 0, 1);

    if (this.cylMesh2) {
      this.cylMesh2.material.uniforms.upperTime.value = oilUpperTime;
      this.cylMesh2.material.uniforms.lowerTime.value = oilLowerTime;
      this.cylMesh2.material.uniforms.insideTime.value = oilLowerTime;
    }
  }
  updateImpulseForward() {
    this.time += this.dt;
    if (this.time <= 0.2) {
      this.upperTime += 5 * this.dt;
      //if (this.upperTime > 0.4) this.lowerTime += 5 * this.dt;
    } else if (this.time > 0.8) {
      this.upperTime -= 5 * this.dt;
      //if (this.lowerTime < 0.6) this.upperTime -= 5 * this.dt;
    }
    this.lowerTime = clamp(this.lowerTime, 0, 1);
    this.upperTime = clamp(this.upperTime, 0, 1);

    if (this.cylMesh) {
      this.cylMesh.material.uniforms.upperTime.value = this.upperTime;
      this.cylMesh.material.uniforms.lowerTime.value = this.lowerTime;
      this.cylMesh.material.uniforms.insideTime.value = 10.0;
    }

    const oilLowerTime =
      this.time > 0.1 ? clamp((0.2 - this.time) / (0.2 - 0.1), 0, 1) : 0.0;

    const oilUpperTime =
      this.time < 0.8
        ? this.time < 0.1
          ? 0.0
          : 1.0
        : clamp((0.9 - this.time) / (0.9 - 0.8), 0, 1);

    if (this.cylMesh2) {
      this.cylMesh2.material.uniforms.upperTime.value = oilUpperTime;
      this.cylMesh2.material.uniforms.lowerTime.value = oilLowerTime;
      this.cylMesh2.material.uniforms.insideTime.value = oilLowerTime;
    }
  }

  delete() {
    this.cylMesh.removeFromParent();
    //this.curve.removeFromParent();
    this.splineHelperObjects.forEach((o) => {
      o.removeFromParent();
    });
    delete this.splineHelperObjects;
    delete this.cylMesh;
    delete this.curve;
  }

  die() {
    this.alive = false;
    setTimeout(() => {
      this.delete();
    }, 100);
  }
}

export { Curve };
