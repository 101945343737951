import * as THREE from "three";
import vertex from "./backgroundMaterial.glslv";
import fragment from "./backgroundMaterial.glslf";
import { LayeredMaterial } from "../layers/LayeredMaterial.mjs";
import { createStandardLayeredShader } from "../layers/layers";
import getState from "../state";

const state = getState();

const backgroundMaterial = () => {
  return new LayeredMaterial(
    {
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: {
        color: { value: new THREE.Color("#000000") },
        resolution: {
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
      },
      side: THREE.DoubleSide,
      depthWrite: false,
      depthTest: false,
      defines: {
        LAYERS: false,
        FINAL_OUTPUT: false,
      },
    },
    createStandardLayeredShader(fragment),
    state,
  );
};

export default backgroundMaterial;
