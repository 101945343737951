import { getData, getCamera } from "../scene";
import { getAssets } from "../loader";
import getState from "../state";
import * as THREE from "three";
import VehicleMesh from "../vehicle";

const vehicles = [];
const vehiclesbb = [];
const vehicleDict = {};
const state = getState();
const assetsData = getAssets();

function updateVehicleDict() {
  for (let i = 0; i < vehicles.length; i++) vehicleDict[vehicles[i].name] = i;
}

function createVehicle(type) {
  let { world, scene, assetsData } = getData();

  const vehicleObject = new VehicleMesh(type, assetsData, null, scene);
  vehicles.push(vehicleObject);
  vehiclesbb.push(vehicleObject.Bbox);
  updateVehicleDict();
}

function addVehicle(vehicle) {
  vehicles.push(vehicle);
  vehiclesbb.push(vehicle.Bbox);
  updateVehicleDict();
}

function updateVehicles() {
  const ps = vehicles.map((v) => v.body.position);
  for (let i = 0; i < vehicles.length; i++) {
    vehicles[i].perFrameUpdate(ps, vehicles);
  }
}

function getVehicles() {
  return vehicles;
}

function getActiveLayersFromState() {
  return state.layers.filter((layer) => layer.active);
}

function updateLayersData(material) {
  const activeLayers = getActiveLayersFromState();
  material.cleanLayers();
  activeLayers.forEach((activeLayer) => {
    material.addLayer(activeLayer, assetsData.layerTextures);
  });
}

function updateVehiclesMaterialsState() {
  vehicles.forEach((vehicle) => {
    const curveMaterial1 = vehicle.path.cylMesh.material;
    const curveMaterial2 = vehicle.path.cylMesh2
      ? vehicle.path.cylMesh2.material
      : null;
    const tankMaterial = vehicle.tankMaterial ? vehicle.tankMaterial : null;

    curveMaterial1.defines["NO_TEXTURE"] = state.PathBeforeMatCap === "none";
    curveMaterial1.defines["NORMAL_TARGET"] =
      state.GTAO !== "off" && state.renderer === "composer";
    curveMaterial1.defines["FINAL_OUTPUT"] = state.renderer !== "composer";
    curveMaterial1.defines["TONEMAP"] =
      state.ToneMapping !== "NONE" && state.renderer !== "composer";
    curveMaterial1.defines["TONEMAP_SIMPLE"] =
      state.ToneMapping === "TONEMAP_SIMPLE";
    curveMaterial1.defines["TONEMAP_REINHARD"] =
      state.ToneMapping === "TONEMAP_REINHARD";
    curveMaterial1.defines["TONEMAP_REINHARD_LUMA"] =
      state.ToneMapping === "TONEMAP_REINHARD_LUMA";
    curveMaterial1.defines["TONEMAP_REINHARD_WHITE"] =
      state.ToneMapping === "TONEMAP_REINHARD_WHITE";
    curveMaterial1.defines["TONEMAP_FILMIC"] =
      state.ToneMapping === "TONEMAP_FILMIC";
    curveMaterial1.defines["TONEMAP_PHOTOGRAPHIC"] =
      state.ToneMapping === "TONEMAP_PHOTOGRAPHIC";
    curveMaterial1.defines["TONEMAP_UNCHARTED"] =
      state.ToneMapping === "TONEMAP_UNCHARTED";

    curveMaterial1.uniforms.matCap.value =
      state.PathBeforeMatCap === "none"
        ? null
        : assetsData[state.PathBeforeMatCap];
    //curveMaterial1.uniforms.width.value = state.pathWidth
    curveMaterial1.uniforms.intensity.value = state.PathBeforeIntensity;
    curveMaterial1.uniforms.toneMappingExposure.value =
      state.ToneMappingExposure;
    curveMaterial1.uniforms.curveColor.value = new THREE.Color(
      state.PathBeforeColor,
    );
    curveMaterial1.uniforms.secondaryColor.value = new THREE.Color(
      state.PathAfterColor,
    );
    curveMaterial1.uniforms.secondaryIntensity.value = state.PathAfterIntensity;
    updateLayersData(curveMaterial1);
    curveMaterial1.compile();

    if (curveMaterial2) {
      curveMaterial2.defines["NO_TEXTURE"] = state.PathAfterMatCap === "none";
      curveMaterial2.defines["NORMAL_TARGET"] =
        state.GTAO !== "off" && state.renderer === "composer";
      curveMaterial2.defines["FINAL_OUTPUT"] = state.renderer !== "composer";
      curveMaterial2.defines["TONEMAP"] =
        state.ToneMapping !== "NONE" && state.renderer !== "composer";
      curveMaterial2.defines["TONEMAP_SIMPLE"] =
        state.ToneMapping === "TONEMAP_SIMPLE";
      curveMaterial2.defines["TONEMAP_REINHARD"] =
        state.ToneMapping === "TONEMAP_REINHARD";
      curveMaterial2.defines["TONEMAP_REINHARD_LUMA"] =
        state.ToneMapping === "TONEMAP_REINHARD_LUMA";
      curveMaterial2.defines["TONEMAP_REINHARD_WHITE"] =
        state.ToneMapping === "TONEMAP_REINHARD_WHITE";
      curveMaterial2.defines["TONEMAP_FILMIC"] =
        state.ToneMapping === "TONEMAP_FILMIC";
      curveMaterial2.defines["TONEMAP_PHOTOGRAPHIC"] =
        state.ToneMapping === "TONEMAP_PHOTOGRAPHIC";
      curveMaterial2.defines["TONEMAP_UNCHARTED"] =
        state.ToneMapping === "TONEMAP_UNCHARTED";
      curveMaterial2.uniforms.matCap.value =
        state.PathAfterMatCap === "none"
          ? null
          : assetsData[state.PathAfterMatCap];
      //curveMaterial2.unforms.width.value = state.pathWidth
      curveMaterial2.uniforms.intensity.value = state.PathAfterIntensity;
      curveMaterial2.uniforms.toneMappingExposure.value =
        state.ToneMappingExposure;
      curveMaterial2.uniforms.curveColor.value = new THREE.Color(
        state.PathAfterColor,
      );
      updateLayersData(curveMaterial2);
      curveMaterial2.compile();
    }

    vehicle.material.defines["NORMAL_TARGET"] =
      state.GTAO !== "off" && state.renderer === "composer";
    vehicle.material.defines["FINAL_OUTPUT"] = state.renderer !== "composer";
    vehicle.material.defines["TONEMAP"] =
      state.ToneMapping !== "NONE" && state.renderer !== "composer";
    vehicle.material.defines["TONEMAP_SIMPLE"] =
      state.ToneMapping === "TONEMAP_SIMPLE";
    vehicle.material.defines["TONEMAP_REINHARD"] =
      state.ToneMapping === "TONEMAP_REINHARD";
    vehicle.material.defines["TONEMAP_REINHARD_LUMA"] =
      state.ToneMapping === "TONEMAP_REINHARD_LUMA";
    vehicle.material.defines["TONEMAP_REINHARD_WHITE"] =
      state.ToneMapping === "TONEMAP_REINHARD_WHITE";
    vehicle.material.defines["TONEMAP_FILMIC"] =
      state.ToneMapping === "TONEMAP_FILMIC";
    vehicle.material.defines["TONEMAP_PHOTOGRAPHIC"] =
      state.ToneMapping === "TONEMAP_PHOTOGRAPHIC";
    vehicle.material.defines["TONEMAP_UNCHARTED"] =
      state.ToneMapping === "TONEMAP_UNCHARTED";

    vehicle.material.uniforms.toneMappingExposure.value =
      state.ToneMappingExposure;

    vehicle.material.uniforms.bigPulseColor.value = new THREE.Color(
      state.BigPulseColor,
    );
    vehicle.material.uniforms.bigPulseIntensity.value = state.BigPulseIntensity;

    vehicle.material.uniforms.smallPulseColor.value = new THREE.Color(
      state.SmallPulseColor,
    );
    vehicle.material.uniforms.smallPulseIntensity.value =
      state.SmallPulseIntensity;
    vehicle.material.uniforms.ambientColor.value = new THREE.Color(
      state.ambientColor,
    );
    vehicle.material.uniforms.ambientIntensity.value = state.ambientIntensity;
    vehicle.material.uniforms.backgroundColor.value = new THREE.Color(
      state.backgroundColor,
    );
    vehicle.material.uniforms.matCap.value = assetsData[state.carsMatCap]
      ? assetsData[state.carsMatCap]
      : assetsData["blackMatCap"];

    updateLayersData(vehicle.material);
    vehicle.material.compile();

    if (tankMaterial) {
      tankMaterial.defines["NORMAL_TARGET"] =
        state.GTAO !== "off" && state.renderer === "composer";
      tankMaterial.defines["FINAL_OUTPUT"] = state.renderer !== "composer";
      tankMaterial.defines["TONEMAP"] =
        state.ToneMapping !== "NONE" && state.renderer !== "composer";
      tankMaterial.defines["TONEMAP_SIMPLE"] =
        state.ToneMapping === "TONEMAP_SIMPLE";
      tankMaterial.defines["TONEMAP_REINHARD"] =
        state.ToneMapping === "TONEMAP_REINHARD";
      tankMaterial.defines["TONEMAP_REINHARD_LUMA"] =
        state.ToneMapping === "TONEMAP_REINHARD_LUMA";
      tankMaterial.defines["TONEMAP_REINHARD_WHITE"] =
        state.ToneMapping === "TONEMAP_REINHARD_WHITE";
      tankMaterial.defines["TONEMAP_FILMIC"] =
        state.ToneMapping === "TONEMAP_FILMIC";
      tankMaterial.defines["TONEMAP_PHOTOGRAPHIC"] =
        state.ToneMapping === "TONEMAP_PHOTOGRAPHIC";
      tankMaterial.defines["TONEMAP_UNCHARTED"] =
        state.ToneMapping === "TONEMAP_UNCHARTED";

      tankMaterial.uniforms.toneMappingExposure.value =
        state.ToneMappingExposure;

      tankMaterial.uniforms.bigPulseColor.value = new THREE.Color(
        state.BigPulseColor,
      );
      tankMaterial.uniforms.bigPulseIntensity.value = state.BigPulseIntensity;

      tankMaterial.uniforms.smallPulseColor.value = new THREE.Color(
        state.SmallPulseColor,
      );
      tankMaterial.uniforms.smallPulseIntensity.value =
        state.SmallPulseIntensity;
      tankMaterial.uniforms.ambientColor.value = new THREE.Color(
        state.ambientColor,
      );
      tankMaterial.uniforms.ambientIntensity.value = state.ambientIntensity;
      tankMaterial.uniforms.backgroundColor.value = new THREE.Color(
        state.backgroundColor,
      );
      tankMaterial.uniforms.matCap.value = assetsData[state.carsMatCap]
        ? assetsData[state.carsMatCap]
        : assetsData["blackMatCap"];

      updateLayersData(tankMaterial);
      tankMaterial.compile();
    }

    curveMaterial1.needsUpdate = true;
    if (curveMaterial2) curveMaterial2.needsUpdate = true;
    vehicle.material.needsUpdate = true;
    if (tankMaterial) tankMaterial.needsUpdate = true;
  });
}

function cleanVehicles() {
  while (vehicles.length > 0) {
    vehicles.pop();
  }
  while (vehiclesbb.length > 0) {
    vehiclesbb.pop();
  }
  Object.keys(vehicleDict).forEach((key) => delete vehicleDict[key]);
}

export {
  cleanVehicles,
  addVehicle,
  updateVehicles,
  getVehicles,
  createVehicle,
  // vehiclesOnPointerMove,
  updateVehiclesMaterialsState,
};
