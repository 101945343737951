import getState from "../state";

const state = getState();
const worlds = [];
const m = 1.1; //1.2;
//const maxSubSteps = 10;

let lastTime = Date.now();

let average = 0;
let N = 0;

function addWorld(world) {
  worlds.push(world);
}

function updateWorld() {
  let time = Date.now();
  if (lastTime !== undefined) {
    N++;
    const current = (time - lastTime) / 1000;
    average = (average * (N - 1) + current) / N;

    if (N < 10) average = 0;

    //console.log(average);

    const highFPS = average < 1 / 80 ? true : false;
    let dt = 1 / 60; //(time - lastTime) / 1000;
    const fixedTimeStep = highFPS
      ? state.velocitiesHighFPS.fixedTimeStep
      : state.velocities.fixedTimeStep;
    const numSteps = highFPS
      ? state.velocitiesHighFPS.numSteps
      : state.velocities.numSteps;

    dt *= highFPS
      ? state.velocitiesHighFPS.timeSpeed
      : state.velocities.timeSpeed; //speedMultiplier; //state.velocities.physDt; //m * 0.016; //(m * (time - lastTime)) / 1000;
    //dt = Math.min(dt, 0.22);

    //console.log(speedMultiplier);
    worlds[0].step(fixedTimeStep, dt, numSteps);
  }
  lastTime = time;
}

export { updateWorld, addWorld };
