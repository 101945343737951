import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { DataUtils } from "three";
import { setRenderer } from "./state";
import { getGPUTier } from "detect-gpu";
const loader = new GLTFLoader();
const curveLoader = new OBJLoader();
const tloader = new THREE.TextureLoader();
const dracoLoader = new DRACOLoader();

let complete = 0;

const assets = {
  Samosval: null,
  TankTruck: null,
  Car1: null,
  Taxi: null,
  Med: null,
  City: null,
  orangeMatCap: null,
  whiteMatCap: null,
  blackMatCap: null,
  bluishMatCap: null,
  colorfulMatCap: null,
  violetYellowMatCap: null,
  patternTexture: null,
  lightmap: null,
  lightmapRoad: null,
  animations: null,
  bricks: null,
  customMatCap0: null,
  customMatCap1: null,
  customMatCap2: null,
  customMatCap3: null,
  customMatCap4: null,
  customMatCap5: null,
  customMatCap6: null,
  customMatCap7: null,
  customMatCap8: null,
  curves: null,
  animatedMeshes: [],
  flowMap: null,
  waterNormal1: null,
  waterNormal2: null,
  waterMatCap: null,

  layerTextures: {
    None: null,
    layerTexture0: null,
    layerTexture1: null,
    layerTexture2: null,
    layerTexture3: null,
    layerTexture4: null,
    layerTexture5: null,
    layerTexture6: null,
    layerTexture7: null,
    layerTexture8: null,
  },
};

function getComplete() {
  return complete;
}

function getAssets() {
  return assets;
}

function wordBeforeSymbol(preword, symbol) {
  let word = "";
  for (let i = 0; i < preword.length; i++) {
    if (preword[i] != symbol) {
      word += preword[i];
    } else break;
  }
  return word;
}

function loadMatCap(url, index, callback) {
  tloader.load(url, (t) => {
    assets["customMatCap" + index] = t;
    assets["customMatCap" + index].flipY = true;
    t.wrapS = THREE.ClampToEdgeWrapping;
    t.wrapT = THREE.ClampToEdgeWrapping;
    callback();
  });
}

function loadLayerTexture(url, index, callback) {
  tloader.load(url, (t) => {
    assets.layerTextures["layerTexture" + index] = t;
    assets.layerTextures["layerTexture" + index].flipY = true;
    t.wrapS = THREE.ClampToEdgeWrapping;
    t.wrapT = THREE.ClampToEdgeWrapping;
    callback();
  });
}

function quantizeGeometryAttribute(geometry, attribute) {
  const preArray = geometry.attributes[attribute].array;
  const uint32View = new Uint32Array(preArray.buffer);
  const itemSize = geometry.attributes[attribute].itemSize;

  const buffer = new ArrayBuffer(2 * preArray.length);
  const uint16Buffer = new Uint16Array(buffer);

  for (let i = 0; i < preArray.length; i++) {
    //const uint32Element = uint32View[i];
    const float32Element = preArray[i];
    //uint16Buffer[i] = DataUtils.toHalfFloat(uint32Element);
    const uint16Element = DataUtils.toHalfFloat(float32Element);
    uint16Buffer[i] = uint16Element;
  }
  geometry.setAttribute(
    attribute,
    new THREE.Float16BufferAttribute(buffer, itemSize, false),
  );
}

function reduceIndexBytes(geometry) {
  const indexArray = geometry.index.array;
  const u16IndexArray = new Uint16Array(indexArray);
  geometry.setIndex(new THREE.Uint16BufferAttribute(u16IndexArray, 1));
}

let maxIndexCount = 0;

async function loadAssets(inURL) {
  let url;
  if (!inURL) url = "";
  else url = inURL;

  dracoLoader.setDecoderPath(url + "js_draco/");
  dracoLoader.preload();
  loader.setDRACOLoader(dracoLoader);
  await Promise.all([
    new Promise((res) => {
      curveLoader.load(url + "SW20_LowpolyCity_Route_04b.obj", (loaded) => {
        const curves = [];
        const wrongOrder = [1, 2, 3, 4];
        for (let j = 0; j < loaded.children.length; j++) {
          const positionAttribute =
            loaded.children[loaded.children.length - 1 - j].geometry.attributes
              .position;
          const N = positionAttribute.count;
          const positions = [];

          for (let i = 0; i < 3 * N; i += 3) {
            const index = wrongOrder.indexOf(j) != -1 ? 3 * N - 3 - i : i;

            positions.push(
              new THREE.Vector3(
                positionAttribute.array[index] * 0.6,
                positionAttribute.array[index + 1] * 0.6,
                positionAttribute.array[index + 2] * 0.6,
              ),
            );
          }

          const lastIndex = wrongOrder.indexOf(j) != -1 ? 3 * N - 3 : 0;

          positions.push(
            new THREE.Vector3(
              positionAttribute.array[lastIndex] * 0.6,
              positionAttribute.array[lastIndex + 1] * 0.6,
              positionAttribute.array[lastIndex + 2] * 0.6,
            ),
          );
          curves.push(positions);
        }

        assets.curves = curves;

        complete += 3.5;
        res(0);
      });
    }),
    new Promise((res) => {
      tloader.load(url + "matcaps/water.png", (t) => {
        assets.waterMatCap = t;
        assets.waterMatCap.flipY = true;
        t.wrapS = THREE.ClampToEdgeWrapping;
        t.wrapT = THREE.ClampToEdgeWrapping;
        complete += 0;

        res(0);
      });
    }),
    new Promise((res) => {
      tloader.load(url + "layers/texture_0.png", (t) => {
        assets.layerTextures.layerTexture0 = t;
        t.flipY = true;
        t.wrapS = THREE.ClampToEdgeWrapping;
        t.wrapT = THREE.ClampToEdgeWrapping;
        complete += 0;

        res(0);
      });
    }),
    new Promise((res) => {
      tloader.load(url + "layers/texture_1.png", (t) => {
        assets.layerTextures.layerTexture1 = t;
        t.flipY = true;
        t.wrapS = THREE.ClampToEdgeWrapping;
        t.wrapT = THREE.ClampToEdgeWrapping;
        complete += 0;

        res(0);
      });
    }),
    new Promise((res) => {
      tloader.load(url + "textures/Water_1_M_Normal.jpg", (t) => {
        assets.waterNormal1 = t;
        assets.waterNormal1.flipY = true;
        t.wrapS = THREE.RepeatWrapping;
        t.wrapT = THREE.RepeatWrapping;
        complete += 0.0;
        res(0);
      });
    }),
    new Promise((res) => {
      tloader.load(url + "textures/Water_2_M_Normal.jpg", (t) => {
        assets.waterNormal2 = t;
        assets.waterNormal2.flipY = true;
        t.wrapS = THREE.RepeatWrapping;
        t.wrapT = THREE.RepeatWrapping;
        complete += 0.0;
        res(0);
      });
    }),
    new Promise((res) => {
      tloader.load(url + "matcaps/colorful.png", (t) => {
        assets.colorfulMatCap = t;
        assets.colorfulMatCap.flipY = true;
        t.wrapS = THREE.ClampToEdgeWrapping;
        t.wrapT = THREE.ClampToEdgeWrapping;
        complete += 3.5;
        res(0);
      });
    }),
    new Promise((res) => {
      tloader.load(url + "matcaps/Black.png", (t) => {
        assets.blackMatCap = t;
        assets.blackMatCap.flipY = true;
        t.wrapS = THREE.ClampToEdgeWrapping;
        t.wrapT = THREE.ClampToEdgeWrapping;
        complete += 3.5;
        res(0);
      });
    }),
    new Promise((res) => {
      tloader.load(url + "matcaps/White.png", (t) => {
        assets.whiteMatCap = t;
        assets.whiteMatCap.flipY = true;
        t.wrapS = THREE.ClampToEdgeWrapping;
        t.wrapT = THREE.ClampToEdgeWrapping;
        complete += 7;

        res(0);
      });
    }),

    new Promise((res) => {
      tloader.load(url + "matcaps/mt_1;8.png", (t) => {
        assets.customMatCap1 = t;
        assets.customMatCap1.flipY = true;
        assets.customMatCap8 = t;
        assets.customMatCap8.flipY = true;
        t.wrapS = THREE.ClampToEdgeWrapping;
        t.wrapT = THREE.ClampToEdgeWrapping;
        complete += 0;

        res(0);
      });
    }),
    new Promise((res) => {
      tloader.load(url + "matcaps/mc_2.png", (t) => {
        assets.customMatCap2 = t;
        assets.customMatCap2.flipY = true;
        t.wrapS = THREE.ClampToEdgeWrapping;
        t.wrapT = THREE.ClampToEdgeWrapping;
        complete += 0;

        res(0);
      });
    }),
    new Promise((res) => {
      tloader.load(url + "matcaps/mt_0.png", (t) => {
        assets.customMatCap0 = t;
        assets.customMatCap0.flipY = true;
        t.wrapS = THREE.ClampToEdgeWrapping;
        t.wrapT = THREE.ClampToEdgeWrapping;
        complete += 0;

        res(0);
      });
    }),
    new Promise((res) => {
      tloader.load(url + "matcaps/mt_3.png", (t) => {
        assets.customMatCap3 = t;
        assets.customMatCap3.flipY = true;
        t.wrapS = THREE.ClampToEdgeWrapping;
        t.wrapT = THREE.ClampToEdgeWrapping;
        complete += 0;

        res(0);
      });
    }),
    new Promise((res) => {
      tloader.load(url + "matcaps/mt_4.png", (t) => {
        assets.customMatCap4 = t;
        assets.customMatCap4.flipY = true;
        t.wrapS = THREE.ClampToEdgeWrapping;
        t.wrapT = THREE.ClampToEdgeWrapping;
        complete += 0;

        res(0);
      });
    }),
    new Promise((res) => {
      tloader.load(url + "matcaps/mt_6.png", (t) => {
        assets.customMatCap6 = t;
        assets.customMatCap6.flipY = true;
        t.wrapS = THREE.ClampToEdgeWrapping;
        t.wrapT = THREE.ClampToEdgeWrapping;
        complete += 0;

        res(0);
      });
    }),
    new Promise((res) => {
      tloader.load(url + "matcaps/mt_7.png", (t) => {
        assets.customMatCap7 = t;
        assets.customMatCap7.flipY = true;
        t.wrapS = THREE.ClampToEdgeWrapping;
        t.wrapT = THREE.ClampToEdgeWrapping;
        complete += 0;

        res(0);
      });
    }),

    // getGPUTier().then((r) => {
    //   setRenderer(r.tier > 2 ? "composer" : "direct");
    // }),

    new Promise((res) => {
      loader.load(url + "bricks.glb", (loaded) => {
        assets.bricks = loaded.scene.children[0];
        complete += 3.5;
        res(0);
      });
    }),
    new Promise((res) => {
      loader.load(url + "out.glb", (loaded) => {
        //loader.load("untitled.glb", (loaded) => {
        assets.City = loaded.scene;

        assets.City.traverse((m) => {
          if (!m.isMesh) return;

          if (m.geometry.index.count > maxIndexCount)
            maxIndexCount = m.geometry.index.count;
        });

        // console.log("Max Index Count: ", maxIndexCount);
        assets.animations = loaded.animations;

        assets.animations[0].tracks.forEach((track) => {
          const name = wordBeforeSymbol(track.name, ".");
          assets.animatedMeshes[name] = true;
        });

        complete += 40;
        res(0);
      });
    }),
    new Promise((res) => {
      loader.load(url + "outcars.glb", (loaded) => {
        const children = loaded.scene.children;
        children.forEach((m) => {
          assets[m.name] = m;

          m.traverse((o) => {
            if (o.isMesh) {
              //o.scale.set(0.3, 0.3, 0.3);
              o.geometry.computeBoundingSphere();
              quantizeGeometryAttribute(o.geometry, "position");
              if (o.geometry.attributes.normal)
                quantizeGeometryAttribute(o.geometry, "normal");
              if (o.geometry.index) reduceIndexBytes(o.geometry);
            }
          });
        });

        complete += 39;
        res(0);
      });
    }),
  ]);
}

export { getComplete, getAssets, loadAssets, loadMatCap, loadLayerTexture };
