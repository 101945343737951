import BloomUpsample from "./shaders/BloomUpsample";
import BloomDownsample from "./shaders/BloomDownsample";
import Final from "./shaders/Final";
import GTAO from "./shaders/GTAO";
import SSAO from "./shaders/SSAO";
import SSAOBlur from "./shaders/SSAOBlur";
import ToneMapping from "./shaders/ToneMapping";
import applyMask from "./shaders/applyMask";

export default () => {
  return {
    BloomUpsample,
    BloomDownsample,
    Final,
    GTAO,
    SSAO,
    SSAOBlur,
    ToneMapping,
    applyMask,
  };
};
